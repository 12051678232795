import React, { useEffect, useCallback, useMemo, forwardRef } from "react";
import useWallet from "lib/wallets/useWallet";
import { SpotRight } from "./SpotRight";
import { BUY, SELL, getPageTitle } from "lib/legacy";
import ExchangeTVChart from "components/Exchange/ExchangeTVChart";
import Footer from "components/Footer/Footer";
import "./Exchange.css";
import { useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getToken } from "config/tokens";
import { useChainId } from "lib/chains";
import QuoteBanner from "components/Quote/QuoteBanner";
import { ethers } from "ethers";
import { getConstant } from "config/chains";
import { SpotBottom } from "./SpotBottom";
import { useTokenPrice } from "domain/prices";
const { AddressZero } = ethers.constants;

export const Spot = forwardRef((props, ref) => {
  const { pendingTxns, setPendingTxns, savedShouldShowPositionLines } = props;

  const { active, signer } = useWallet();
  const { chainId } = useChainId();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");

  const tokenPrice = useTokenPrice(chainId, signer);
  // console.log("tokenPrice: ", tokenPrice);
  const { infoTokens } = useInfoTokens(signer, chainId, active, 0, 0);
  const defaultTokenSelection = useMemo(
    () => ({
      [BUY]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SELL]: {
        from: AddressZero,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-spot",
    defaultTokenSelection
  );
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "Swap-option-v2", BUY);

  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;
  // console.log(fromTokenAddress, toTokenAddress);
  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[BUY].to = address;
      newTokenSelection[SELL].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  useEffect(() => {
    const toToken = getToken(chainId, toTokenAddress);
    let title = getPageTitle(
      (tokenPrice[toToken.baseSymbol]?.price ? tokenPrice[toToken.baseSymbol]?.price : "") +
      ` | ${toToken.baseSymbol}${toToken.isStable ? "" : "USD"}`
    );
    document.title = title;
  }, [tokenSelection, infoTokens, chainId, fromTokenAddress, toTokenAddress]);

  const renderChart = () => {
    return (
      <ExchangeTVChart
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        infoTokens={infoTokens}
        swapOption="Swap"
        chainId={chainId}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        setToTokenAddress={setToTokenAddress}
      />
    );
  };
  return (
    <div className="Exchange page-layout">
      <QuoteBanner></QuoteBanner>
      <div className="Exchange-content">
        <div className="Exchange-left">
          {renderChart()}
          <SpotBottom tokenAddress={toTokenAddress} tokenPrice={tokenPrice} setPendingTxns={setPendingTxns} />
        </div>
        <div className="Exchange-right">
          <SpotRight
            tokenPrice={tokenPrice}
            defaultTokens={tokenSelection}
            setToTokenAddress={setToTokenAddress}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
          />

          <div className="Exchange-wallet-tokens">
            <div className="Exchange-wallet-tokens-content"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});
