import React from "react";

function Auction() {
  return (
    <div>
      <iframe
        src="https://app.bounce.finance/launchpad/dip_exchange"
        title="DIP_Auction"
        style={{ width: "100vw", minHeight: "100vh" }}
      ></iframe>
      {/* <iframe
        src="https://www.dip.exchange"
        title="DIP_Auction"
        style={{ width: "100vw", minHeight: "100vh" }}
      ></iframe> */}
    </div>
  );
}

export default Auction;
