import React, { useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import { getConstant } from "config/chains";
import { getPoolAssets, getToken } from "config/tokens";
import cx from "classnames";
import { callContract, contractFetcher } from "lib/contracts";
import { ethers } from "ethers";
import "./Referrals.css";
import { AddReferralModal } from "./AddReferralModal";
import { useCopyToClipboard } from "react-use";
import { helperToast } from "lib/helperToast";
import { getTwitterIntentURL } from "lib/legacy";
import { defaultApiUrl, getApiPathName } from "lib/api";
import LeftPageIcon from "img/ic_left_page.png";
import UnActiveLeftPageIcon from "img/ic_left_page_unactive.png";
import RightPageIcon from "img/ic_right_page.png";
import UnActiveRightPageIcon from "img/ic_right_page_unactive.png";
import { formatUTCTime } from "lib/dates";
import { shortenAddress } from "lib/legacy";
import { formatAmount, formatMoney } from "lib/numbers";
import useSWR from "swr";
import { getContract } from "config/contracts";
import ReferralRegistry from "abis/DIPReferralRegistry.json";
import ReferralController from "abis/DIPReferralController.json";
import { defaultValueDecimals } from "lib/pool";
import { useTokenPrice } from "domain/prices";
import { useReferralInfo } from "domain/referral";
import Footer from "components/Footer/Footer";

function Referrals ({ setPendingTxns }) {
  const { chainId } = useChainId();
  const { active, signer, account } = useWallet();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const [page, setPage] = useState(1);

  const poolAssets = getPoolAssets(chainId);
  const [, copyToClipboard] = useCopyToClipboard();
  const [showModal, setShowModal] = useState(false);
  const referralInfo = useReferralInfo(chainId, account);
  const referralRegistryAddr = getContract(chainId, "ReferralRegistry");
  const referralControllerAddr = getContract(chainId, "ReferralController");
  const tokenPrice = useTokenPrice(chainId, signer);
  const tweetLink = getTwitterIntentURL(
    `Trying out trading on @DIP_Exchange, up to 50x leverage on $BTC, $ETH 📈 For fee discounts use: ${referralInfo?.referralLink} `
  );
  const { data: referralAddress } = useSWR(
    account && [`Referral:referralAddress${active}`, chainId, referralRegistryAddr, "referredBy", account],
    {
      fetcher: contractFetcher(signer, ReferralRegistry),
    }
  );
  const { data: tier1Address } = useSWR(
    account && [`Referral:tier1Address${active}`, chainId, referralRegistryAddr, "referredCount", account],
    {
      fetcher: contractFetcher(signer, ReferralRegistry),
    }
  );
  const { data: rewardInfo } = useSWR(
    account && [`Referral:rewardInfo${active}`, chainId, referralControllerAddr, "userRewardsInfo", account],
    {
      fetcher: contractFetcher(signer, ReferralController, [
        poolAssets?.map((token) => {
          return token.isNative ? token.wrappedAddress : token.address;
        }),
      ]),
    }
  );
  const [rewardHistory, setRewardHistory] = useState();
  useEffect(() => {
    if (!account) return;
    try {
      fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/referral/rewardHistory?address=${account}&page=${page}`).then(
        (res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              if (data.errno === 0) {
                setRewardHistory(
                  data.data.map((item) => {
                    return {
                      ...item,
                      token: getToken(chainId, item.tokenAddress),
                    };
                  })
                );
              } else {
                setRewardHistory(undefined);
              }
            });
          }
        }
      );
    } catch (e) { }
  }, [chainId, account, page]);

  const clickPage = (type) => {
    let newPage;
    if (type === 0) {
      newPage = page > 1 ? page - 1 : 1;
    } else {
      newPage = rewardHistory?.length < 10 ? page : page + 1;
    }
    setPage(newPage);
  };
  const [totalClaimableValue, setTotalClaimableValue] = useState();
  useEffect(() => {
    if (rewardInfo && tokenPrice && poolAssets) {
      let totalValue = 0;
      for (let i = 0; i < rewardInfo.rewardInfo.length; i++) {
        const info = rewardInfo.rewardInfo[i];
        const token = poolAssets[i];
        totalValue +=
          formatAmount(info.reward.sub(info.claimed), token.decimals, token.decimals) *
          tokenPrice[token.baseSymbol]?.price;
      }
      setTotalClaimableValue(totalValue);
    }
  }, [tokenPrice, rewardInfo, poolAssets]);
  const claim = async () => {
    if (!signer || !account || !active || !rewardInfo || !poolAssets) {
      helperToast.error("No rewards to claim");
      return;
    }

    const rewards = rewardInfo.rewardInfo;
    let tokenAddress = [];
    for (let i = 0; i < rewards.length; i++) {
      if (rewards[i].reward.gt(rewards[i].claimed)) {
        const token = poolAssets[i];
        tokenAddress.push(token.isNative ? token.wrappedAddress : token.address);
      }
    }
    if (tokenAddress.length === 0) {
      helperToast.error("No rewards to claim");
      return;
    }
    callContract(
      chainId,
      new ethers.Contract(referralControllerAddr, ReferralController.abi, signer),
      "claimMultiple",
      [tokenAddress],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        // setIsStaking(false);
      });
  };

  return (
    <div className=" default-container page-layout">
      <div className="pb-30">
        <div className="Earn-header-bg">
          <div className="Earn-header  flex-col justify-center">
            <div className="Earn-header-title ">
              Referral Program
              {poolAssets &&
                poolAssets.map((token, index) => (
                  <img
                    width="24"
                    src={token.imageUrl}
                    style={{ marginLeft: index > 0 ? "-10px" : "10px" }}
                    alt="Network Icon"
                  />
                ))}
            </div>
            <div className="">
              Refer your friends and build a passive income stream! Check out the details now &nbsp;
              <a href="https://docs.dip.exchange/fundamentals/roadmap/referral-system">referral program details.</a>
            </div>
          </div>
        </div>
        <div className="">
          <div>
            {/* <div className="fw-bold fs-3">Your Referral</div> */}

            <div className="row">
              <div className="col-md-7 col-12 mt-10  Referral-card ">
                <div className="flex aligns-center justify-around Transform-50p">
                  <div>
                    <div className="Liquidity-card-subtitle ">Cumulative rewards</div>
                    <div className="mt-10 flex aligns-center">
                      ≈
                      <div className=" ml-10 fw-bold fs-3">
                        $
                        {rewardInfo?.totalValue
                          ? formatAmount(rewardInfo.totalValue, defaultValueDecimals, 4, true)
                          : "--"}
                        {/* <img src={require("img/ic_usdc_40.svg")} alt="USDC" className="icon-xs ml-10"></img> */}
                      </div>
                    </div>
                  </div>
                  <div className="Referral-split-col"></div>
                  <div>
                    <div className="Liquidity-card-subtitle ">Total Users(Tier 1)</div>
                    <div className="mt-10 flex aligns-center">
                      <img src={require("img/ic_user_group.png")} alt="users" className="icon-xs"></img>
                      <div className=" ml-10 fw-bold fs-3">{tier1Address ? tier1Address.toString() : ""}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 mt-10">
                <div className="Referral-card flex aligns-center justify-between">
                  <div className="flex aligns-center flex-1">
                    <div className="Liquidity-card-subtitle ">Your Link</div>
                    <div className=" ml-10 ellipsis  flex-1">{referralInfo?.referralLink}</div>
                  </div>
                  <div>
                    <img
                      src={require("img/ic_copy_16.svg").default}
                      className="icon-xs"
                      alt="copy"
                      onClick={() => {
                        if (referralInfo?.referralLink) {
                          copyToClipboard(referralInfo.referralLink);
                          helperToast.success(`Referral link copied to your clipboard`);
                        }
                      }}
                    ></img>
                    <a href={tweetLink}>
                      <img src={require("img/ic_twitter.svg").default} className="icon-xs ml-15" alt="twitter"></img>
                    </a>
                  </div>
                </div>
                <div className="Referral-card flex aligns-center mt-10">
                  <div className="Liquidity-card-subtitle ">Your Referrer</div>
                  {referralAddress && referralAddress != ethers.constants.AddressZero ? (
                    <div className="ml-10"> {shortenAddress(referralAddress.toString(), 14)}</div>
                  ) : (
                    <div
                      className="default-btn ml-20"
                      onClick={() => {
                        setShowModal(!showModal);
                      }}
                    >
                      Add Referral Address
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-12 ">
              <div className="Referral-card flex-col justify-center  aligns-center">
                <div className="Liquidity-card-subtitle ">Add Referral Address </div>
                <div className="mt-10 flex algins-center">
                  <div className={cx(" ml-10", {})}>mmmm</div>
                </div>
              </div>
            </div> */}
          </div>
          <div style={{ height: "4rem" }}></div>

          <div className=" Referral-list-card">
            <div className="flex justify-between aligns-center">
              <div className="fw-bold fs-3">
                Reward Detail{" "}
                <span className="Earn-detail-left-subtitle fs-4">
                  (Claimable: ≈ ${formatMoney(totalClaimableValue, 4)})
                </span>
              </div>
              <div className="default-btn" onClick={claim}>
                Claim
              </div>
            </div>
          </div>

          <div className=" scroll-x">
            <table
              className="table table-dark table-hover table-striped align-middle mt-10"
              style={{ marginBottom: 0 }}
            >
              <thead className="Order-header">
                <tr>
                  <th scope="col">Token</th>
                  <th scope="col">Claimed</th>
                  <th scope="col">Claimable</th>
                  {/* <th scope="col"></th> */}
                </tr>
              </thead>
              <tbody>
                {poolAssets?.map((token, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex aligns-center">
                        <img key={index} alt="" src={token.imageUrl} className={cx("icon-sm ml-10")}></img>
                        <div className="flex-col ml-20">
                          <div className="fw-bold">{token.baseSymbol}</div>
                          <div className="Earn-detail-left-subtitle">{token.name}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {rewardInfo?.rewardInfo
                        ? formatAmount(rewardInfo?.rewardInfo[index].claimed, token.decimals, 6, true)
                        : "---"}
                    </td>
                    <td>
                      {rewardInfo?.rewardInfo ? (
                        <div>
                          <div>
                            {formatAmount(
                              rewardInfo?.rewardInfo[index].reward.sub(rewardInfo?.rewardInfo[index].claimed),
                              token.decimals,
                              6,
                              true
                            )}
                          </div>
                          <div className="Earn-detail-left-sub-value">
                            ≈ $
                            {formatMoney(
                              formatAmount(
                                rewardInfo?.rewardInfo[index].reward.sub(rewardInfo?.rewardInfo[index].claimed),
                                token.decimals,
                                token.decimals
                              ) * tokenPrice[token.baseSymbol]?.price,
                              4
                            )}
                          </div>
                        </div>
                      ) : (
                        "---"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ height: "4rem" }}></div>
          <div className="Referral-list-card">
            <div className=" fw-bold fs-3">Reward History</div>
          </div>

          <div className=" scroll-x">
            <table
              className="table table-dark table-hover table-striped align-middle mt-10"
              style={{ marginBottom: 0 }}
            >
              <thead className="Order-header">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Token</th>
                  <th scope="col">Trader</th>
                  <th scope="col">Amount</th>
                  {/* <th scope="col">Tier</th> */}
                  {/* <th scope="col"></th> */}
                </tr>
              </thead>
              <tbody>
                {rewardHistory?.map((history, index) => (
                  <tr key={index}>
                    <td> {formatUTCTime(new Date(history.createTime?.toString() * 1000))} UTC</td>
                    <td>
                      <div className="flex aligns-center">
                        <img key={index} alt="" src={history.token?.imageUrl} className={cx("icon-xs ml-10")}></img>
                        <div className="ml-10">{history.token?.baseSymbol}</div>
                      </div>
                    </td>
                    <td>{shortenAddress(history.trader, 14)}</td>
                    <td>{formatAmount(history.amount, history.token.decimals, 6, true)}</td>
                    {/* <td>{history.tier}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-content-end mt-20 mr-20">
            <div className="flex aligns-center">
              <img
                alt=""
                src={page === 1 ? UnActiveLeftPageIcon : LeftPageIcon}
                style={{ height: "1.5rem", width: "auto", marginRight: "0.5rem" }}
                onClick={() => {
                  clickPage(0);
                }}
                disabled={page === 1}
              ></img>
              Page {page}
              <img
                alt=""
                src={rewardHistory?.length < 10 ? UnActiveRightPageIcon : RightPageIcon}
                style={{ height: "1.5rem", width: "auto", marginLeft: "0.5rem" }}
                onClick={() => {
                  clickPage(1);
                }}
                disabled={rewardHistory?.length < 10}
              ></img>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <AddReferralModal
          showModal={showModal}
          account={account}
          chainId={chainId}
          referralControllerAddr={referralControllerAddr}
          ReferralController={ReferralController}
          signer={signer}
          setPendingTxns={setPendingTxns}
          closeModal={() => {
            setShowModal(false);
          }}
        />
      )}
      <Footer />
    </div>
  );
}

export default Referrals;
