import cx from "classnames";
import React, { useState, useEffect, useRef } from "react";
import CurrencySelect from "components/Currency/CurrencySelect";
import SingleSwapIcon from "img/ic_single_swap.png";
import Button from "components/Button/Button";
import { approveTokens } from "domain/tokens";
import longImg from "img/ic_long_white.png";
import longDarkImg from "img/ic_long_dark.png";
import shortImg from "img/ic_short_white.png";
import shortDarkImg from "img/ic_short_dark.png";
import { AVAILABLE_CHART_TOKENS, getToken, getTokenBySymbol } from "config/tokens";
import { useChainId } from "lib/chains";
import { ethers } from "ethers";
import ERC20 from "abis/ERC20.json";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import useWallet from "lib/wallets/useWallet";
import { getContract } from "config/contracts";
import { formatAmount, formatMoney, isNumber } from "lib/numbers";
import Slider, { SliderTooltip } from "rc-slider";
import { MAX_ALLOWED_LEVERAGE, BASIS_POINTS_DIVISOR } from "lib/legacy";
import {
  getMarketInfo,
  getPositionsInfoAndPosition,
  MIN_COLLATERAL_VALUE,
  getSwapAndExecutionFee,
} from "lib/positions";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import Pool from "abis/Pool.json";
import { helperToast } from "lib/helperToast";
import { OrderType } from "components/Exchange/OrderType";
import { ExchangeModal } from "components/Exchange/ExchangeModal";
import "./ExchangeRight.css";
import { getConstant } from "config/chains";
import { useConnectModal } from "@rainbow-me/rainbowkit";


export function ExchangeRight ({ defaultTokens, setToTokenAddress, setPendingTxns, tokenPrice, positions }) {
  const { chainId } = useChainId();

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");

  const [state, setState] = useState({ tabIndex: 0 });
  const [payTokenInfo, setPayTokenInfo] = useState();
  const [isApproving, setIsApproving] = useState();
  const [allowanceAmount, setAllowanceAmount] = useState();
  const orderManagerAddr = getContract(chainId, "OrderManager");
  const [positionsInfo, setPositionsInfo] = useState({ size: 0 });
  const positionsToken = useRef();
  const poolAddress = getContract(chainId, "Pool");
  const [inProcess, setInProcess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [slippage, setSlippage] = useState(0.003);
  const [executionFee, setExecutionFee] = useState("");
  const [swapFee, setSwapFee] = useState();
  const [orderType, setOrderType] = useState({ type: 0 }); //0: market 1:limit
  const payTokenChild = useRef(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const isLong = () => {
    return state.tabIndex === 0;
  };
  const getAllowanceAmount = async (token) => {
    if (token.isNative) {
      setAllowanceAmount("9999999999999999999999999999");
      return;
    }
    try {
      const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
      const allowanceAmount = await tokenContract.allowance(account, orderManagerAddr);
      setAllowanceAmount(parseFloat(formatAmount(allowanceAmount, token.decimals, token.decimals)));
    } catch (e) {
      // console.log("error in ", e);
    }
  };

  const defaultTokensInfo = [
    {
      type: "Long",
      // from: getToken(chainId, defaultTokens.Long.from),
      from: getTokenBySymbol(chainId, defaultCollateralSymbol), // todo temp for testnet
      to: getToken(chainId, defaultTokens.Long.to),
      collateralAsset: getToken(chainId, defaultTokens.Long.to),
    },
    {
      type: "Short",
      // from: getToken(chainId, defaultTokens.Short.from),
      from: getTokenBySymbol(chainId, defaultCollateralSymbol), // todo temp for testnet
      to: getToken(chainId, defaultTokens.Short.to),
      collateralAsset: getTokenBySymbol(chainId, defaultCollateralSymbol),
    },
    {
      type: "Swap",
      from: getToken(chainId, defaultTokens.Swap.from),
      to: getToken(chainId, defaultTokens.Swap.to),
    },
  ];
  const checkLongAvailableAmount = (positionTmp) => {
    if (isLong() && positionTmp && positionTmp.size && positionTmp.size > marketInfo?.available) {
      return false;
    }
    return true;
  };
  const checkMinCollateralValue = (positionTmp) => {
    if (positionTmp && positionTmp.collateralValue && positionTmp.collateralValue <= MIN_COLLATERAL_VALUE) {
      return false;
    }
    return true;
  };

  const checkShortAvailableAmount = (positionTmp) => {
    if (!isLong() && positionTmp && positionTmp.positionValue && positionTmp?.positionValue > marketInfo?.available) {
      return false;
    }
    if (positionTmp.positionValue < MIN_COLLATERAL_VALUE) return false;
    return true;
  };

  const getButtonText = () => {
    if (!active) return "Connect Wallet";
    if (isApproving) {
      return "Approving";
    }
    if (!checkMinCollateralValue(positionsInfo)) {
      return "Min Collateral Value: $10";
    }
    if (!checkShortAvailableAmount(positionsInfo) || !checkLongAvailableAmount(positionsInfo)) {
      // return `Insufficient ${positionsToken?.current?.token?.symbol} Balance`;
      return `Max Allow ${marketInfo?.available} ${defaultTokensInfo[state.tabIndex]?.collateralAsset?.baseSymbol}`;
    }
    if (allowanceAmount && payTokenInfo && allowanceAmount < payTokenInfo.amount) {
      return `Approve ${payTokenInfo.token.symbol}`;
    }
    const symbol = positionsToken?.current?.token?.symbol;
    return `${isLong() ? "BUY" : "SELL"}  ${symbol ? symbol : "Token"}`;
  };

  const tabs = [
    {
      icon: isLong() ? longImg : longDarkImg,
      text: "Long",
      activeClass: "Exchange-right-long-active",
    },
    {
      icon: !isLong() ? shortImg : shortDarkImg,
      text: "Short",
      activeClass: "Exchange-right-short-active",
    },
    // {
    //   icon: swapImg,
    //   text: "Swap",
    // },
  ];

  const leverageMarks = {
    2: "2x",
    // 5: "5x",
    10: "10x",
    // 15: "15x",
    20: "20x",
    // 25: "25x",
    30: "30x",
    // 35: "35x",
    40: "40x",
    // 45: "45x",
    50: "50x",
  };
  const { data: poolAsset } = useSWR(
    [
      `Exchange:poolAsset${active}`,
      chainId,
      poolAddress,
      "getPoolAsset",
      defaultTokensInfo[state.tabIndex]?.collateralAsset?.isNative
        ? defaultTokensInfo[state.tabIndex]?.collateralAsset?.wrappedAddress
        : defaultTokensInfo[state.tabIndex]?.collateralAsset?.address,
    ],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const marketInfo = getMarketInfo(poolAsset, defaultTokensInfo[state.tabIndex]?.collateralAsset);

  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-option"],
    "2"
  );

  const leverageSliderHandle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${parseFloat(value).toFixed(2)}x`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Slider.Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const clickTab = (index) => {
    setState({ tabIndex: index });
  };

  const setPositionsToken = (tokenInfo) => {
    // console.log("setPositionsToken", tokenInfo.token);
    positionsToken.current = tokenInfo;
    setPositionsInfo({});
    payTokenChild?.current?.clearInput();

    setToTokenAddress(defaultTokensInfo[state.tabIndex].type, tokenInfo.token.address);
  };

  useEffect(() => {
    if (!payTokenInfo) return;
    if (account) {
      getAllowanceAmount(payTokenInfo.token);
    }
    if (payTokenInfo.token.symbol) setPositionsInfo({});
  }, [payTokenInfo, account]);

  useEffect(() => {
    if (!payTokenInfo || !payTokenInfo.token || !positionsToken.current || !positionsToken.current.token) return;
    const payAmount = payTokenInfo.amount;
    // console.log("payTokenInfo", payTokenInfo);
    // console.log("positionsToken", positionsToken.current);
    if (payAmount && payAmount !== "" && isNumber(payAmount) && tokenPrice) {
      const positionData = getPositionsInfoAndPosition(
        tokenPrice,
        orderType,
        payTokenInfo,
        positionsToken.current,
        defaultTokensInfo[state.tabIndex].collateralAsset,
        leverageOption,
        isLong(),
        slippage,
        positions
      );
      if (isLong()) {
        setDisabledButton(!checkLongAvailableAmount(positionData) || !checkMinCollateralValue(positionData));
      } else {
        setDisabledButton(!checkShortAvailableAmount(positionData) || !checkMinCollateralValue(positionData));
      }
      setPositionsInfo(positionData);
      getSwapAndExecutionFee(chainId, signer, payTokenInfo, defaultTokensInfo[state.tabIndex].collateralAsset).then(
        (fee) => {
          setSwapFee(fee.swapFee);
          setExecutionFee(fee.executionFee);
        }
      );
    } else {
      setSwapFee(undefined);
    }
  }, [leverageOption, payTokenInfo, orderType]);
  const rightCells = [
    { split: true },
    {
      title: "Collateral Asset",
      value: defaultTokensInfo[state.tabIndex].collateralAsset.symbol,
      rightIcon: defaultTokensInfo[state.tabIndex].collateralAsset.imageUrl,
    },
    {
      title: "Collateral Value",
      value: `$${formatMoney(
        positionsInfo?.changed ? positionsInfo?.changed?.new?.collateralValue : positionsInfo?.collateralValue,
        2
      )}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.collateralValue, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Leverage",
      value: `${positionsInfo?.changed ? positionsInfo?.changed?.new?.leverage?.toFixed(2) : leverageOption}x`,
      beforeValue: `${formatMoney(positionsInfo?.changed?.old?.leverage, 2)}x`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Mark Price",
      value: `$${formatMoney(
        orderType?.type === 1
          ? orderType.price
          : tokenPrice && tokenPrice[positionsToken.current?.token?.symbol]
            ? tokenPrice[positionsToken.current?.token?.symbol].price
            : positionsToken.current?.price,
        positionsToken.current?.token?.displayDecimals
      )}`,
    },
    {
      title: "Entry Price",
      value: `$${formatMoney(
        positionsInfo?.changed ? positionsInfo?.changed?.new?.entryPrice : positionsInfo?.entryPrice,
        positionsToken.current?.token?.displayDecimals
      )}`,
      beforeValue: `$${formatMoney(
        positionsInfo?.changed?.old?.entryPrice,
        positionsToken.current?.token?.displayDecimals
      )}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Liquidation Price",
      value: `$${formatMoney(
        positionsInfo?.changed ? positionsInfo?.changed?.new?.liqPrice : positionsInfo?.liqPrice,
        positionsToken.current?.token?.displayDecimals
      )}`,
      beforeValue: `$${formatMoney(
        positionsInfo?.changed?.old?.liqPrice,
        positionsToken.current?.token?.displayDecimals
      )}`,
      changed: positionsInfo?.changed !== undefined,

      valueClass: "waring-color",
    },

    // { title: "Fees", value: "0.21%" },
  ];

  const marketInfoCells = [
    {
      title: "Borrow Fee",
      value: `${marketInfo?.borrowFee}% per hour`,
    },
    {
      title: "Available Liquidity",
      value: `${marketInfo?.available} ${defaultTokensInfo[state.tabIndex]?.collateralAsset?.symbol}`,
    },
  ];
  const buttonClick = async () => {
    if (active && account) {
      const { token, amount } = payTokenInfo;
      if (!amount || !isNumber(amount)) {
        helperToast.error("Please enter a amount!");
        return;
      }
      if (positionsInfo && positionsInfo.collateralValue && positionsInfo.collateralValue < MIN_COLLATERAL_VALUE) {
        helperToast.error("Min Collateral Value: $10");
        return;
      }
      const { decimals } = token;
      if (!token.isNative) {
        // if(!allowanceAmount){
        //   const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        //   const allowance = await tokenContract.allowance(account, orderManagerAddr);
        //   setAllowanceAmount(formatAmount(allowance,token.decimals,token.decimals));
        // }
        if (allowanceAmount < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: orderManagerAddr,
            chainId,
            onApproveSubmitted: () => {
              setAllowanceAmount(amount);
            },
          });
          return;
        }
      }
      setInProcess(true);
      setShowModal(true);
    } else {
      openConnectModal();
    }
  };

  const confirmed = () => {
    setShowModal(false);
    payTokenChild?.current?.clearInput();
  };
  return (
    <div className="Exchange-swap-box">
      <div className={cx("Tab", "block", "Exchange-swap-option-tabs")}>
        {tabs.map((opt, index) => (
          <div
            className={cx(`${state.tabIndex === index ? opt.activeClass : ""}`, "Tab-option", "muted")}
            onClick={() => clickTab(index)}
            key={index}
          >
            <img className="Tab-option-icon icon-xs" src={opt.icon} alt={opt.icon} />
            <span> {opt.text}</span>
          </div>
        ))}
      </div>
      <OrderType callBack={setOrderType} tokenPrice={tokenPrice} token={positionsToken?.current?.token} />
      <div className="Liquidity-card-split"></div>
      <CurrencySelect
        setStakeTokenInfo={setPayTokenInfo}
        title={"Pay"}
        ref={payTokenChild}
        defaultSelectedToken={defaultTokensInfo[state.tabIndex]?.from}
      />
      <div className="flex justify-center">
        <img src={SingleSwapIcon} className="icon-xs" alt="Swap"></img>
      </div>
      <CurrencySelect
        setStakeTokenInfo={setPositionsToken}
        title={"Positions Size"}
        displayAmount={positionsInfo?.size}
        hideMax={true}
        customerToken={AVAILABLE_CHART_TOKENS[chainId]}
        hideBalance={true}
        defaultSelectedToken={defaultTokensInfo[state.tabIndex]?.to}
      />
      <div
        className={cx("Exchange-leverage-slider", " ml-15 mr-15", {
          "App-slider": state.tabIndex === 0,
          "App-slider-sell": state.tabIndex === 1,
        })}
      >
        <div className="mb-20 flex  ">
          <div>Leverage</div>
          <button
            type="button"
            className="btn btn-outline-secondary  ml-10"
            style={{ "--bs-btn-padding-y": ".05rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".45rem" }}
          >
            {leverageOption}X
          </button>
        </div>
        <Slider
          min={1.1}
          max={MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR}
          step={0.1}
          marks={leverageMarks}
          handle={leverageSliderHandle}
          onChange={(value) => setLeverageOption(value)}
          defaultValue={leverageOption}
        />
      </div>
      <div className="Earn-detail-right-cell">
        <div className={cx("flex justify-between aligns-center ", "mt-40")}>
          <div className="Earn-detail-left-subtitle">Slippage</div>
          <div className="Earn-detail-left-value flex aligns-center">
            <div className="ml-5"> {slippage * 100}%</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
          <Button
            variant={`${state.tabIndex === 0 ? "Long-action" : "Short-action"} `}
            className="w-100"
            disabled={disabledButton}
          >
            {getButtonText()}
          </Button>
        </div>
        {rightCells.map((item, index) =>
          item.split ? (
            <div className="Liquidity-card-split mb-20" key={index}></div>
          ) : (
            <div className={cx("flex justify-between aligns-center ", "mb-10")} key={index}>
              <div className="Earn-detail-left-subtitle">{item.title}</div>
              <div className="Earn-detail-left-value flex aligns-center">
                {item.rightIcon && <img src={item.rightIcon} alt={item.value} className="icon-xs"></img>}
                {item.changed && (
                  <div className="mr-10 Earn-detail-left-subtitle">
                    {item.beforeValue}
                    <span className="ml-10">→</span>
                  </div>
                )}
                <div className={`ml-5 ${item.valueClass ? item.valueClass : ""}`}> {item.value}</div>
              </div>
            </div>
          )
        )}
        {swapFee > 0 && (
          <div className={cx("flex justify-between aligns-center ", "mb-10")}>
            <div className="Earn-detail-left-subtitle">Swap Fee</div>
            <div className="Earn-detail-left-value flex aligns-center">
              <div className="ml-5"> {swapFee.toFixed(4)}%</div>
            </div>
          </div>
        )}
        <div className="Liquidity-card-split mb-20 mt-20"></div>

        <div className="mb-10">Market Info</div>
        {marketInfoCells.map((item, index) =>
          item.split ? (
            <div className="Liquidity-card-split mb-20" key={index}></div>
          ) : (
            <div className={cx("flex justify-between aligns-center ", "mb-10")} key={index}>
              <div className="Earn-detail-left-subtitle">{item.title}</div>
              <div className="Earn-detail-left-value flex aligns-center">
                {item.rightIcon && <img src={item.rightIcon} alt={item.value} className="icon-xs"></img>}
                <div className="ml-5"> {item.value}</div>
              </div>
            </div>
          )
        )}
      </div>
      <ExchangeModal
        payToken={payTokenInfo}
        assetToken={defaultTokensInfo[state.tabIndex].collateralAsset}
        positionsToken={positionsToken.current}
        side={state.tabIndex}
        chainId={chainId}
        account={account}
        active={active}
        signer={signer}
        positionsInfo={positionsInfo}
        leverage={leverageOption}
        slippage={slippage}
        isOpenPosition={true}
        orderType={orderType}
        setPendingTxns={setPendingTxns}
        tokenPrice={tokenPrice}
        closeModal={() => {
          setShowModal(false);
        }}
        onConfirmed={() => {
          confirmed();
        }}
        showModal={showModal}
        fee={{
          borrowFee: marketInfo?.borrowFee,
          swapFee: swapFee,
          executionFee: executionFee,
          positionFee: positionsInfo?.positionFee,
        }}
      />
    </div>
  );
}
