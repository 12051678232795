import cx from "classnames";
import React, { useState } from "react";
import "./ExchangeModal.css";
import { expandOfStringDecimals, formatMoney } from "lib/numbers";
import SingleSwapIcon from "img/ic_single_swap.png";
import Button from "components/Button/Button";
import { ethers } from "ethers";
import { callContract } from "lib/contracts";
import OrderManager from "abis/OrderManager.json";
import { getContract } from "config/contracts";
import { encodePositionData } from "lib/positions";
import { defaultTokenDecimals, defaultPriceDecimals, defaultValueDecimals } from "lib/pool";
import { ExchangeModalCell } from "components/Exchange/ExchangeModalCell";
import { getReferrerBytes } from "lib/referral";
export function ExchangeModal ({
  payToken,
  positionsToken,
  assetToken,
  side,
  leverage,
  positionsInfo,
  fee,
  slippage,
  closeModal,
  showModal,
  chainId,
  account,
  signer,
  active,
  onConfirmed,
  setPendingTxns,
  isOpenPosition,
  orderType,
  tokenPrice,
}) {
  const [confirming, setConfirming] = useState(false);
  const orderManagerAddr = getContract(chainId, "OrderManager");

  /**
   * UpdatePositionType _updateType,
   * Side _side,
   * address _indexToken,
   * address _collateralToken,
   * OrderType _orderType,
   * bytes calldata data */

  /**
   * Order Price: 26810.633214867600
   * Order Pay Token: 0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c
   * Purchase Amount: 0.000300000000000000
   * Request Size Change: 381.863455559999980906827222000000
   * Request Collateral: 0.00300000000000000
   */
  const buttonClick = () => {
    if (!positionsInfo || !payToken || !account || !positionsInfo || !fee) return;
    let { decimals, address } = payToken.token;
    let msgValue = parseFloat(fee.executionFee);
    if (payToken.token.isNative) {
      address = payToken.token.placeHolderAddress;
      msgValue += parseFloat(payToken.amount);
    }
    // console.log("msgValue", msgValue);

    const orderPrice = expandOfStringDecimals(
      positionsInfo.triggerPrice,
      defaultPriceDecimals + defaultTokenDecimals - positionsToken.token.decimals
    );
    const payAmount = expandOfStringDecimals(payToken.amount, decimals);
    const positionValue = expandOfStringDecimals(
      fee.swapFee
        ? (positionsInfo.positionValue - fee.positionFee * leverage) * (1 - fee.swapFee / 100)
        : // ?  (positionsInfo.positionValue - fee.positionFee) * (1 - 0.0065)
        positionsInfo.positionValue,
      // positionsInfo.positionValue,
      defaultValueDecimals
    );
    msgValue = expandOfStringDecimals(msgValue, defaultTokenDecimals);
    const contract = new ethers.Contract(orderManagerAddr, OrderManager.abi, signer);
    // console.log("orderPrice", orderPrice.toString());
    // console.log("payAmount", payAmount.toString());
    // console.log("positionValue", positionValue.toString());
    // console.log("msgValue", msgValue.toString());
    // console.log(
    //   "indexToken",
    //   positionsToken.token.isNative ? positionsToken.token.wrappedAddress : positionsToken.token.address
    // );

    // console.log("assetToken", assetToken.isNative ? assetToken.wrappedAddress : assetToken.address);
    // console.log("payToken", address);

    callContract(
      chainId,
      contract,
      "placeOrder",
      [
        isOpenPosition ? 0 : 1,
        side,
        positionsToken.token.isNative ? positionsToken.token.wrappedAddress : positionsToken.token.address,
        assetToken.isNative ? assetToken.wrappedAddress : assetToken.address,
        orderType.type,
        encodePositionData([orderPrice, address, payAmount, positionValue, payAmount, getReferrerBytes()]),
      ],

      {
        sentMsg: `Confirm submitted!`,
        failMsg: `Confirm failed.`,
        value: msgValue,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setConfirming(false);
        onConfirmed();
      });
  };
  const getButtonText = () => {
    if (confirming) {
      return "Confirming...";
    }
    return "Confirm";
  };
  const cells = [
    {
      title: "Leverage",
      value: `${positionsInfo?.changed ? positionsInfo?.changed?.new?.leverage?.toFixed(2) : leverage}x`,
      beforeValue: `${formatMoney(positionsInfo?.changed?.old?.leverage, 2)}x`,
      changed: positionsInfo?.changed !== undefined,
    },
    { title: "Slippage", value: `${slippage * 100}%` },
    { split: true },

    {
      title: "Collateral Asset",
      value: assetToken.symbol,
      rightIcon: assetToken.imageUrl,
    },
    {
      title: "Collateral Value",
      value: `$${formatMoney(
        positionsInfo?.changed ? positionsInfo?.changed?.new?.collateralValue : positionsInfo?.collateralValue,
        2
      )}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.collateralValue, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    { split: true },
    {
      title: "Trigger Condition",
      value: `Mark Price ${side === 0 ? "≤" : "≥"} $${formatMoney(
        positionsInfo?.triggerPrice,
        positionsToken?.token.displayDecimals
      )}`,
    },

    {
      title: "Entry Price",
      value: `$${formatMoney(
        positionsInfo?.changed ? positionsInfo?.changed?.new?.entryPrice : positionsInfo?.entryPrice,
        positionsToken?.token?.displayDecimals
      )}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.entryPrice, positionsToken?.token?.displayDecimals)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Liquidation Price",
      value: `$${formatMoney(
        positionsInfo?.changed ? positionsInfo?.changed?.new?.liqPrice : positionsInfo?.liqPrice,
        positionsToken?.token?.displayDecimals
      )}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.liqPrice, positionsToken?.token?.displayDecimals)}`,
      changed: positionsInfo?.changed !== undefined,
      valueClass: "waring-color",
    },
    { split: true },

    { title: "Position Fee", value: `$${formatMoney(fee?.positionFee, 4)}` },
    { title: "Swap Fee", value: fee?.swapFee ? `${fee?.swapFee.toFixed(4)}%` : undefined },
    { title: "Execution Fee", value: `${fee?.executionFee} ETH` },
    { title: "Borrow Fee", value: `${fee?.borrowFee}% per hour ` },
  ];
  return (
    <div
      className={cx("modal fade Exchange-modal", { show: showModal })}
      aria-modal={showModal}
      style={{ display: showModal ? "block" : "none" }}
      role="dialog"
      aria-hidden={showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3">
              {side === 0 ? "Long" : "Short"} {positionsToken?.token?.symbol}
            </h1>
            <div data-bs-theme="dark">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </div>
          <div className="Liquidity-card-split "></div>
          <div className="modal-body mt-10">
            <div className="Exchange-modal-input flex justify-between aligns-center">
              <div className="flex aligns-center">
                <img src={payToken?.token?.imageUrl} alt="" className="icon-sm"></img>
                <div className="ml-10">{payToken?.token.symbol}</div>
              </div>
              <div className=" flex-col   align-items-end ">
                <div className="fs-3 fw-bold">{payToken?.amount}</div>
                <div className="Exchange-modal-sub-title">≈ ${formatMoney(payToken?.amount * payToken?.price, 2)}</div>
              </div>
            </div>
            <div className="flex justify-center mt-10 mb-10">
              <img src={SingleSwapIcon} className="icon-xs" alt="Swap"></img>
            </div>
            <div className="Exchange-modal-input flex justify-between aligns-center">
              <div className="flex aligns-center">
                <img src={positionsToken?.token?.imageUrl} alt="" className="icon-sm"></img>
                <div className="ml-10">{positionsToken?.token.symbol}</div>
              </div>
              <div className=" flex-col align-items-end">
                <div className="fs-3 fw-bold">{positionsInfo?.size?.toFixed(6)}</div>
                <div className="Exchange-modal-sub-title">≈ ${formatMoney(positionsInfo?.positionValue, 2)}</div>
              </div>
            </div>
            <ExchangeModalCell cells={cells} />
          </div>
          <div className="">
            <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
              <Button variant="primary-action" className="w-100">
                {getButtonText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
