import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Detail.css";
import LiquidityTab from "components/LiquidityCard/LiquidityTab";
import cx from "classnames";
import SingleSwapIcon from "img/ic_single_swap.png";
import Button from "components/Button/Button";
import { getTokenBySymbol } from "config/tokens";
import { getPoolData } from "./services";
import Pool from "abis/Pool.json";
import { formatAmount, isNumber, expandOfStringDecimals, formatMoney } from "lib/numbers";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { callContract, contractFetcher } from "lib/contracts";
import useSWR from "swr";
import DIPMaster from "abis/DIPMaster.json";
import ERC20 from "abis/ERC20.json";
import TokenDistribution from "components/TokenDistribution/TrancheTokenDistribution";
import CurrencySelect from "components/Currency/CurrencySelect";
import { calcFeeRate, defaultTokenDecimals, getReceive } from "lib/pool";
import { BigNumber, ethers } from "ethers";
import { approveTokens, getTokenInfo } from "domain/tokens";
import { getConstant } from "config/chains";
import { useDLPPrices } from "domain/pool";
import { useDipPairPrice } from "domain/prices";
import BackButton from "components/Button/BackButton";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { helperToast } from "lib/helperToast";

export default function EarnDetail ({ setPendingTxns }) {

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();

  const { chainId } = useChainId();
  const { poolIndex } = useParams();
  const [trancheIndex, setTrancheIndex] = useState(poolIndex - 1);

  const dipMasterAddr = getContract(chainId, "DIPMaster");
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const SnrDLP = getTokenBySymbol(chainId, "SnrDLP");
  const MezzDLP = getTokenBySymbol(chainId, "MezzDLP");
  const JnrDLP = getTokenBySymbol(chainId, "JnrDLP");

  const { data: snrManagementData } = useSWR(
    [`Earn:SnrBalance${active}`, chainId, SnrDLP.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const { data: mezzManagementData } = useSWR(
    [`Earn:MezzBalance${active}`, chainId, MezzDLP.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const { data: jnrManagementData } = useSWR(
    [`Earn:JnrBalance${active}`, chainId, JnrDLP.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const dlpPrices = useDLPPrices(chainId, signer);
  const pairPrice = useDipPairPrice(chainId, signer);
  const snrPool = getPoolData(snrManagementData, 0, dlpPrices?.snrDLPPrice, pairPrice?.dipPrice);
  const mezzPool = getPoolData(mezzManagementData, 1, dlpPrices?.mezzDLPPrice, pairPrice?.dipPrice);
  const jnrPool = getPoolData(jnrManagementData, 2, dlpPrices?.jnrDLPPrice, pairPrice?.dipPrice);

  const history = useHistory();
  const tranches = [
    {
      id: 0,
      title: "Senior    Tranche",
      lpToken: getTokenBySymbol(chainId, "SnrDLP"),
      risk: 1,
      perDay: "6,000",
      lpPrice: snrPool?.lpPrice ? formatMoney(snrPool?.lpPrice, 4) : "---",
      management: `${snrPool?.totalAmount ? snrPool?.totalAmount : "---"}`,
      managementValue: `${snrPool?.totalValue ? snrPool?.totalValue : "---"}`,
      // management: `$${formatAmount(SnrTrancheValue, 30, 2, true)}`,
      apr: snrPool?.apr,
      icon: require("img/ic_snr.png"),
    },
    {
      id: 1,
      title: "Mezzanine Tranche",
      lpToken: getTokenBySymbol(chainId, "MezzDLP"),
      perDay: "6,000",
      lpPrice: mezzPool?.lpPrice ? formatMoney(mezzPool?.lpPrice, 4) : "---",
      management: `${mezzPool?.totalAmount ? mezzPool?.totalAmount : "---"}`,
      managementValue: `${mezzPool?.totalValue ? mezzPool?.totalValue : "---"}`,
      apr: mezzPool?.apr,
      icon: require("img/ic_mezz.png"),
    },
    {
      id: 2,
      title: "Junior    Tranche",
      lpToken: getTokenBySymbol(chainId, "JnrDLP"),
      risk: 3,
      perDay: "12,000",
      lpPrice: jnrPool?.lpPrice ? formatMoney(jnrPool?.lpPrice, 4) : "---",
      management: `${jnrPool?.totalAmount ? jnrPool?.totalAmount : "---"}`,
      managementValue: `${jnrPool?.totalValue ? jnrPool?.totalValue : "---"}`,
      apr: jnrPool?.apr,
      icon: require("img/ic_jnr.png"),
    },
  ];

  let defaultPoolInfo = tranches[poolIndex - 1];
  let [poolInfo, setPoolInfo] = useState(undefined);
  const getPoolInfo = () => {
    return poolInfo ? poolInfo : defaultPoolInfo;
  };
  const { data: dlpWalletBalance } = useSWR(
    [`Earn:DlpWalletBalance${active}`, chainId, getPoolInfo()?.lpToken.address, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const { data: claimableRewards } = useSWR(
    active && [active, chainId, dipMasterAddr, "pendingReward", getPoolInfo()?.id, account],
    {
      fetcher: contractFetcher(signer, DIPMaster),
    }
  );
  const claimableDIP = formatAmount(claimableRewards ? claimableRewards : "0", 18, 4, true);

  const { data: userInfoData } = useSWR(
    active && [active, chainId, dipMasterAddr, "userInfo", getPoolInfo()?.id, account],
    {
      fetcher: contractFetcher(signer, DIPMaster),
    }
  );
  const userInfo = {
    amount: formatAmount(userInfoData?.amount, 18, 4, true),
  };
  const leftCells = [
    { title: "Claimable Rewards", value: `${claimableDIP}`, unit: "DIP" },
    {
      title: "Staking Balance",
      value: userInfo.amount,
      unit: getPoolInfo().lpToken.name,
    },
    { split: true },
    { title: "LP Price", value: `$${getPoolInfo().lpPrice}` },
    { title: "Earn per day", value: getPoolInfo().perDay, unit: "DIP/Day" },
  ];

  const rowDefaultClass = "flex aligns-center justify-between";
  const [state, setState] = useState({ tabIndex: 1 });

  const switchPool = (index) => {
    setTrancheIndex(index);
    // poolInfo = tranches[0];
    setPoolInfo(tranches[index]);
  };

  const clickTab = (index) => {
    setState({
      tabIndex: index,
    });
  };
  const [stakeTokenInfo, setStakeTokenInfo] = useState();
  const [receiveTokenInfo, setReceiveTokenInfo] = useState({ minValue: "" });
  const [fee, setFee] = useState("");

  useEffect(() => {
    // console.log("stakeTokenInfo", stakeTokenInfo);
    if (!stakeTokenInfo) {
      setFee("");
      setReceiveTokenInfo({ minValue: "" });
      return;
    }
    const { amount, token, price } = stakeTokenInfo;
    if (!amount || amount === "" || !isNumber(amount)) {
      setReceiveTokenInfo({ minValue: "" });
      return;
    }
    calcFeeRate(chainId, signer, token, 1, amount, true).then((fee) => {
      // console.log("fee", fee.toString());
      setFee(fee);
      const receive = getReceive(price, getPoolInfo().lpPrice, amount, 0.3, fee, true);
      setReceiveTokenInfo(receive);
    });
    return () => { };
  }, [stakeTokenInfo]);

  const [isApproving, setIsApproving] = useState();
  const [isStaking, setIsStaking] = useState();

  const deposit = async () => {
    if (active && stakeTokenInfo && receiveTokenInfo) {
      const { token, amount } = stakeTokenInfo;
      const { decimals } = token;
      if (!token.isNative) {
        const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        const allowanceAmount = await tokenContract.allowance(account, dipMasterAddr);
        if (parseFloat(formatAmount(allowanceAmount, decimals, 4)) < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: dipMasterAddr,
            chainId,
          });
          return;
        }
      }

      setIsStaking(true);

      const contract = new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer);
      let methodName = "";
      let params = [];
      let value = undefined;
      const pId = getPoolInfo().id;
      let minLpReceive = expandOfStringDecimals(receiveTokenInfo.minValue, getPoolInfo().lpToken.decimals);
      minLpReceive = 0;
      const depositAmount = expandOfStringDecimals(amount, token.decimals);
      if (token.isNative) {
        methodName = "addLiquidityETH";
        params = [pId, minLpReceive, account];
        value = depositAmount;
      } else {
        methodName = "addLiquidity";
        params = [pId, token.address, depositAmount, minLpReceive, account];
      }

      // console.log(params);
      // console.log(depositAmount.toString());
      // console.log(minLpReceive.toString());
      callContract(chainId, contract, methodName, params, {
        sentMsg: `Buy DLP submitted!`,
        failMsg: `Buy DLP failed.`,
        value: value,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };

  const depositOnly = async () => {
    if (active && account && signer) {
      const amount = dlpWalletBalance;
      const token = getPoolInfo()?.lpToken

      if (amount.lte(BigNumber.from("0"))) {
        helperToast.error(`Invalid ${token?.symbol} balance`)
        return;
      }
      const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
      const allowanceAmount = await tokenContract.allowance(account, dipMasterAddr);
      if (allowanceAmount.lt(amount)) {
        approveTokens({
          setIsApproving,
          signer,
          tokenAddress: token.address,
          spender: dipMasterAddr,
          chainId,
        });
        return;
      }


      setIsStaking(true);

      const contract = new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer);
      let methodName = "deposit";
      let params = [];
      let value = undefined;
      const pId = getPoolInfo().id;
      methodName = "deposit";
      params = [pId, dlpWalletBalance, account];


      // console.log(params);
      // console.log(depositAmount.toString());
      // console.log(minLpReceive.toString());
      callContract(chainId, contract, methodName, params, {
        sentMsg: `Deposit DLP submitted!`,
        failMsg: `Deposit DLP failed.`,
        value: value,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };

  const withdraw = async () => {
    if (active && sellReceiveAmountInfo && sellReceiveTokenInfo) {
      const { token } = sellReceiveTokenInfo;
      // const { decimals } = token;
      // if (!token.isNative) {
      //   const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
      //   const allowanceAmount = await tokenContract.allowance(account, dipMasterAddr);
      //   if (parseFloat(formatAmount(allowanceAmount, decimals, 4)) < amount) {
      //     approveTokens({
      //       setIsApproving,
      //       signer,
      //       tokenAddress: token.address,
      //       spender: dipMasterAddr,
      //       chainId,
      //     });
      //     return;
      //   }
      // }

      setIsStaking(true);

      const contract = new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer);
      let methodName = "";
      let params = [];
      const pId = getPoolInfo().id;
      let minReceive = expandOfStringDecimals(sellReceiveAmountInfo.minValue, token.decimals);
      minReceive = 0;
      const sellAmount = expandOfStringDecimals(sellLpAmount, getPoolInfo().lpToken.decimals);
      if (token.isNative) {
        methodName = "removeLiquidityETH";
        params = [pId, sellAmount, minReceive, account];
      } else {
        methodName = "removeLiquidity";
        params = [pId, sellAmount, token.address, minReceive, account];
      }

      // console.log(params);
      // console.log(minReceive.toString());
      callContract(chainId, contract, methodName, params, {
        sentMsg: `Sell DLP submitted!`,
        failMsg: `Sell DLP failed.`,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };
  const claim = async () => {
    callContract(
      chainId,
      new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer),
      "harvest",
      [getPoolInfo().id, account],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const [sellLpAmount, setSellLpAmount] = useState();

  const [sellReceiveTokenInfo, setSellReceiveTokenInfo] = useState();
  const [sellReceiveAmountInfo, setSellReceiveAmountInfo] = useState({ valueAfterFee: " ", minValue: " " });

  useEffect(() => {
    setSellReceiveAmountInfo({ valueAfterFee: " ", minValue: " " });
    setSellLpAmount("");
  }, [sellReceiveTokenInfo]);
  const lpAmountHandleChange = (e) => {
    const value = e.target.value;
    // if (!value || value === "") return;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setSellLpAmount(value);
      if (!sellReceiveTokenInfo || !value || !isNumber(value)) return;
      const { token, price } = sellReceiveTokenInfo;
      if (!token) return;
      // console.log("sell token price", price);
      // console.log("sell value", value);
      // console.log("sell token", token.symbol);
      calcFeeRate(chainId, signer, sellReceiveTokenInfo.token, 1, value, true).then((fee) => {
        // console.log("sellfee", fee.toString());
        setFee(fee);
        const receive = getReceive(price, getPoolInfo().lpPrice, value, 0.3, fee, false);
        // console.log("sellReceive", receive);
        setSellReceiveAmountInfo(receive);
      });
      // setStakeTokenInfo({ amount: value, token: selectedToken, price: price });
    }
  };

  const buttonClick = async () => {
    if (state.tabIndex === 1) {
      await deposit();
    } else {
      await withdraw();
    }
  };
  const getButtonText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    if (isApproving) {
      return "Approving";
    }
    if (isStaking) {
      return state.tabIndex === 1 ? "Buying" : "Selling";
    }

    return state.tabIndex === 1 ? "Buy" : "Sell";
  };
  const rightCells =
    state.tabIndex === 1
      ? [
        { title: "Slippage", value: "0.3%" },
        { title: "Minimum Received", value: `${receiveTokenInfo?.minValue} ${getPoolInfo()?.lpToken.symbol}` },
        // { title: "Weight/Target", value: "--%/--%" },
        { title: "Fees", value: `${fee}%` },
      ]
      : [
        { title: "Slippage", value: "0.3%" },
        {
          title: "Minimum Received",
          value: `${sellReceiveAmountInfo?.minValue} ${sellReceiveTokenInfo?.token?.symbol}`,
        },
        // { title: "Weight/Target", value: "--%/--%" },
        { title: "Fees", value: `${fee}%` },
      ];
  return (
    <div className="default-bg">
      <div className="default-container pt-40">
        <BackButton></BackButton>
        <div className="row">
          {tranches.map((item, index) => (
            <div
              className="mt-20 col-4"
              key={index}
              onClick={() => {
                switchPool(index);
              }}
            >
              <LiquidityTab data={{ ...item, active: trancheIndex == index }}></LiquidityTab>
            </div>
          ))}
        </div>
        <div className="row mt-20">
          <div className="col-12 col-md-8 order-md-1 order-1">
            <div className="order-1">
              <div className={cx("Earn-detail-left-header", rowDefaultClass)}>
                <div className="flex aligns-center">
                  <img src={getPoolInfo()?.icon} className="icon-sm mr-10" alt=""></img>
                  {getPoolInfo().title}
                </div>
                <div className="default-btn" onClick={claim}>
                  Claim
                </div>
              </div>
              <div className="Earn-detail-left-content">
                {leftCells.map((item, index) =>
                  item.split ? (
                    <div className="Liquidity-card-split mt-10" key={index}></div>
                  ) : (
                    <div className={cx(rowDefaultClass, { "mt-10": index > 0 })} key={index}>
                      <div className="Earn-detail-left-subtitle">{item.title}</div>
                      <div className="Earn-detail-left-value flex aligns-center">
                        <div>{item.value}</div>
                        {item.unit && <div className="Earn-detail-left-subtitle ml-5">{item.unit}</div>}
                      </div>
                    </div>
                  )
                )}

                <div className={cx(rowDefaultClass, "mt-10")}>
                  <div className="Earn-detail-left-subtitle">APR</div>
                  <div className="flex-col justify-center align-items-end">
                    <div className="Earn-detail-left-value">
                      {getPoolInfo()?.apr ? formatMoney(getPoolInfo()?.apr, 2) : "---"}%
                    </div>
                    {/* <div className="Earn-detail-left-sub-value">
                      Fee --% + DIP {getPoolInfo()?.apr ? formatMoney(getPoolInfo()?.apr, 2) : "---"}%{" "}
                    </div> */}
                  </div>
                </div>
                <div className={cx(rowDefaultClass, "mt-10")}>
                  <div className="Earn-detail-left-subtitle">Asset under Manager</div>
                  <div className="flex-col justify-center align-items-end">
                    <div className="Earn-detail-left-value flex aligns-center">
                      <div> {getPoolInfo().management} </div>
                      <div className="Earn-detail-left-subtitle ml-5"> {getPoolInfo().lpToken.name}</div>
                    </div>
                    <div className="Earn-detail-left-sub-value">${getPoolInfo()?.managementValue}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="order-3 mt-20">
              <div className="Earn-header-title"> Token Distribution</div>
              <div>
                <TokenDistribution
                  showInDetail={false}
                  trancheAddress={getPoolInfo()?.lpToken.address}
                ></TokenDistribution>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-2">
            <div className="Ear-detail-right p-4">
              <div className="flex  justify-between  aligns-center ">
                <div>
                  <div> <img src={require("img/ic_wallet.png")} className="icon-xs mr-10" style={{ borderRadius: "50%", }}></img>Wallet Balance</div>
                  <div className="Earn-detail-left-subtitle">
                    <img src={getPoolInfo()?.icon} className="icon-xs mr-10" alt=""></img>
                    {formatAmount(dlpWalletBalance, defaultTokenDecimals, 6)} {getPoolInfo()?.lpToken?.symbol}
                  </div>
                </div>
                <div>
                  <div className="Currency-selected-max cursor-pointer" onClick={depositOnly}> Stake</div>
                </div>
              </div>
            </div>
            <div className="Ear-detail-right order-2 mt-10">
              <div className="flex">
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "up-color ": state.tabIndex === 1,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 1,
                  })}
                  onClick={() => clickTab(1)}
                >
                  Buy
                </div>
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "down-color": state.tabIndex === 2,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 2,
                  })}
                  onClick={() => clickTab(2)}
                >
                  Sell
                </div>
              </div>
              {state.tabIndex === 1 && (
                <CurrencySelect setStakeTokenInfo={setStakeTokenInfo} customerToken={getPoolInfo()?.assetSymbols} />
              )}
              {state.tabIndex === 2 && (
                <div className="Earn-detail-right-buy">
                  <div className="flex justify-between">
                    <div>Amount</div>
                    <div className="Earn-detail-left-subtitle"> Staking: {userInfo.amount}</div>
                  </div>
                  <div className="Earn-detail-right-buy-input mt-10">
                    <div className="flex justify-between aligns-center">
                      <input value={sellLpAmount} type="text" onChange={lpAmountHandleChange} className="flex-1" />
                      <div className="Currency-selected-group flex justify-around aligns-center mr-10">
                        <img src={getPoolInfo()?.lpToken.imageUrl} className="icon-xs ml-5" alt="more"></img>
                        <div className="ml-5"> {getPoolInfo()?.lpToken?.symbol} </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-center">
                <img src={SingleSwapIcon} className="icon-xs" alt="Swap"></img>
              </div>
              {state.tabIndex === 1 && (
                <div className="Earn-detail-right-buy">
                  {/* <div className="Earn-detail-right-buy-input mt-10"></div> */}

                  <div className="flex justify-between ">
                    <div>RECEIVE</div>
                    {/* <div className="Earn-detail-left-subtitle"> {getPoolInfo().lpToken.symbol}</div> */}
                  </div>
                  <div className="Earn-detail-right-buy-input mt-10 flex aligns-center justify-between">
                    <div className="ml-10">{receiveTokenInfo?.valueAfterFee}</div>
                    <div className="Currency-selected-group flex justify-around aligns-center mr-10">
                      <img src={getPoolInfo()?.lpToken.imageUrl} className="icon-xs ml-5" alt="more"></img>
                      <div className="ml-5"> {getPoolInfo()?.lpToken?.symbol} </div>
                    </div>
                  </div>
                </div>
              )}
              {state.tabIndex === 2 && (
                <CurrencySelect
                  setStakeTokenInfo={setSellReceiveTokenInfo}
                  title="RECEIVE"
                  hideMax={true}
                  displayAmount={sellReceiveAmountInfo?.valueAfterFee}
                  customerToken={getPoolInfo()?.assetSymbols}
                ></CurrencySelect>
              )}

              <div className="Earn-detail-right-cell">
                {rightCells.map((item, index) =>
                  item.split ? (
                    <div className="Liquidity-card-split mt-10"></div>
                  ) : (
                    <div className={cx(rowDefaultClass, { "mt-10": index > 0 })} key={index}>
                      <div className="Earn-detail-left-subtitle">{item.title}</div>
                      <div className="Earn-detail-left-value">{item.value}</div>
                    </div>
                  )
                )}
                <div className="Exchange-swap-button-container mt-20" onClick={buttonClick}>
                  <Button variant="primary-action" className="w-100">
                    {getButtonText()}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
