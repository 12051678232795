import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useWallet from "lib/wallets/useWallet";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "components/Tooltip/Tooltip";

import { ethers } from "ethers";
import DIPIcon from "img/DIP.png";
import DGTIcon from "img/dgt-white.png";
import Quote from "components/Quote/Quote";
import Liquiditys from "components/Dashboard/Liquiditys";
import Stakings from "components/Dashboard/Stakings";
import TokenDistribution from "components/TokenDistribution";
import DefaultCard from "components/Common/DefaultCard";

import {
  USD_DECIMALS,
  GMX_DECIMALS,
  GLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDG_AMOUNT,
  getPageTitle,
  importImage,
  arrayURLFetcher,
} from "lib/legacy";
import {
  useTotalGmxInLiquidity,
  useGmxPrice,
  useTotalGmxStaked,
  useTotalGmxSupply,
  useDipPairPrice,
  useDgtPairPrice,
} from "domain/legacy";

import { getContract } from "config/contracts";

import VaultV2 from "abis/VaultV2.json";
import ReaderV2 from "abis/ReaderV2.json";
import GlpManager from "abis/GlpManager.json";
import Footer from "components/Footer/Footer";

import statsIcon from "img/ic_stats.png";
import tradingIcon from "img/ic_trading.png";
import lockedIcon from "img/ic_safe.png";
import feeIcon from "img/ic_fee.png";
import longIcon from "img/ic_long.png";
import shortIcon from "img/ic_short.png";
import "./DashboardV2.css";

import AssetDropdown from "./AssetDropdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import { useTotalVolume, useVolumeInfo, useFeesSummary } from "domain/stats";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { ARBITRUM, AVALANCHE, getChainName } from "config/chains";
import { getServerUrl } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenBySymbol, getWhitelistedTokens, GLP_POOL_COLORS } from "config/tokens";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatKeyAmount,
  numberWithCommas,
  formatMoney,
} from "lib/numbers";
import { useChainId } from "lib/chains";
import { formatDate } from "lib/dates";
import { getIcons } from "config/icons";
import Pool from "abis/Pool.json";
import ERC20 from "abis/ERC20.json";
import { useDipEthLBPInfo } from "domain/pool";
import { getPoolData } from "pages/LBP/services";
import { defaultApiUrl, getApiPathName } from "lib/api";
import { defaultTokenDecimals } from "lib/pool";
const ACTIVE_CHAIN_IDS = [ARBITRUM, AVALANCHE];

const { AddressZero } = ethers.constants;

function getPositionStats (positionStats) {
  if (!positionStats || positionStats.length === 0) {
    return null;
  }
  return positionStats.reduce(
    (acc, cv, i) => {
      acc.totalLongPositionSizes = acc.totalLongPositionSizes.add(cv.totalLongPositionSizes);
      acc.totalShortPositionSizes = acc.totalShortPositionSizes.add(cv.totalShortPositionSizes);
      acc[ACTIVE_CHAIN_IDS[i]] = cv;
      return acc;
    },
    {
      totalLongPositionSizes: bigNumberify(0),
      totalShortPositionSizes: bigNumberify(0),
    }
  );
}

function getCurrentFeesUsd (tokenAddresses, fees, infoTokens) {
  if (!fees || !infoTokens) {
    return bigNumberify(0);
  }

  let currentFeesUsd = bigNumberify(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue;
    }

    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
}

export default function DashboardV2 () {
  const { active, signer } = useWallet();
  const { chainId } = useChainId();

  const poolAddress = getContract(chainId, "Pool");
  const DIP = getTokenBySymbol(chainId, "DIP");

  const { data: aums } = useSWR([`Dashboard:getPoolAums:${active}`, chainId, poolAddress, "virtualPoolValue"], {
    fetcher: contractFetcher(signer, Pool),
  });

  const [aum, setAum] = useState();
  useEffect(() => {
    try {
      fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/aum`).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setAum(data.errno === 0 ? data.data : undefined);
          });
        }
      });
    } catch (e) { }
  }, [chainId]);
  const dipPairPrice = useDipPairPrice(chainId, signer);
  const dgtPairPrice = useDgtPairPrice(chainId, signer)
  const dipEthLBPPoolData = useDipEthLBPInfo(chainId, signer);
  const dipEthLBPPool = getPoolData(dipEthLBPPoolData, true, 3, dipPairPrice);
  // console.log(dipEthLBPPool);
  const assetsManager = parseFloat(formatAmount(aums, 30)) + parseFloat(dipEthLBPPool?.totalAmount);

  const { data: dipSupplyData } = useSWR([`Dashboard:getDIPSupply:${active}`, chainId, DIP.address, "totalSupply"], {
    fetcher: contractFetcher(signer, ERC20),
  });
  const { data: dgtTotalSupply } = useSWR(
    [`DaoStaking:DGTTotalSupply`, chainId, getTokenBySymbol(chainId, "DGT")?.address, "totalSupply"],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const dipTotalSupply = formatAmount(dipSupplyData, 18, 4, true);
  const dipFullAmount = formatMoney(formatAmount(dipSupplyData, defaultTokenDecimals, 4) * dipPairPrice?.dipPrice, 2);
  const dgtFullAmount = formatMoney(formatAmount(dgtTotalSupply, defaultTokenDecimals, 4) * dgtPairPrice?.dgtPrice, 2);

  // const dipcirculatValue = 10000000;
  const dipcirculatValue = () => {
    let now = Date.now() / 1000;
    const vestingEnd = 1815660000;
    const vestingCycle = vestingEnd - 1692108000;
    now = now > vestingEnd ? vestingEnd : now;
    const daoVestingLock = (97916667 * (vestingEnd - now)) / vestingCycle;
    const lpVestingLock = (331937500 * (vestingEnd - now)) / vestingCycle;
    const traderVestingLock = (342708333 * (vestingEnd - now)) / vestingCycle;
    const teamVestingLock = (186041667 * (vestingEnd - now)) / vestingCycle;
    const totalSupply = formatAmount(dipSupplyData, 18, 6);
    // console.log("totalSupply: ", totalSupply);
    const circulatingSupply = totalSupply - daoVestingLock - lpVestingLock - traderVestingLock - teamVestingLock;
    return circulatingSupply;
  };
  const dgtCirculatValue = () => {
    let now = Date.now() / 1000;
    const vestingEnd = 1825164000;
    const vestingCycle = vestingEnd - 1698933600;
    now = now > vestingEnd ? vestingEnd : now;
    const dgtStakingLock = (750000 * (vestingEnd - now)) / vestingCycle;
    const totalSupply = formatAmount(dgtTotalSupply, 18, 6);
    // console.log("totalSupply: ", totalSupply);
    const circulatingSupply = totalSupply - dgtStakingLock;
    return circulatingSupply;
  };
  const marketCap = formatMoney(dipcirculatValue() * dipPairPrice?.dipPrice, 2);
  const dgtMarketCap = formatMoney(dgtCirculatValue() * dgtPairPrice?.dgtPrice, 2);

  const totalVolume = useTotalVolume();

  const chainName = getChainName(chainId);
  const currentIcons = getIcons(chainId);

  const { data: positionStats } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/position_stats")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  let { total: totalGmxSupply } = useTotalGmxSupply();

  const currentVolumeInfo = useVolumeInfo();

  const positionStatsInfo = getPositionStats(positionStats);

  function getWhitelistedTokenAddresses (chainId) {
    const whitelistedTokens = getWhitelistedTokens(chainId);
    return whitelistedTokens.map((token) => token.address);
  }

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  // const readerAddress = getContract(chainId, "Reader");
  // const vaultAddress = getContract(chainId, "Vault");
  // const glpManagerAddress = getContract(chainId, "GlpManager");

  // const gmxAddress = getContract(chainId, "GMX");
  // const glpAddress = getContract(chainId, "GLP");
  // const usdgAddress = getContract(chainId, "USDG");

  // const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  // const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(signer, GlpManager),
  // });

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(signer, ReaderV2, [tokensForSupplyQuery]),
  //   }
  // );

  // const { data: totalTokenWeights } = useSWR(
  //   [`GlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
  //   {
  //     fetcher: contractFetcher(signer, VaultV2),
  //   }
  // );

  // const { infoTokens } = useInfoTokens(signer, chainId, active, undefined, undefined);
  // const { infoTokens: infoTokensArbitrum } = useInfoTokens(null, ARBITRUM, active, undefined, undefined);
  // const { infoTokens: infoTokensAvax } = useInfoTokens(null, AVALANCHE, active, undefined, undefined);

  // const { data: currentFees } = useSWR(
  //   infoTokensArbitrum[AddressZero].contractMinPrice && infoTokensAvax[AddressZero].contractMinPrice
  //     ? "Dashboard:currentFees"
  //     : null,
  //   {
  //     fetcher: () => {
  //       return Promise.all(
  //         ACTIVE_CHAIN_IDS.map((chainId) =>
  //           contractFetcher(null, ReaderV2, [getWhitelistedTokenAddresses(chainId)])(
  //             `Dashboard:fees:${chainId}`,
  //             chainId,
  //             getContract(chainId, "Reader"),
  //             "getFees",
  //             getContract(chainId, "Vault")
  //           )
  //         )
  //       ).then((fees) => {
  //         return fees.reduce(
  //           (acc, cv, i) => {
  //             const feeUSD = getCurrentFeesUsd(
  //               getWhitelistedTokenAddresses(ACTIVE_CHAIN_IDS[i]),
  //               cv,
  //               ACTIVE_CHAIN_IDS[i] === ARBITRUM ? infoTokensArbitrum : infoTokensAvax
  //             );
  //             acc[ACTIVE_CHAIN_IDS[i]] = feeUSD;
  //             acc.total = acc.total.add(feeUSD);
  //             return acc;
  //           },
  //           { total: bigNumberify(0) }
  //         );
  //       });
  //     },
  //   }
  // );

  const { data: feesSummaryByChain } = useFeesSummary();
  const feesSummary = feesSummaryByChain[chainId];

  // const eth = infoTokens[getTokenBySymbol(chainId, "ETH").address];
  const shouldIncludeCurrrentFees =
    feesSummaryByChain[chainId]?.lastUpdatedAt &&
    parseInt(Date.now() / 1000) - feesSummaryByChain[chainId]?.lastUpdatedAt > 60 * 60;

  // const totalFees = ACTIVE_CHAIN_IDS.map((chainId) => {
  //   if (shouldIncludeCurrrentFees && currentFees && currentFees[chainId]) {
  //     return currentFees[chainId].div(expandDecimals(1, USD_DECIMALS)).add(feesSummaryByChain[chainId]?.totalFees || 0);
  //   }

  //   return feesSummaryByChain[chainId].totalFees || 0;
  // })
  //   .map((v) => Math.round(v))
  //   .reduce(
  //     (acc, cv, i) => {
  //       acc[ACTIVE_CHAIN_IDS[i]] = cv;
  //       acc.total = acc.total + cv;
  //       return acc;
  //     },
  //     { total: 0 }
  //   );

  // const { gmxPrice, gmxPriceFromArbitrum, gmxPriceFromAvalanche } = useGmxPrice(
  //   chainId,
  //   { arbitrum: chainId === ARBITRUM ? signer : undefined },
  //   active
  // );

  let { total: totalGmxInLiquidity } = useTotalGmxInLiquidity(chainId, active);

  let { avax: avaxStakedGmx, arbitrum: arbitrumStakedGmx, total: totalStakedGmx } = useTotalGmxStaked();

  let gmxMarketCap;
  // if (gmxPrice && totalGmxSupply) {
  //   gmxMarketCap = gmxPrice.mul(totalGmxSupply).div(expandDecimals(1, GMX_DECIMALS));
  // }

  let stakedGmxSupplyUsd;
  // if (gmxPrice && totalStakedGmx) {
  //   stakedGmxSupplyUsd = totalStakedGmx.mul(gmxPrice).div(expandDecimals(1, GMX_DECIMALS));
  // }

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  // if (aum && totalSupplies && totalSupplies[3]) {
  //   glpSupply = totalSupplies[3];
  //   glpPrice =
  //     aum && aum.gt(0) && glpSupply.gt(0)
  //       ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
  //       : expandDecimals(1, USD_DECIMALS);
  //   glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  // }

  let tvl;
  // if (glpMarketCap && gmxPrice && totalStakedGmx) {
  //   tvl = glpMarketCap.add(gmxPrice.mul(totalStakedGmx).div(expandDecimals(1, GMX_DECIMALS)));
  // }

  const ethFloorPriceFund = expandDecimals(350 + 148 + 384, 18);
  const glpFloorPriceFund = expandDecimals(660001, 18);
  const usdcFloorPriceFund = expandDecimals(784598 + 200000, 30);

  let totalFloorPriceFundUsd;

  // if (eth && eth.contractMinPrice && glpPrice) {
  //   const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals));
  //   const glpFloorPriceFundUsd = glpFloorPriceFund.mul(glpPrice).div(expandDecimals(1, 18));

  //   totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(glpFloorPriceFundUsd).add(usdcFloorPriceFund);
  // }

  let adjustedUsdgSupply = bigNumberify(0);

  // for (let i = 0; i < tokenList.length; i++) {
  //   const token = tokenList[i];
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo && tokenInfo.usdgAmount) {
  //     adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
  //   }
  // }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdgAmount ||
      !adjustedUsdgSupply ||
      adjustedUsdgSupply.eq(0)
      // ||!totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
    // use add(1).div(10).mul(10) to round numbers up
    // const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);
    const targetWeightBps = 0;
    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={t`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is below its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/buy_glp" target="_blank" rel="noopener noreferrer">
                      buy GLP
                    </Link>{" "}
                    with {tokenInfo.symbol}, and to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    {tokenInfo.symbol} for other tokens.
                  </Trans>
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is above its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    tokens for {tokenInfo.symbol}.
                  </Trans>
                </div>
              )}
              <br />
              <div>
                <ExternalLink href="https://dip.gitbook.io/dip">
                  <Trans>More Info</Trans>
                </ExternalLink>
              </div>
            </>
          );
        }}
      />
    );
  };

  let stakedPercent = 0;

  if (totalGmxSupply && !totalGmxSupply.isZero() && !totalStakedGmx.isZero()) {
    stakedPercent = totalStakedGmx.mul(100).div(totalGmxSupply).toNumber();
  }

  let liquidityPercent = 0;

  if (totalGmxSupply && !totalGmxSupply.isZero() && totalGmxInLiquidity) {
    liquidityPercent = totalGmxInLiquidity.mul(100).div(totalGmxSupply).toNumber();
  }

  let notStakedPercent = 100 - stakedPercent - liquidityPercent;

  let gmxDistributionData = [
    {
      name: t`staked`,
      value: stakedPercent,
      color: "#4353fa",
    },
    {
      name: t`in liquidity`,
      value: liquidityPercent,
      color: "#0598fa",
    },
    {
      name: t`not staked`,
      value: notStakedPercent,
      color: "#5c0af5",
    },
  ];

  const totalStatsStartDate = chainId === AVALANCHE ? t`06 Jan 2022` : t`01 Sep 2021`;

  let stableGlp = 0;
  let totalGlp = 0;

  // let glpPool = tokenList.map((token) => {
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
  //     const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
  //     if (tokenInfo.isStable) {
  //       stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
  //     }
  //     totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
  //     return {
  //       fullname: token.name,
  //       name: token.symbol,
  //       value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
  //     };
  //   }
  //   return null;
  // });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  // glpPool = glpPool.filter(function (element) {
  //   return element !== null;
  // });

  // glpPool = glpPool.sort(function (a, b) {
  //   if (a.value < b.value) return 1;
  //   else return -1;
  // });

  gmxDistributionData = gmxDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [gmxActiveIndex, setGMXActiveIndex] = useState(null);

  const onGMXDistributionChartEnter = (_, index) => {
    setGMXActiveIndex(index);
  };

  const onGMXDistributionChartLeave = (_, index) => {
    setGMXActiveIndex(null);
  };

  const [glpActiveIndex, setGLPActiveIndex] = useState(null);

  const onGLPPoolChartEnter = (_, index) => {
    setGLPActiveIndex(index);
  };

  const onGLPPoolChartLeave = (_, index) => {
    setGLPActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  const DIPInfo = {
    title: "DIP Info",
    rightTitle: `Price: $${dipPairPrice?.dipPrice}`,
    icons: [DIPIcon],
    cells: [
      // { left: "Price ", right: "$--" },
      { left: "Total supply", right: `${dipTotalSupply}` },
      { left: "Full Diluted Valuation", right: `$${dipFullAmount}` },
      { left: "Circulating supply", right: formatMoney(dipcirculatValue(), 4) },
      { left: "Market Cap", right: `$${marketCap}` },
    ],
  };

  const DGTInfo = {
    title: "DGT Info",
    rightTitle: `Price: $${dgtPairPrice?.dgtPrice}`,
    icons: [DGTIcon],
    cells: [
      { left: "Total supply", right: `${formatAmount(dgtTotalSupply, defaultTokenDecimals, 4, true)} DGT` },
      { left: "Full Diluted Valuation", right: `$${dgtFullAmount}` },
      { left: "Circulating supply", right: formatMoney(dgtCirculatValue(), 4) },
      // { left: "Redemption Value", right: "$--" },
      { left: "Last Auction Price", right: "31.9889 DIP" },
    ],
  };

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <div className="default-bg">
        <div className="default-container DashboardV2 page-layout">
          <div className="section-title-block">
            <div className="section-title-icon"></div>
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Stats</Trans> <img width="24" src={getIcons(chainId).network} alt="Network Icon" />
              </div>
              {/* <div className="Page-description">
              <Trans>
                {chainName} Total Stats start from {totalStatsStartDate}.<br /> For detailed stats:
              </Trans>{" "}
              {chainId === ARBITRUM && <ExternalLink href="https://stats.gmx.io">https://stats.gmx.io</ExternalLink>}
              {chainId === AVALANCHE && (
                <ExternalLink href="https://stats.gmx.io/avalanche">https://stats.gmx.io/avalanche</ExternalLink>
              )}
              .
            </div> */}
            </div>
          </div>

          <div className="">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className=" DashboardV2-cards">
                  <div className="flex aligns-center">
                    <img
                      src={require("img/ic_dashboard_aum.png")}
                      alt="Total Trading Volume Icon"
                      className="icon-xs"
                    />
                    <div className="ml-10">
                      <div className="DefaultCard-cell-subTitle">Assets Under Management</div>
                    </div>
                  </div>
                  <div className="DefaultCard-cell-value  ml-30 mt-10">${formatMoney(assetsManager, 2)}</div>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="DashboardV2-cards">
                  <div className="flex aligns-center ">
                    <img
                      src={require("img/ic_dashboard_fee.png")}
                      alt="Total Trading Volume Icon"
                      className="icon-xs"
                    />
                    <div className="ml-10">
                      <div className="DefaultCard-cell-subTitle">Total Fees</div>
                    </div>
                  </div>
                  <div className="DefaultCard-cell-value ml-30 mt-10">
                    {aum ? `$${formatMoney(aum.totalFee, 2)}` : "---"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="DashboardV2-cards">
                  <div className="flex aligns-center ">
                    <img
                      src={require("img/ic_dashboard_volume.png")}
                      alt="Total Trading Volume Icon"
                      className="icon-xs"
                    />
                    <div className="ml-10">
                      <div className="DefaultCard-cell-subTitle">Total Trading Volume</div>
                    </div>
                  </div>
                  <div className="DefaultCard-cell-value mt-10 ml-30">
                    {aum ? `$${formatMoney(aum.totalVolume, 2)}` : "---"}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 col-md-4">
                <div className="DashboardV2-cards">
                  <div className="flex aligns-center ">
                    <img
                      src={require("img/ic_dashboard_long.png")}
                      alt="Total Trading Volume Icon"
                      className="icon-xs"
                    />
                    <div className="ml-10">
                      <div className="DefaultCard-cell-subTitle">Long Positions</div>
                    </div>
                  </div>
                  <div className="DefaultCard-cell-value ml-30 mt-10">
                    {aum ? `$${formatMoney(aum.long, 2)}` : "---"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="DashboardV2-cards">
                  <div className="flex aligns-center ">
                    <img
                      src={require("img/ic_dashboard_short.png")}
                      alt="Total Trading Volume Icon"
                      className="icon-xs"
                    />
                    <div className="ml-10">
                      <div className="DefaultCard-cell-subTitle">Short Positions</div>
                    </div>
                  </div>
                  <div className="DefaultCard-cell-value ml-30 mt-10">
                    {aum ? `$${formatMoney(aum.short, 2)}` : "---"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="DashboardV2-cards">
                  <div className="flex aligns-center ">
                    <img src={require("img/ic_24h.png")} alt="Total Trading Volume Icon" className="icon-xs" />
                    <div className="ml-10">
                      <div className="DefaultCard-cell-subTitle">24h Trading Volume</div>
                    </div>
                  </div>
                  <div className="DefaultCard-cell-value ml-30 mt-10">
                    {aum ? `$${formatMoney(aum.trading24H?.totalValue, 2)}` : "---"}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="mt-20">
            <Quote title="Token Pairs" trading24H={aum?.trading24H?.tokens} />
          </div>
          <div className="DashboardV2-content">
            {/* <div className="DashboardV2-cards">
            <div className="App-card">
              <div className="App-card-title">
                <Trans>Overview</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>AUM</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => (
                        <span>{t`Assets Under Management: GMX staked (All chains) + GLP pool (${chainName}).`}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>GLP Pool</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => (
                        <Trans>
                          <p>Total value of tokens in GLP pool ({chainName}).</p>
                          <p>
                            Other websites may show a higher value as they add positions' collaterals to the GLP pool.
                          </p>
                        </Trans>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>24h Volume</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(currentVolumeInfo?.[chainId], USD_DECIMALS, 0, true)}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Volume`}
                          arbitrumValue={currentVolumeInfo?.[ARBITRUM]}
                          avaxValue={currentVolumeInfo?.[AVALANCHE]}
                          total={currentVolumeInfo?.total}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Long Positions</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(
                        positionStatsInfo?.[chainId]?.totalLongPositionSizes,
                        USD_DECIMALS,
                        0,
                        true
                      )}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Long Positions`}
                          arbitrumValue={positionStatsInfo?.[ARBITRUM].totalLongPositionSizes}
                          avaxValue={positionStatsInfo?.[AVALANCHE].totalLongPositionSizes}
                          total={positionStatsInfo?.totalLongPositionSizes}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Short Positions</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(
                        positionStatsInfo?.[chainId]?.totalShortPositionSizes,
                        USD_DECIMALS,
                        0,
                        true
                      )}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Short Positions`}
                          arbitrumValue={positionStatsInfo?.[ARBITRUM].totalShortPositionSizes}
                          avaxValue={positionStatsInfo?.[AVALANCHE].totalShortPositionSizes}
                          total={positionStatsInfo?.totalShortPositionSizes}
                        />
                      )}
                    />
                  </div>
                </div>
                {feesSummary?.lastUpdatedAt ? (
                  <div className="App-card-row">
                    <div className="label">
                      <Trans>Fees since</Trans> {formatDate(feesSummary.lastUpdatedAt)}
                    </div>
                    <div>
                      <TooltipComponent
                        position="right-bottom"
                        className="nowrap"
                        handle={`$${formatAmount(currentFees?.[chainId], USD_DECIMALS, 2, true)}`}
                        renderContent={() => (
                          <StatsTooltip
                            title={t`Fees`}
                            arbitrumValue={currentFees?.[ARBITRUM]}
                            avaxValue={currentFees?.[AVALANCHE]}
                            total={currentFees?.total}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title">
                <Trans>Total Stats</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Total Fees</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${numberWithCommas(totalFees?.[chainId])}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Total Fees`}
                          arbitrumValue={totalFees?.[ARBITRUM]}
                          avaxValue={totalFees?.[AVALANCHE]}
                          total={totalFees?.total}
                          decimalsForConversion={0}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Total Volume</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(totalVolume?.[chainId], USD_DECIMALS, 0, true)}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Total Volume`}
                          arbitrumValue={totalVolume?.[ARBITRUM]}
                          avaxValue={totalVolume?.[AVALANCHE]}
                          total={totalVolume?.total}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Floor Price Fund</Trans>
                  </div>
                  <div>${formatAmount(totalFloorPriceFundUsd, 30, 0, true)}</div>
                </div>
              </div>
            </div>
          </div> */}
            {/* <div className="Tab-title-section">
            <div className="Page-title">
              <Trans>Tokens</Trans>
              <img src={currentIcons.network} width="24" alt="Network Icon" />
            </div>
            <div className="Page-description">
              <Trans>Platform and GLP index tokens.</Trans>
            </div>
          </div> */}
            <div className="Page-title mt-20">
              <Trans>Tokens</Trans>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <DefaultCard data={DIPInfo}></DefaultCard>
              </div>
              <div className="col-md-6 col-12">
                <DefaultCard data={DGTInfo}></DefaultCard>
              </div>
            </div>
            <div className="DashboardV2-token-cards">
              {/* <div className="stats-wrapper stats-wrapper--gmx">
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={currentIcons.gmx} width="40" alt="GMX Token Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">GMX</div>
                        <div className="App-card-title-mark-subtitle">GMX</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="GMX" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>
                        {!gmxPrice && "..."}
                        {gmxPrice && (
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={"$" + formatAmount(gmxPrice, USD_DECIMALS, 2, true)}
                            renderContent={() => (
                              <>
                                <StatsTooltipRow
                                  label={t`Price on Arbitrum`}
                                  value={formatAmount(gmxPriceFromArbitrum, USD_DECIMALS, 2, true)}
                                  showDollar={true}
                                />
                                <StatsTooltipRow
                                  label={t`Price on Avalanche`}
                                  value={formatAmount(gmxPriceFromAvalanche, USD_DECIMALS, 2, true)}
                                  showDollar={true}
                                />
                              </>
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Supply</Trans>
                      </div>
                      <div>{formatAmount(totalGmxSupply, GMX_DECIMALS, 0, true)} GMX</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>
                        <TooltipComponent
                          position="right-bottom"
                          className="nowrap"
                          handle={`$${formatAmount(stakedGmxSupplyUsd, USD_DECIMALS, 0, true)}`}
                          renderContent={() => (
                            <StatsTooltip
                              title={t`Staked`}
                              arbitrumValue={arbitrumStakedGmx}
                              avaxValue={avaxStakedGmx}
                              total={totalStakedGmx}
                              decimalsForConversion={GMX_DECIMALS}
                              showDollar={false}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div>${formatAmount(gmxMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseLeave={onGMXDistributionChartLeave}>
                  {gmxDistributionData.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={gmxDistributionData}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={2}
                        onMouseEnter={onGMXDistributionChartEnter}
                        onMouseOut={onGMXDistributionChartLeave}
                        onMouseLeave={onGMXDistributionChartLeave}
                      >
                        {gmxDistributionData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            style={{
                              filter:
                                gmxActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={entry.color}
                            strokeWidth={gmxActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        <Trans>Distribution</Trans>
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={currentIcons.glp} width="40" alt="GLP Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">GLP</div>
                        <div className="App-card-title-mark-subtitle">GLP</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="GLP" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>${formatAmount(glpPrice, USD_DECIMALS, 3, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Supply</Trans>
                      </div>
                      <div>{formatAmount(glpSupply, GLP_DECIMALS, 0, true)} GLP</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>${formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div>${formatAmount(glpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Stablecoin Percentage</Trans>
                      </div>
                      <div>{stablePercentage}%</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseOut={onGLPPoolChartLeave}>
                  {glpPool.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={glpPool}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        onMouseEnter={onGLPPoolChartEnter}
                        onMouseOut={onGLPPoolChartLeave}
                        onMouseLeave={onGLPPoolChartLeave}
                        paddingAngle={2}
                      >
                        {glpPool.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={GLP_POOL_COLORS[entry.name]}
                            style={{
                              filter:
                                glpActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(GLP_POOL_COLORS[entry.name], 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={GLP_POOL_COLORS[entry.name]}
                            strokeWidth={glpActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        GLP Pool
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
            </div> */}
              <div className="mt-20">
                <Liquiditys />
              </div>
              <div className="mt-20">
                <Stakings />
              </div>
              {/* <div className="token-table-wrapper App-card">
              <div className="App-card-title">
                <Trans>GLP Index Composition</Trans> <img src={currentIcons.network} width="16" alt="Network Icon" />
              </div>
              <div className="App-card-divider"></div>
              <table className="token-table">
                <thead>
                  <tr>
                    <th>
                      <Trans>TOKEN</Trans>
                    </th>
                    <th>
                      <Trans>PRICE</Trans>
                    </th>
                    <th>
                      <Trans>POOL</Trans>
                    </th>
                    <th>
                      <Trans>WEIGHT</Trans>
                    </th>
                    <th>
                      <Trans>UTILIZATION</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {visibleTokens.map((token) => {
                    const tokenInfo = infoTokens[token.address];
                    let utilization = bigNumberify(0);
                    if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                      utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    }
                    let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
                    if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                      maxUsdgAmount = tokenInfo.maxUsdgAmount;
                    }
                    const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage} alt={token.symbol} width="40" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.name}</div>
                                <div className="App-card-info-subtitle">{token.symbol}</div>
                              </div>
                              <div>
                                <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</td>
                        <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            className="nowrap"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                    showDollar={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </td>
                        <td>{getWeightText(tokenInfo)}</td>
                        <td>{formatAmount(utilization, 2, 2, false)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}

              {/* <div className="token-grid">
              {visibleTokens.map((token) => {
                const tokenInfo = infoTokens[token.address];
                let utilization = bigNumberify(0);
                if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                  utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                }
                let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
                if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                  maxUsdgAmount = tokenInfo.maxUsdgAmount;
                }

                const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
                return (
                  <div className="App-card" key={token.symbol}>
                    <div className="App-card-title">
                      <div className="mobile-token-card">
                        <img src={tokenImage} alt={token.symbol} width="20px" />
                        <div className="token-symbol-text">{token.symbol}</div>
                        <div>
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Price</Trans>
                        </div>
                        <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Pool</Trans>
                        </div>
                        <div>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Weight</Trans>
                        </div>
                        <div>{getWeightText(tokenInfo)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Utilization</Trans>
                        </div>
                        <div>{formatAmount(utilization, 2, 2, false)}%</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div> */}
              <div className="mt-20">
                <div className="Page-title"> Token Distribution</div>
                <div className="mt-20">
                  <TokenDistribution />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </SEO>
  );
}
