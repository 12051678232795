import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";

import { HeaderLink } from "./HeaderLink";
import { getHomeUrl } from "lib/legacy";
import { useHistory } from "react-router-dom";

import "./Header.css";
import { isHomeSite } from "lib/legacy";
import logoImg from "img/logo-withname-white.png";
import { HeaderDropdownList } from "./HeaderDropdownList";
import { isFantomTestnet } from "config/env";
import { useChainId } from "lib/chains";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  let subMenuTimer;
  const history = useHistory();
  const { chainId } = useChainId();
  const [isEarnHovered, setIsEarnHovered] = useState(false);
  const hoverSubMenu = (isShow: boolean) => {
    if (isShow) {
      clearTimeout(subMenuTimer);
      setIsEarnHovered(true);
    } else {
      subMenuTimer = setTimeout(() => {
        setIsEarnHovered(false);
      }, 200);
    }
  };
  const gotoEarn = (to) => {
    history.push(to);
    setIsEarnHovered(false);
  };
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <a className="App-header-link-main" href={getHomeUrl()}>
            <img src={logoImg} alt="DIP Logo" />
          </a>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      <div className="App-header-link-container">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Dashboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink to="/assets" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Portfolio</Trans>
        </HeaderLink>
      </div>
      {/* <div className="App-header-link-container">
        <HeaderLink to="/markets" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Markets</Trans>
        </HeaderLink>
      </div> */}
      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Markets"}
        subList={
          isFantomTestnet(chainId)
            ? [
              {
                title: "Leveraged Trading",
                subTitle: "Up to 50x Leverage",
                icon: require("img/ic_leveraged.png"),
                path: "/markets",
              },
              {
                title: "Spot",
                subTitle: "Buy and Sell with advance tools",
                icon: require("img/ic_swap_trade.png"),
                path: "/spot",
              },
            ]
            : [
              {
                title: "Leveraged Trading",
                subTitle: "Up to 50x Leverage",
                icon: require("img/ic_leveraged.png"),
                path: "/markets",
              },
              {
                title: "Spot",
                subTitle: "Buy and Sell with advance tools",
                icon: require("img/ic_swap_trade.png"),
                path: "/spot",
              },
              {
                title: "Buy DIP/DGT",
                subTitle: "How to buy DIP/DGT",
                icon: require("img/ic_buy.png"),
                path: "/buy",
              },
            ]
        }
      />
      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Earn"}
        subList={[
          {
            title: "Earn",
            subTitle: "Provide liquidity to earn Real Yield",
            icon: require("img/ic_earn.png"),
            path: "/earn",
          },
          {
            title: "Staking",
            subTitle: "Stake DIP or DGT to earn protocol revenue",
            icon: require("img/ic_stake.png"),
            path: "/staking",
          },
          // {
          //   title: "Liquidity Bootstrapping",
          //   subTitle: "Earn high early returns",
          //   icon: require("img/ic_lbp.png"),
          //   path: "/liquiditybootstrapping",
          // },
        ]}
      />

      <div className="App-header-link-container">
        <HeaderLink to="/dao" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>DAO</Trans>
        </HeaderLink>
      </div>
      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Rewards"}
        subList={[
          {
            title: "Trade Mining Program",
            subTitle: "Trade and earn 100,000 DIP per day",
            icon: require("img/ic_tradingmining.png"),
            path: "/tradingmining",
          },
          {
            title: "Referral Program",
            subTitle: "Earn up to 70% of fees",
            icon: require("img/ic_referral.png"),
            path: "/referrals",
          },
          // {
          //   title: "AirDrop",
          //   subTitle: "Airdrop USDC/DIP rewards for users",
          //   icon: require("img/ic_airdrop.png"),
          //   path: "/trading/contest/rewards",
          // },
        ]}
      />
      {/* <div className="App-header-link-container">
        <HeaderLink to="/auction" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Auction</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <ExternalLink href="https://dip-exchange.gitbook.io/dip">
          <Trans>Docs</Trans>
        </ExternalLink> 
      </div> */}
      {/* <div className="App-header-link-container">
        <ExternalLink href="https://swap.dip.exchange">
          <Trans>Swap</Trans>
        </ExternalLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink to="/faucet" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Faucet</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <ExternalLink href="https://leaderboard.dip.exchange">
          <Trans>Leaderboard</Trans>
        </ExternalLink>
      </div> */}

      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Ecosystem"}
        subList={
          isFantomTestnet(chainId)
            ? [
              {
                title: "Faucet",
                subTitle: "Claim $100 USDT",
                icon: require("img/ic_faucet.png"),
                path: "/faucet",
              },
              {
                title: "Leaderboard",
                subTitle: "DIP Leaderboard",
                icon: require("img/ic_leaderboard.png"),
                url: "https://leaderboard.dip.exchange",
              },
              {
                title: "Bridge",
                subTitle: "Bridge assets from or to BASE",
                icon: require("img/ic_bridge.png"),
                path: "/bridge",
              },
              {
                title: "Docs",
                subTitle: "Learn about DIP Exchange",
                icon: require("img/ic_doc.png"),
                url: "https://docs.dip.exchange/",
              },
              // {
              //   title: "Forum",
              //   subTitle: "Learn about DIP Exchange",
              //   icon: require("img/ic_forum.png"),
              //   url: "https://forum.testnet.dip.exchange/",
              // },
            ]
            : [
              {
                title: "Auction",
                subTitle: "Bid for DIP and DGT",
                icon: require("img/ic_auction.png"),
                url: "https://app.bounce.finance/launchpad/dip_exchange",
              },
              {
                title: "Leaderboard",
                subTitle: "DIP Leaderboard",
                icon: require("img/ic_leaderboard.png"),
                url: "https://leaderboard.dip.exchange",
              },
              {
                title: "Bridge",
                subTitle: "Bridge assets from or to BASE",
                icon: require("img/ic_bridge.png"),
                path: "/bridge",
              },
              {
                title: "Docs",
                subTitle: "Learn about DIP Exchange",
                icon: require("img/ic_doc.png"),
                url: "https://docs.dip.exchange/",
              },
              {
                title: "Forum",
                subTitle: "Share your ideas",
                icon: require("img/ic_forum.png"),
                url: "https://forum.dip.exchange/",
              },
            ]
        }
      />
      {small && !isHomeSite() && (
        <div className="App-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            <Trans>Settings</Trans>
          </a>
        </div>
      )}
    </div>
  );
}
