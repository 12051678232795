import React from "react";

import "./Quote.css";

import useSWR from "swr";
import cx from "classnames";
import { formatMoney, formatPercent } from "lib/numbers";
import { useChainId } from "lib/chains";
import { getQuoteLink } from "config/links";

export default function QuoteBanner() {
  const { chainId } = useChainId();
  const quoteUrl = getQuoteLink(chainId);
  const { data: quotes } = useSWR([quoteUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const cellGroupClass = cx("flex", "justify-between", "hidden-x", "aligns-center");
  const getDecimals = (symbol) => {
    switch (symbol) {
      case "FTMUSDT":
        return 4;
      default:
        return 2;
    }
  };
  return (
    <div className="flex aligns-center Quote-banner scroll-x">
      {quotes?.map((item, index) => (
        <div key={index} className="Quote-banner-cell">
          <div className={cellGroupClass}>
            <div>{item.symbol.split("USDT")[0] + "/USD"}</div>
            <div
              className={cx("ml-10", {
                "up-color": item.lastPrice > item.openPrice,
                "down-color": item.lastPrice < item.openPrice,
              })}
            >
              ${formatMoney(item.lastPrice, getDecimals(item.symbol))}
            </div>
            <div
              className={cx("ml-10", {
                "up-color": item.lastPrice > item.openPrice,
                "down-color": item.lastPrice < item.openPrice,
              })}
            >
              {formatPercent(item.lastPrice, item.openPrice)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
