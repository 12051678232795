import { useEffect, useState } from "react";
import { defaultApiUrl, getApiPathName } from "lib/api";
import cx from "classnames";
import Button from "components/Button/Button";
import { helperToast } from "lib/helperToast";
import Footer from "components/Footer/Footer";

export default function DIPAirdrop ({ account, chainId }) {
  const [rewardInfo, setRewardInfo] = useState();
  const isNotCanApply = () => {
    return !rewardInfo || rewardInfo.totalPoint === "" || rewardInfo.totalPoint <= 0;
  };
  const [claim, setClaim] = useState();
  useEffect(() => {
    if (!account) return;
    try {
      fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/contest/testnet/reward/dip/${account}`).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            if (data.errno === 0) {
              setRewardInfo(data.data);
            } else {
              setRewardInfo(undefined);
            }
          });
        }
      });
    } catch (e) { }
  }, [chainId, account, claim]);
  const applyClaim = () => {
    if (!account || rewardInfo?.status !== 0) return;
    try {
      fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/contest/testnet/applyReward/dip/${account}`)
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              if (data.errno === 0) {
                helperToast.success("Apply success! The airdrop will arrive within 24 hours.");
                setClaim(true);
              } else {
                helperToast.error("Apply failed! Please try again later.");
              }
            });
          }
        })
        .catch((error) => {
          helperToast.error("Apply failed! Please try again later.");
        });
    } catch (e) {
      helperToast.error("Apply failed! Please try again later.");
    }
  };
  return (
    <div className="pb-30  ">
      <div className="ml-10">
        <div className=" mt-20">
          <div className="fs-2">Beta Test $DIP Reward</div>
          <div className="Assets-total-card mt-10">
            <div className="Earn-detail-left-subtitle mt-10">Total Reward</div>
            <div className="mt-10">
              <span className="fs-3 fw-bold"> {rewardInfo?.reward} DIP</span>
              <span className="Earn-detail-left-subtitle ml-10">({rewardInfo?.totalPoint} Points)</span>
            </div>
          </div>
        </div>
        <div className="Earn-detail-left-subtitle mt-20">Status</div>

        <div className="mt-20 ml-20 Liquidity-card-subtitle">
          <div className="flex  aligns-center">
            {/* <div className="flex aligns-center">
              <div className="Position-spinner"></div>
            </div> */}
            <img
              src={
                require(rewardInfo && rewardInfo.lbpPoint !== "" && rewardInfo.lbpPoint > 0
                  ? "img/ic_pass.png"
                  : "img/ic_not_pass.png")
              }
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">Participate in LBP more than $10,000</div>
          </div>
          <div className="flex  aligns-center mt-10">
            {/* <div className="flex aligns-center">
              <div className="Position-spinner"></div>
             </div> */}
            <img
              src={
                require(rewardInfo && rewardInfo.tradePoint !== "" && rewardInfo.tradePoint > 0
                  ? "img/ic_pass.png"
                  : "img/ic_not_pass.png")
              }
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">Trade more than $10,000 on DIP Exchange (incl. leverage)</div>
          </div>
          <div className="flex  aligns-center mt-10">
            {/* <div className="flex aligns-center">
              <div className="Position-spinner"></div>
             </div> */}
            <img
              src={
                require(rewardInfo && rewardInfo.baseSwapPoint !== "" && rewardInfo.baseSwapPoint > 0
                  ? "img/ic_pass.png"
                  : "img/ic_not_pass.png")
              }
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">Trade more than $1,000 worth of $DIP on Baseswap</div>
          </div>
          <div className="flex  aligns-center mt-10">
            {/* <div className="flex aligns-center">
              <div className="Position-spinner"></div>
             </div> */}
            <img
              src={
                require(rewardInfo && rewardInfo.bridgePoint !== "" && rewardInfo.bridgePoint > 0
                  ? "img/ic_pass.png"
                  : "img/ic_not_pass.png")
              }
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">Have bridged to Base using Base Bridge</div>
          </div>
          <div className="flex  aligns-center mt-10">
            {/* <div className="flex aligns-center">
              <div className="Position-spinner"></div>
             </div> */}
            <img
              src={
                require(rewardInfo && rewardInfo.starGatePoint !== "" && rewardInfo.starGatePoint > 0
                  ? "img/ic_pass.png"
                  : "img/ic_not_pass.png")
              }
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">Have bridged to Base using Stargate Bridge</div>
          </div>
        </div>
        {/* <div className="Earn-detail-left-subtitle mt-20">Point Details</div>

        <div className="row">
          <div className="col-md-4 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Under LBP </div>
              <div className="mt-10">{rewardInfo?.lbpPoint}</div>
            </div>
          </div>
          <div className="col-md-4 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Under Trade</div>
              <div className="mt-10">{rewardInfo?.tradePoint}</div>
            </div>
          </div>
          <div className="col-md-4 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Under BaseSwap</div>
              <div className="mt-10">{rewardInfo?.baseSwapPoint}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Under BaseBridge </div>
              <div className="mt-10">{rewardInfo?.bridgePoint}</div>
            </div>
          </div>
          <div className="col-md-6 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Under Stargate Bridge </div>
              <div className="mt-10">{rewardInfo?.starGatePoint}</div>
            </div>
          </div>
        </div> */}
        <div className="Exchange-swap-button-container mt-20">
          <Button
            variant="primary-action"
            className="w-100"
            onClick={applyClaim}
            disabled={isNotCanApply() || rewardInfo?.status !== 0}
          >
            {isNotCanApply()
              ? "Cannot apply for a claim"
              : rewardInfo?.status === 0
                ? "Apply Claim"
                : "Claim Already Applied"}
          </Button>
        </div>
        <div className="Earn-detail-left-subtitle mt-20">Rules</div>

        <div className="Activity-content-group  ">
          <div className="Activity-content-title">Beta Test $DIP Reward</div>
          <div className="Activity-content-sub-title mt-10">
            Event Period:August 24, 2023, 2:00 PM UTC - September 26, 2023, 2:00 PM UTC.
          </div>
          <div className="mt-20">
            To the Beta Test Participants who missed out on the above prize pool, don’t worry. We have an even better
            reward for you! Whether you won or did not win the above contest, we will be rewarding all Beta Test
            Participants based on the points you earn.
          </div>
          <p />
          Whether you won or did not win the above contest, we will be rewarding all Beta Test Participants based on the
          points you earn.
          <div className=" up-color  mt-20 fw-bold">Point System (1 point = 50 $DIP)</div>
          <div className="mt-10">
            ▶ <span className="fw-bold">30 points:</span> if you participate in LBP more than $10,000
          </div>
          <div className="mt-10">
            ▶ <span className="fw-bold">30 points:</span> if you trade more than $10,000 on DIP Exchange (incl.
            leverage)
          </div>
          <div className="mt-10">
            ▶ <span className="fw-bold">30 points:</span> if you trade more than $1,000 worth of $DIP on Baseswap
          </div>
          <div className="mt-10">
            ▶ <span className="fw-bold">5 points:</span> if you have bridged to Base using Base Bridge
          </div>
          <div className="mt-10">
            ▶ <span className="fw-bold">5 points:</span> if you have bridged to Base using Stargate Bridge
          </div>
          <p />
          You will be able to claim $DIP rewards on September 26, 2023, 2:00 PM UTC. Rewards will be sent out to you
          within 24 hours of claiming them.
          <p />
          Note: This event is only available to Beta Test Participants. New traders will have more opportunities to
          participate in future events.
          <p />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
