import React, { useState, useEffect, useMemo } from "react";
import { getLPToken, getPoolAssets, getTokenBySymbols } from "config/tokens";
import { useChainId } from "lib/chains";
import cx from "classnames";
import WalletAssets from "./WalletAssets";
import { helperToast } from "lib/helperToast";
import useWallet from "lib/wallets/useWallet";
import { ethers } from "ethers";
import { formatAmount } from "lib/numbers";
import TokenABI from "abis/Token.json";
import useSWR from "swr";
import { getQuoteLink } from "config/links";
import AssetsOverview from "./AssetsOverview";
import "./Assets.css";
import { useDgtPairPrice, useDipPairPrice } from "domain/prices";
import StakingAssets from "./StakingAssets";

import { useDLPPrices, useEarnPoolUserInfo, useLbpUserInfo, useStakingUserInfo } from "domain/pool";
import Footer from "components/Footer/Footer";

function Assets ({ setPendingTxns }) {
  const { chainId } = useChainId();
  const [lpTokens, setLpTokens] = useState();
  const [assetToken, setAssetToken] = useState();
  const { signer, account, active } = useWallet();
  // console.log("signer::::", signer)
  const [leftIndex, setLeftIndex] = useState(0);
  const [balanceMap, setBalanceMap] = useState({});
  const dipPairPrice = useDipPairPrice(chainId, signer);
  const dgtPairPrice = useDgtPairPrice(chainId, signer)
  const { data: tokenPrice } = useSWR([getQuoteLink(chainId)], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const [walletTotalValue, setWalletTotalValue] = useState(0);
  useEffect(() => {
    let assetTotalValue = 0;
    if (tokenPrice && assetToken) {
      for (let token of assetToken) {
        let price;
        if (token.isStable) {
          price = { lastPrice: 1 };
        } else if (token.symbol == "DIP") {
          price = { lastPrice: dipPairPrice.dipPrice };
        } else if (token.symbol == "DGT") {
          price = { lastPrice: dgtPairPrice.dgtPrice };
        } else {
          price = tokenPrice?.find((price) => price.symbol.includes(token.baseSymbol));
        }

        if (!price) {
          price = { lastPrice: 0 };
        }
        console.log("balanceMap", balanceMap)
        assetTotalValue += balanceMap[token.symbol] ? price.lastPrice * balanceMap[token.symbol] : 0;
      }
    }
    let lpTotalValue = 0;
    if (dipPairPrice.lpPrice && lpTokens) {
      for (let token of lpTokens) {
        if (token.symbol === "DIP/ETH LP") {
          lpTotalValue += balanceMap[token.symbol] ? dipPairPrice.lpPrice * balanceMap[token.symbol] : 0;
        } else if (token.symbol === "ETH/USDC LP") {
          lpTotalValue +=
            balanceMap[token.symbol] && tokenPrice
              ? tokenPrice?.find((price) => price.symbol.includes("ETH")).lastPrice * balanceMap[token.symbol]
              : 0;
        }
      }
    }
    setWalletTotalValue(lpTotalValue + assetTotalValue);
  }, [tokenPrice, dipPairPrice, assetToken, lpTokens, balanceMap]);
  const leftMenuCell = [
    {
      icon: require("img/ic_overview.png"),
      activeIcon: require("img/ic_overview-active.png"),
      content: "Overview",
    },
    {
      icon: require("img/ic_wallet_24.svg").default,
      activeIcon: require("img/ic_wallet_24.svg").default,
      content: "Wallets",
    },
    {
      icon: require("img/ic_staking.png"),
      activeIcon: require("img/ic_staking-active.png"),
      content: "Earn",
    },
    {
      // icon: require("img/ic_staking.png"),
      // activeIcon: require("img/ic_staking-active.png"),
      content: "In Orders",
    },
    {
      // icon: require("img/ic_staking.png"),
      // activeIcon: require("img/ic_staking-active.png"),
      content: "In Positions",
    },
  ];
  const menuClick = (index) => {
    if (index > 2) {
      helperToast.error("Coming Soon!");
      return;
    }
    setLeftIndex(index);
  };
  useEffect(() => {
    // setBalanceMap({});
    const lp = getLPToken(chainId);
    setLpTokens(lp);
    let asset = getPoolAssets(chainId);
    asset = asset.concat(getTokenBySymbols(chainId, ["WETH", "DIP", "DGT"]));
    setAssetToken(asset);
    // console.log("signer-", signer)

    initTokenBalance(asset, signer);
    initTokenBalance(lp, signer);

  }, [account, chainId, signer]);
  const lbpUserInfo = useLbpUserInfo(chainId, active, signer, account);
  const earnUserInfo = useEarnPoolUserInfo(chainId, active, signer, account);
  const stakingUserInfo = useStakingUserInfo(chainId, active, signer, account);
  const dlpPrices = useDLPPrices(chainId, signer);
  const lbpData = [
    { amount: lbpUserInfo?.snr?.amount, value: lbpUserInfo?.snr?.amount * dlpPrices?.snrDLPPrice },
    { amount: lbpUserInfo?.mezz?.amount, value: lbpUserInfo?.mezz?.amount * dlpPrices?.mezzDLPPrice },
    { amount: lbpUserInfo?.jnr?.amount, value: lbpUserInfo?.jnr?.amount * dlpPrices?.jnrDLPPrice },
    { amount: lbpUserInfo?.dipEth?.amount, value: lbpUserInfo?.dipEth?.amount * dipPairPrice?.lpPrice },
  ];
  const earnData = [
    { amount: earnUserInfo?.snr?.amount, value: earnUserInfo?.snr?.amount * dlpPrices?.snrDLPPrice },
    { amount: earnUserInfo?.mezz?.amount, value: earnUserInfo?.mezz?.amount * dlpPrices?.mezzDLPPrice },
    { amount: earnUserInfo?.jnr?.amount, value: earnUserInfo?.jnr?.amount * dlpPrices?.jnrDLPPrice },
    { amount: earnUserInfo?.dipEth?.amount, value: earnUserInfo?.dipEth?.amount * dipPairPrice?.lpPrice },
  ];
  const stakingData = [
    { amount: stakingUserInfo?.dipStaking?.amount, value: stakingUserInfo?.dipStaking?.amount * dipPairPrice?.dipPrice },
    { amount: stakingUserInfo?.dgtStaking?.amount, value: stakingUserInfo?.dgtStaking?.amount * dgtPairPrice?.dgtPrice },
  ]
  const daoStakingData = [
    { amount: stakingUserInfo?.daoStaking?.amount, value: stakingUserInfo?.daoStaking?.amount * dipPairPrice?.dipPrice },
  ]
  const lbpTotalValue =
    lbpUserInfo?.snr?.amount * dlpPrices?.snrDLPPrice +
    lbpUserInfo?.mezz?.amount * dlpPrices?.mezzDLPPrice +
    lbpUserInfo?.jnr?.amount * dlpPrices?.jnrDLPPrice +
    lbpUserInfo?.dipEth?.amount * dipPairPrice?.lpPrice;
  const earnPoolTotalValue =
    earnUserInfo?.snr?.amount * dlpPrices?.snrDLPPrice +
    earnUserInfo?.mezz?.amount * dlpPrices?.mezzDLPPrice +
    earnUserInfo?.jnr?.amount * dlpPrices?.jnrDLPPrice +
    earnUserInfo?.dipEth?.amount * dipPairPrice?.lpPrice;

  const stakingTotalValue = stakingUserInfo?.dipStaking?.amount * dipPairPrice?.dipPrice +
    stakingUserInfo?.dgtStaking?.amount * dipPairPrice?.dipPrice;
  const daoTotalValue = stakingUserInfo?.daoStaking?.amount * dipPairPrice?.dipPrice;
  const earnTotalValue = lbpTotalValue + earnPoolTotalValue + stakingTotalValue + daoTotalValue;

  const initTokenBalance = (tokens, signer) => {
    // console.log("init token balance: ", tokens, signer)
    for (let token of tokens) {
      if (!token || !token.address || token.address === "") return;
      if (token.isNative) {
        // console.log("get native balance", signer, account, token)
        if (account && signer) {
          // console.log("token,", token.symbol)
          signer?.provider.getBalance(account).then((balance) => {
            // console.log("balance", balance)
            setBalanceMap((prevBalanceMap) => ({
              ...prevBalanceMap,
              [token.symbol]: formatAmount(balance, token.decimals),
            }));
          });
        }
      } else {
        if (account && signer) {
          // console.log("erc20  balance")
          const contract = new ethers.Contract(token.address, TokenABI.abi, signer);
          contract.balanceOf(account).then((balance) => {
            setBalanceMap((prevBalanceMap) => ({
              ...prevBalanceMap,
              [token.symbol]: formatAmount(balance, token.decimals),
            }));
          });
        }
      }
    }
  };
  return (
    <div className="default-bg page-layout">
      <div className="pb-30 pt-40">
        {/* <div className="Earn-header-bg">
          <div className="Earn-header default-container flex-col justify-center">
            <div className="Earn-header-title ">Portfolio</div>
            <div className="">
              Portfolio details page, where your Order assets, Position assets, Wallet balance, and Staking assets will
              be displayed
            </div>
          </div>
        </div> */}

        <div className="default-container">
          <div className="row">
            <div className=" col-12 col-md-2 ">
              <div className="row">
                {leftMenuCell.map((item, index) => (
                  <div
                    className={cx("col-4 col-md-12 Dao-left-cell flex aligns-center ", {
                      "Dao-left-cell-active": leftIndex === index,
                    })}
                    key={index}
                    onClick={() => menuClick(index)}
                  >
                    {item.icon && (
                      <img
                        className="icon-xs"
                        src={leftIndex === index ? item.activeIcon : item.icon}
                        alt={item.content}
                      />
                    )}

                    <div className="ml-10">{item.content}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-md-10">
              {leftIndex === 0 && (
                <AssetsOverview walletTotalValue={walletTotalValue} earnTotalValue={earnTotalValue} />
              )}
              {leftIndex === 1 && (
                <WalletAssets
                  lpTokens={lpTokens}
                  assetToken={assetToken}
                  balanceMap={balanceMap}
                  tokenPrice={tokenPrice}
                  dipPairPrice={dipPairPrice}
                  walletTotalValue={walletTotalValue}
                  dgtPairPrice={dgtPairPrice}
                />
              )}
              {leftIndex === 2 && (
                <StakingAssets
                  lbpData={lbpData}
                  earnData={earnData}
                  daoData={daoStakingData}
                  daoTotalValue={daoTotalValue}
                  stakingData={stakingData}
                  stakingTotalValue={stakingTotalValue}
                  lbpTotalValue={lbpTotalValue}
                  earnPoolTotalValue={earnPoolTotalValue}
                  earnTotalValue={earnTotalValue}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Assets;
