import React, { useState } from "react";
import cx from "classnames";
import "./Orders.css";
import DropDownIcon from "img/ic_dropdown.png";
import { formatMoney } from "lib/numbers";

export const CloseOrderType = ({ callBack, tokenPrice, token, isLong }) => {
  const [inputAmount, setInputAmount] = useState();
  //0: market ,1: Take profit 2: Stop loss
  const dropDownTabs = ["Market Order", "Take profit", "Stop loss"];
  const [orderType, setOrderType] = useState(0);
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setInputAmount(value);
      callBack({
        price: parseFloat(value),
        type: orderType,
        triggerAboveThreshold: orderType <= 1 ? isLong : !isLong,
      });
    }
  };
  const [showModal, setShowModal] = useState(false);
  const selectType = (index) => {
    setShowModal(false);
    setOrderType(index);
    let amount = "";
    if (index > 0) {
      amount = tokenPrice[token?.baseSymbol]?.price;
      setInputAmount(amount);
    } else {
      setInputAmount("MarketPrice");
    }

    callBack({
      price: parseFloat(amount),
      triggerAboveThreshold: index >= 1 ? isLong : !isLong,
      type: index,
    });
  };
  const getTriggerPriceText = () => {
    if (orderType === 0) {
      return `${isLong ? "≥" : "≤"} ${formatMoney(
        tokenPrice[token?.baseSymbol]?.price * 0.997,
        token?.displayDecimals
      )}`;
    } else if (orderType === 1) {
      return `${isLong ? "≥" : "≤"} ${inputAmount}`;
    } else if (orderType === 2) {
      return `${isLong ? "≤" : "≥"} ${inputAmount}`;
    }
  };
  const getTypeText = () => {
    return orderType === 0 ? "MarketPrice" : "";
  };
  return (
    <div>
      <div className="flex">
        <div className="mr-10">
          <div className="">OrderType</div>

          <div className=" Order-type-select-group mt-10">
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              style={{ minWidth: "12.2rem" }}
              className="flex aligns-center justify-between"
            >
              {dropDownTabs[orderType]}
              <img className="icon-ss ml-5" src={DropDownIcon} alt="order type"></img>
            </div>
            {showModal && (
              <div className="Currency-dropdown">
                {dropDownTabs.map((name, index) => (
                  <div
                    className="flex aligns-center App-header-dropdown-menu"
                    onClick={() => {
                      selectType(index);
                    }}
                  >
                    <div>{name} </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex-1">
          <div>Trigger Price</div>
          <div className="Order-type-Price-card mt-10">
            <input
              value={inputAmount}
              type="text"
              onChange={handleChange}
              placeholder={getTypeText()}
              className={cx({ "Earn-detail-left-subtitle": orderType === 0 })}
              disabled={orderType === 0 ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="Earn-detail-left-subtitle mt-10 text-right">Trigger when Mark Price {getTriggerPriceText()}</div>
    </div>
  );
};
