import React from "react";
import "./Dao.css";
import cx from "classnames";
import DefaultCard from "components/Common/DefaultCard";
import { formatAmount, formatMoney } from "lib/numbers";
import { defaultTokenDecimals } from "lib/pool";

export default function DaoOverview ({
  dipDaoLock,
  dipTreasuryReserve,
  chainId,
  signer,
  active,
  dipPairPrice,
  treasuryData,
  totalDepositAmount,
  totalDepositValue,
  apr,
  perDay,
  dgtPairPrice,
  dgtTotalSupply
}) {
  const dipReserveValue = (dipDaoLock + dipTreasuryReserve) * dipPairPrice.dipPrice;
  const snrDLPLiquidAssetsValue = treasuryData?.dlpValue ? treasuryData?.dlpValue : 0;
  const lPLiquidAssetsValue = treasuryData?.lpValue ? treasuryData?.lpValue : 0;

  const totalTreasuryValue = dipReserveValue + lPLiquidAssetsValue + snrDLPLiquidAssetsValue;

  const dgtCirculatValue = () => {
    let now = Date.now() / 1000;
    const vestingEnd = 1825164000;
    const vestingCycle = vestingEnd - 1698933600;
    now = now > vestingEnd ? vestingEnd : now;
    const dgtStakingLock = (750000 * (vestingEnd - now)) / vestingCycle;
    const totalSupply = formatAmount(dgtTotalSupply, 18, 6);
    // console.log("totalSupply: ", totalSupply);
    const circulatingSupply = totalSupply - dgtStakingLock;
    return circulatingSupply;
  };
  const treasuryInfoData = {
    title: "DGT Info",
    cells: [
      {
        left: "DGT Total Supply",
        right: `${formatAmount(dgtTotalSupply, defaultTokenDecimals, 4, true)} DGT`,
      },
      { left: "DGT Circulating Supply", right: `${formatMoney(dgtCirculatValue(), 4)} DGT` },
      { left: "DGT Estimated Value", right: `1DGT ~ $${formatMoney(dgtPairPrice?.dgtPrice, 2)}` },
      // {
      //   left: "Total Deposited",
      //   right: `-- DIP`,
      //   subRight: `$--`,
      // },
      // { left: "Staking APR", right: "--%", subRight: "-- DGT per Day" },
    ],
  };
  const stakingInfoData = {
    title: "DAO Staking Info",
    cells: [
      {
        left: "Total Deposited",
        right: `${formatMoney(totalDepositAmount, 4)} DIP`,
        subRight: `≈$${formatMoney(totalDepositValue, 2)} `,
      },
      { left: "Staking APR", right: `${formatMoney(apr, 2)}%`, subRight: `${formatMoney(perDay, 4)} DGT per Day` },
    ],
  };
  const treasuryReserveData = {
    title: "Treasury Reserve",
    rightTitle: `$${formatMoney((dipDaoLock + dipTreasuryReserve) * dipPairPrice.dipPrice, 2)}`,
    cells: [
      {
        left: "DIP",
        right: formatMoney(dipTreasuryReserve + dipDaoLock, 6),
        icons: [require("img/DIP-token.png")],
      },
    ],
  };
  const treasuryAssetsData = {
    title: "Treasury Liquid Assets",
    rightTitle: `$${snrDLPLiquidAssetsValue > 0 || lPLiquidAssetsValue > 0
      ? formatMoney(snrDLPLiquidAssetsValue + lPLiquidAssetsValue, 2)
      : "---"
      }`,
    cells: [
      {
        left: "Senior DLP",
        right: `${treasuryData?.dlpAmount ? formatMoney(treasuryData?.dlpAmount, 6) : "---"}`,
        subRight: `$${treasuryData?.dlpValue ? formatMoney(treasuryData?.dlpValue, 2) : "---"}`,
        icons: [require("img/ic_snr.png")],
      },
      {
        left: "DIP/ETH LP",
        right: `${treasuryData?.lpAmount ? formatMoney(treasuryData?.lpAmount, 6) : "---"}`,
        subRight: `$${treasuryData?.lpValue ? formatMoney(treasuryData?.lpValue, 2) : "---"}`,
        icons: [require("img/DIP-token.png"), require("img/ETH.png")],
      },
    ],
  };
  return (
    <div className="">
      <div className="Dao-title">Overview</div>
      <div className="mt-20">
        The DIP DAO serves as the Asset Manager and strategic decision-maker for the platform.
      </div>
      <div className="Dao-subTitle mt-20">
        Aiming to maintain a truly permissionless and decentralized environment while prioritizing the long-term
        interests of its community. Operating in a democratic and transparent manner, the DIP DAO assumes the role of
        overseeing strategic matters for DIP Exchange, with the governance token DGT facilitating governance within the
        platform.
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="DefaultCard-title mt-20">
            <div className="flex justify-between aligns-center">
              <div className="flex aligns-center">
                <div>Treasury Total Value</div>
              </div>
              <div>${formatMoney(totalTreasuryValue, 2)}</div>
            </div>
          </div>
          <DefaultCard data={treasuryReserveData}></DefaultCard>
          <DefaultCard data={treasuryAssetsData}></DefaultCard>
        </div>
        <div className="col-12 col-md-6">
          <DefaultCard data={treasuryInfoData}></DefaultCard>
          <DefaultCard data={stakingInfoData}></DefaultCard>
        </div>
      </div>
    </div>
  );
}
