import cx from "classnames";
import { formatMoney } from "lib/numbers";

const StakingAssetsTable = ({ data }) => {
  const { staking, stakingTokens, title, icon, total } = data;
  return (
    <div>
      <div className="mt-20">
        <div className="flex aligns-center">
          <div>
            <img src={icon} className="icon-xs" alt=""></img>
          </div>
          <div className="ml-10">
            {title}
            <span className="Earn-detail-left-subtitle ml-10">(${formatMoney(total, 2)})</span>
          </div>
        </div>
      </div>
      <div className="scroll-x">
        <table className="table table-dark table-hover table-striped align-middle" style={{ marginBottom: 0 }}>
          <thead className="Order-header">
            <tr>
              <th scope="col">Token</th>
              <th scope="col">Staking</th>
              <th scope="col">USD Value</th>
              {/* <th scope="col"></th> */}
            </tr>
          </thead>
          <tbody>
            {stakingTokens.map((token, index) => (
              <tr key={index}>
                <td>
                  <div className="flex aligns-center">
                    {token.icons.map((icon, index) => (
                      <img
                        key={index}
                        alt=""
                        src={icon}
                        className={cx("icon-sm", {
                          "DefaultCard--ml10": index > 0,
                        })}
                      ></img>
                    ))}
                    <div className="flex-col ml-20">
                      <div className="fw-bold">{token.symbol}</div>
                      <div className="Earn-detail-left-subtitle">{token.name}</div>
                    </div>
                  </div>
                </td>
                <td>{staking[index]?.amount ? formatMoney(staking[index].amount, 4) : "---"}</td>
                <td>{staking[index]?.value ? `$${formatMoney(staking[index].value, 2)}` : "---"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StakingAssetsTable;
