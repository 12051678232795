import React, { useEffect, useState } from "react";
import cx from "classnames";
import "./Orders.css";
import DropDownIcon from "img/ic_dropdown.png";

export const OrderType = ({ callBack, tokenPrice, token }) => {
  const [inputAmount, setInputAmount] = useState();
  //0: market ,1: limit
  const [orderType, setOrderType] = useState(0);
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setInputAmount(value);
      callBack({
        price: parseFloat(value),
        type: orderType,
      });
    }
  };
  const [showModal, setShowModal] = useState(false);
  const selectType = (index) => {
    setShowModal(false);
    setOrderType(index);
  };

  useEffect(() => {
    const price = tokenPrice[token?.baseSymbol]?.price;
    if (orderType === 1) {
      setInputAmount(price);
    } else {
      setInputAmount("MarketPrice");
    }

    callBack({
      price: parseFloat(price),
      type: orderType,
    });
  }, [token, orderType]);
  const getTypeText = () => {
    return orderType === 0 ? "MarketPrice" : "";
  };
  return (
    <div className=" Earn-detail-right-buy">
      <div className="flex aligns-center">
        <div className="flex-1">OrderType</div>
        <div className="flex-1">Price</div>
      </div>
      <div className="flex aligns-center">
        <div className="flex-1 Order-type-select-group mt-10 mr-10">
          <div
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            {orderType === 0 ? "Market Order" : "Limit Order"}
            <img className="icon-ss ml-5" src={DropDownIcon} alt="order type"></img>
          </div>
          {showModal && (
            <div className="Currency-dropdown">
              <div
                className="flex aligns-center App-header-dropdown-menu"
                onClick={() => {
                  selectType(0);
                }}
              >
                <div>Market Order </div>
              </div>
              <div
                className="flex aligns-center App-header-dropdown-menu"
                onClick={() => {
                  selectType(1);
                }}
              >
                <div>Limit Order </div>
              </div>
            </div>
          )}
        </div>
        <div className="Order-type-Price-card mt-10  flex-1">
          <input
            value={inputAmount}
            type="text"
            onChange={handleChange}
            placeholder={getTypeText()}
            className={cx({ "Earn-detail-left-subtitle": orderType === 0 })}
            disabled={orderType === 0 ? true : false}
          />
        </div>
      </div>
    </div>
  );
};
