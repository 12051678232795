import { formatMoney } from "lib/numbers";
import { useState, useEffect, useMemo } from "react";
import StakingAssetsTable from "./StakingAssetsTable";
import cx from "classnames";

const StakingAssets = ({ lbpData, earnData, stakingData, stakingTotalValue, earnPoolTotalValue, lbpTotalValue, earnTotalValue, daoTotalValue, daoData }) => {
  const earnTokens = [
    {
      name: "Senior",
      icons: [require("img/ic_snr.png")],
      symbol: "SnrDLP",
    },
    {
      name: "Mezzanine",
      symbol: "MezzDLP",
      icons: [require("img/ic_mezz.png")],
    },
    {
      name: "Junior",
      icons: [require("img/ic_jnr.png")],
      symbol: "JnrDLP",
    },
    {
      name: "DIP/ETH Lp",
      icons: [require("img/DIP-token.png"), require("img/ETH.png")],
      symbol: "DIP/ETH LP",
    },
  ];
  const stakingTokens = [
    {
      name: "DIP Token",
      icons: [require("img/DIP-token.png")],
      symbol: "DIP",
    },
    {
      name: "DGT Token",
      symbol: "DGT",
      icons: [require("img/DGT-token.png")],
    },
  ];
  const daoTokens = [
    {
      name: "DIP Token",
      icons: [require("img/DIP-token.png")],
      symbol: "DIP",
    },
  ];
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div>
      <div className="ml-10 mt-20">
        <div className="fs-2">Staking</div>
        <div className="Assets-total-card  mt-10">
          <div className="Earn-detail-left-subtitle">Total</div>
          <div className="fs-3 fw-bold">{formatMoney(earnTotalValue, 2)} USD</div>
        </div>
      </div>
      {/* <div className="Liquidity-card-split mt-10" style={{ margin: "auto" }}></div> */}
      <div className="mt-20">
        <div className="flex aligns-center">
          <div
            className={cx({
              AssetsTab: tabIndex !== 0,
              "AssetsTab-active": tabIndex === 0,
            })}
            onClick={() => {
              setTabIndex(0);
            }}
          >
            Earn Pool
          </div>
          <div
            className={cx({
              AssetsTab: tabIndex !== 1,
              "AssetsTab-active": tabIndex === 1,
            })}
            onClick={() => {
              setTabIndex(1);
            }}
          >
            Staking
          </div>
          <div
            className={cx({
              AssetsTab: tabIndex !== 2,
              "AssetsTab-active": tabIndex === 2,
            })}
            onClick={() => {
              setTabIndex(2);
            }}
          >
            DAO Staking
          </div>
          <div
            className={cx("", {
              AssetsTab: tabIndex !== 3,
              "AssetsTab-active": tabIndex === 3,
            })}
            onClick={() => {
              setTabIndex(3);
            }}
          >
            LBP
          </div>
        </div>
      </div>
      {tabIndex === 0 ? (
        <StakingAssetsTable
          data={{
            icon: require("img/ic_earn.png"),
            title: `Earn Pool`,
            total: earnPoolTotalValue,
            stakingTokens: earnTokens,
            staking: earnData,
          }}
        />
      ) : tabIndex === 1 ? (
        <StakingAssetsTable
          data={{
            icon: require("img/ic_staking.png"),
            title: `Staking`,
            total: stakingTotalValue,
            stakingTokens: stakingTokens,
            staking: stakingData,
          }}
        />
      ) : tabIndex === 2 ? (
        <StakingAssetsTable
          data={{
            icon: require("img/ic_dao.png"),
            title: `DAO Staking`,
            total: daoTotalValue,
            stakingTokens: daoTokens,
            staking: daoData,
          }}
        />
      ) : (
        <StakingAssetsTable
          data={{
            icon: require("img/ic_lbp.png"),
            title: `LBP`,
            total: lbpTotalValue,
            stakingTokens: earnTokens,
            staking: lbpData,
          }}
        />
      )}
    </div>
  );
};

export default StakingAssets;
