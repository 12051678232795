import "./Orders.css";
import longImg from "img/ic_long.png";
import shortImg from "img/ic_short.png";
import cx from "classnames";
import { ethers } from "ethers";
import { callContract } from "lib/contracts";
import { getContract } from "config/contracts";
import OrderManager from "abis/OrderManager.json";
import { formatMoney } from "lib/numbers";

export const Orders = ({ orders, tokenPrice, chainId, signer, setPendingTxns }) => {
  const buttonClick = (order) => {
    const contract = new ethers.Contract(getContract(chainId, "OrderManager"), OrderManager.abi, signer);
    callContract(
      chainId,
      contract,
      "cancelOrder",
      [order.id],

      {
        sentMsg: `Cancel submitted!`,
        failMsg: `Cancel failed.`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => { });
  };
  const getText = (order) => {
    let text = `${order.updateType === 0 ? "Increase" : "Decrease"} by $${order.sizeChange}`;
    if (order.updateType === 1 && parseFloat(order.sizeChange) === 0) {
      text = `Withdraw of $${formatMoney(order.collateralAmount, 2)}`;
    }
    // console.log("order.sizeChange", order.sizeChange);
    // console.log("order.updateType", order.updateType);
    if (order.updateType === 0 && parseFloat(order.sizeChange) === 0) {
      text = `Deposit of $${formatMoney(order.collateralAmount, 2)}`;
    }
    return text;
  };
  return (
    <div className="scroll-x">
      <table className="table table-dark table-hover table-striped align-middle  " style={{ marginBottom: 0 }}>
        <thead className="Order-header">
          <tr>
            <th scope="col">Order Type</th>
            <th scope="col">Type</th>
            <th scope="col">Order</th>
            <th scope="col">Trigger Condition</th>
            <th scope="col">Mark Price</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          {orders &&
            orders.map((order, index) => (
              <tr key={index}>
                <td>
                  <div className="flex-col">
                    <div className="fw-bold">{order.indexToken.baseSymbol}/USD</div>
                    <div
                      className={cx("flex aligns-center", { "up-color": order.isLong, "down-color": !order.isLong })}
                    >
                      <img src={order.isLong ? longImg : shortImg} className="icon-ss" alt=""></img>
                      <div className="Order-smValue"> {order.isLong ? "Long" : "Short"}</div>
                    </div>
                  </div>
                </td>
                <td>{order.type === 0 ? "Market" : "Limit"}</td>
                <td>{`${getText(order)}  `}</td>
                <td>
                  Mark Price{order.triggerAboveThreshold ? "≥" : "≤"} ${order.price}
                </td>

                <td>
                  $
                  {tokenPrice && tokenPrice[order.indexToken.baseSymbol]
                    ? formatMoney(tokenPrice[order.indexToken.baseSymbol].price, order.indexToken?.displayDecimals)
                    : "---"}
                </td>
                <td>
                  <div
                    className=" Order-symbol"
                    onClick={() => {
                      buttonClick(order);
                    }}
                  >
                    Cancel
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="Liquidity-card-split" style={{ marginTop: 0 }}></div>
    </div>
  );
};
