import cx from "classnames";
import React, { useState, useEffect } from "react";
import "./ExchangeModal.css";
import { expandOfStringDecimals, formatMoney, isNumber } from "lib/numbers";
import Button from "components/Button/Button";
import { ethers } from "ethers";
import { callContract } from "lib/contracts";
import OrderManager from "abis/OrderManager.json";
import { getContract } from "config/contracts";
import "./PositionsClose.css";
import { getPositionsInfoDePositionClose, encodeDePositionData } from "lib/positions";
import { getTokenBySymbol, getPoolAssets } from "config/tokens";
import { defaultTokenDecimals, defaultPriceDecimals, defaultValueDecimals, getExecutionFee } from "lib/pool";
import { ExchangeModalCell } from "components/Exchange/ExchangeModalCell";
import { CloseOrderType } from "./CloseOrderType";
import DropDownIcon from "img/ic_dropdown.png";
import { getConstant } from "config/chains";
import { getReferrerBytes } from "lib/referral";
export function PositionsClose ({
  position,
  tokenPrice,
  chainId,
  slippage,
  signer,
  setPendingTxns,
  onConfirmed,
  closeModal,
  showModal,
  account,
}) {
  // const { side, leverage, isLong } = position;

  const { isLong } = position;
  const indexToken = position.indexToken.symbol === "WETH" ? getTokenBySymbol(chainId, "ETH") : position.indexToken;
  // const indexToken = position.indexToken;
  const [confirming, setConfirming] = useState(false);
  const orderManagerAddr = getContract(chainId, "OrderManager");
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const USDT = getTokenBySymbol(chainId, defaultCollateralSymbol);
  const assetToken = isLong ? indexToken : USDT;
  const { executionFee, swapExecutionFee } = getExecutionFee();
  const [closeExecutionFee, setCloseExecutionFee] = useState(executionFee);
  const [orderType, setOrderType] = useState({ type: 0 });
  const [inputAmount, setInputAmount] = useState();
  const [positionsInfo, setPositionsInfo] = useState();
  const tokens = getPoolAssets(chainId);
  const [showTokenList, setShowTokenList] = useState(false);

  const [selectedToken, setSelectedToken] = useState(assetToken);
  const selectToken = (index) => {
    setSelectedToken(tokens[index]);
    setShowTokenList(false);
  };

  const clickShowToken = () => {
    setShowTokenList(!showTokenList);
  };
  const sizeChangePercentTabs = [0.25, 0.5, 0.75, 1];
  const processInfo = () => {
    setPositionsInfo(
      getPositionsInfoDePositionClose(
        tokenPrice,
        selectedToken,
        indexToken,
        isLong,
        inputAmount,
        slippage,
        position,
        orderType
      )
    );
  };

  useEffect(() => {
    processInfo();
    setCloseExecutionFee(
      selectedToken.baseSymbol !== assetToken.baseSymbol ? executionFee + swapExecutionFee : executionFee
    );
  }, [inputAmount, selectedToken]);

  const clickSizeChangePercent = (index) => {
    setInputAmount(position.size * sizeChangePercentTabs[index]);
  };
  /**
   * UpdatePositionType _updateType,
   * Side _side,
   * address _indexToken,
   * address _collateralToken,
   * OrderType _orderType,
   * bytes calldata data */

  /**
   * Order Price: 26810.633214867600
   * Order Pay Token: 0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c
   * Purchase Amount: 0.000300000000000000
   * Request Size Change: 381.863455559999980906827222000000
   * Request Collateral: 0.00300000000000000
   */
  const buttonClick = () => {
    if (!position || !inputAmount) return;

    let { decimals, address, isNative, placeHolderAddress, wrappedAddress } = assetToken;
    let receiveTokenAddress = selectedToken.isNative ? selectedToken.placeHolderAddress : selectedToken.address;
    let msgValue = parseFloat(closeExecutionFee);
    if (isNative) {
      address = placeHolderAddress;
      // msgValue += parseFloat(inputAmount);
    }

    // console.log("orderType.price:", orderType.price);

    const orderPrice = expandOfStringDecimals(
      orderType.type === 0 ? positionsInfo.triggerPrice : orderType.price,
      defaultPriceDecimals + defaultTokenDecimals - indexToken.decimals
    );
    const sizeChanged =
      inputAmount >= position.size ? position.originSize : expandOfStringDecimals(inputAmount, defaultValueDecimals);
    const amountChanged = expandOfStringDecimals(inputAmount / position.leverage, defaultValueDecimals);
    msgValue = expandOfStringDecimals(msgValue, defaultTokenDecimals);
    const contract = new ethers.Contract(orderManagerAddr, OrderManager.abi, signer);
    // console.log("orderPrice", orderPrice.toString());
    // console.log("positionValue", sizeChanged.toString());
    // console.log("msgValue", msgValue.toString());
    // console.log("indexToken", indexToken.isNative ? indexToken.wrappedAddress : indexToken.address);
    // console.log("assetToken", isNative ? wrappedAddress : address);

    // console.log("payToken", address);
    // console.log("position.Side", position.side);

    callContract(
      chainId,
      contract,
      "placeOrder",
      [
        1,
        position.side,
        indexToken.isNative ? indexToken.wrappedAddress : indexToken.address,
        isNative ? wrappedAddress : address,
        orderType.type === 0 ? 0 : 1,
        //order.price, order.payToken, request.sizeChange, request.collateral, extradata

        orderType.type === 0
          ? encodeDePositionData(
            [orderPrice, receiveTokenAddress, sizeChanged, amountChanged, getReferrerBytes()],
            true
          )
          : encodeDePositionData(
            [
              orderPrice,
              orderType.triggerAboveThreshold,
              receiveTokenAddress,
              sizeChanged,
              amountChanged,
              getReferrerBytes(),
            ],
            false
          ),
      ],

      {
        sentMsg: `Confirm submitted!`,
        failMsg: `Confirm failed.`,
        value: msgValue,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setConfirming(false);
        onConfirmed();
      });
  };
  const getButtonText = () => {
    if (confirming) {
      return "Confirming...";
    }
    return "Confirm";
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setInputAmount(value);
    }
  };
  // console.log("positionsInfo..", positionsInfo);
  const cells = [
    {
      title: "Leverage",
      value: isNumber(position?.leverage) ? `${position?.leverage?.toFixed(2)}x` : " ",
    },
    { title: "Slippage", value: `${slippage * 100}%` },
    { split: true },
    {
      title: "Collateral Asset",
      value: assetToken.baseSymbol,
      rightIcon: assetToken.imageUrl,
    },
    {
      title: "Position Size",
      value: `$${formatMoney(positionsInfo?.changed?.new?.size, 2)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.size, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Net Value",
      value: `$${formatMoney(positionsInfo?.changed?.new?.netValue, 2)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.netValue, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Collateral Value",
      value: `$${formatMoney(positionsInfo?.changed?.new?.collateralValue, 2)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.collateralValue, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Estimated Pnl",
      value: `$${formatMoney(positionsInfo?.pnl, 2)}`,
      valueClass: positionsInfo?.pnl > 0 ? "up-color" : "down-color",
    },
    { split: true },
    {
      title: "Mark Price",
      value: formatMoney(tokenPrice[indexToken?.baseSymbol].price, indexToken?.displayDecimals),
    },

    {
      title: "Entry Price",
      value: `$${formatMoney(position?.entryPrice, indexToken?.displayDecimals)}`,
    },
    {
      title: "Liquidation Price",
      value: `$${formatMoney(positionsInfo?.changed?.new?.liqPrice, indexToken?.displayDecimals)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.liqPrice, indexToken?.displayDecimals)}`,
      changed: positionsInfo?.changed !== undefined,

      valueClass: "waring-color",
    },
    { split: true },

    // { title: "Position Fee", value: `$${formatMoney(fee?.positionFee, 4)}` },
    { title: "Execution Fee", value: `${closeExecutionFee ? closeExecutionFee : ""} ETH` },
    // { title: "Borrow Fee", value: `${fee?.borrowFee ? fee?.borrowFee : ""}% per hour ` },
  ];
  return (
    <div
      className={cx("modal fade Exchange-modal Position-close", { show: showModal })}
      aria-modal={showModal}
      style={{ display: showModal ? "block" : "none" }}
      role="dialog"
      aria-hidden={showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3"> Close Position</h1>
            <div data-bs-theme="dark">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </div>
          <div className="Liquidity-card-split "></div>
          <div className="modal-body mt-10">
            <CloseOrderType
              tokenPrice={tokenPrice}
              token={indexToken}
              callBack={setOrderType}
              style={{ margin: "auto" }}
              isLong={isLong}
            />
            <div className="Liquidity-card-split mb-20 mt-20"></div>

            <div className="flex justify-between aligns-center ">
              <div> Closing Size</div>

              <div className="Earn-detail-left-subtitle">Max Close: ${formatMoney(position?.size, 2)}</div>
            </div>
            <div className="Exchange-modal-input  Exchange-modal-edit-input  mt-10">
              <div className=" flex justify-between aligns-center">
                <input
                  value={inputAmount}
                  type="text"
                  onChange={handleChange}
                  className={cx("fw-bold", {
                    "down-color": inputAmount > position?.size,
                  })}
                  style={{ padding: "0" }}
                />
                <div className="flex aligns-center Currency-selected-group">
                  <div>USD</div>
                </div>
              </div>

              {inputAmount && (
                <div className="Exchange-modal-sub-title mt-10">
                  <div className="flex  aligns-center ">
                    Receive
                    <div
                      className="Currency-selected-group flex justify-around aligns-center mr-10"
                      onClick={clickShowToken}
                    >
                      {selectedToken?.imageUrl && (
                        <img src={selectedToken?.imageUrl} className="icon-xs " alt={selectedToken?.symbol}></img>
                      )}
                      <div className="ml-5"> {selectedToken?.symbol} </div>
                      <img src={DropDownIcon} className="icon-ss ml-5" alt="more"></img>
                    </div>
                    ≈ {formatMoney(positionsInfo?.changedToken, 6)}
                  </div>
                  {showTokenList && (
                    <div className="Position-close-currency-dropdown">
                      {tokens &&
                        tokens.map((token, index) => (
                          <div
                            className="flex aligns-center App-header-dropdown-menu"
                            onClick={() => {
                              selectToken(index);
                            }}
                            key={index}
                          >
                            <img src={token.imageUrl} alt={token.symbol} className="icon-sm"></img>
                            <div className="ml-10">{token.symbol} </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-20 flex justify-around aligns-center">
              {sizeChangePercentTabs.map((tab, index) => (
                <div
                  key={index}
                  className="Position-close-percent-tabs"
                  onClick={() => {
                    clickSizeChangePercent(index);
                  }}
                >
                  {tab * 100}%
                </div>
              ))}
            </div>
            <div className="Liquidity-card-split mb-20 mt-20"></div>
            <ExchangeModalCell cells={cells} />
          </div>
          <div>
            <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
              <Button variant="primary-action" className="w-100">
                {getButtonText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
