import React, { useState } from "react";
import cx from "classnames";
import DGTIcon from "img/dgt-white.png";
import DIPIcon from "img/DIP-token.png";
import { IoMdSwap } from "react-icons/io";
import Button from "components/Button/Button";
import DefaultCard from "components/Common/DefaultCard";
import { expandOfStringDecimals, formatAmount, formatMoney } from "lib/numbers";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { getContract } from "config/contracts";
import { getTokenBySymbol } from "config/tokens";
import useSWR from "swr";
import DaoStakingAbi from "abis/DaoStaking.json"
import { callContract, contractFetcher } from "lib/contracts";
import { defaultTokenDecimals } from "lib/pool";
import ERC20 from "abis/ERC20.json";
import { ethers } from "ethers";
import CurrencySelect from "components/Currency/CurrencySelect";
import { approveTokens } from "domain/tokens";


export default function DaoStaking ({
  dipDaoLock,
  dipTreasuryReserve,
  chainId,
  signer,
  active,
  treasuryData,
  account,
  setPendingTxns,
  totalDepositAmount,
  totalDepositValue,
  apr,
  perDay,
  dgtPairPrice,
  dgtTotalSupply
}) {
  const stakeTokenName = "DIP";
  const { openConnectModal } = useConnectModal();
  const daoStakingAddr = getContract(chainId, "DaoStaking");
  const stakeToken = getTokenBySymbol(chainId, stakeTokenName);
  const [stakeTokenInfo, setStakeTokenInfo] = useState();

  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const { data: claimableRewards } = useSWR(active && [active, chainId, daoStakingAddr, "pendingReward", account], {
    fetcher: contractFetcher(signer, DaoStakingAbi),
  });
  const { data: userInfoData } = useSWR(active && [active, chainId, daoStakingAddr, "userInfo", account], {
    fetcher: contractFetcher(signer, DaoStakingAbi),
  });

  const userInfo = {
    amount: formatAmount(userInfoData?.amount, 18, 4, true),
    // rewardDebt: formatAmount(userInfoData?.rewardDebt, 18, 4, true),
  };

  const [state, setState] = useState({ tabIndex: 1 });
  const clickTab = (index) => {
    setState({
      tabIndex: index,
    });
  };

  const rowDefaultClass = "flex aligns-center justify-between";
  const treasuryAssetsData = {
    title: "Treasury Liquid Assets",
    rightTitle: `$${treasuryData?.dlpValue && treasuryData?.lpValue
      ? formatMoney(treasuryData?.dlpValue + treasuryData?.lpValue, 2)
      : treasuryData?.dlpValue
        ? formatMoney(treasuryData?.dlpValue, 2)
        : treasuryData?.lpValue
          ? formatMoney(treasuryData?.lpValue, 2)
          : "---"
      }`,
    cells: [
      {
        left: "Senior DLP",
        right: `${treasuryData?.dlpAmount ? formatMoney(treasuryData?.dlpAmount, 6) : "---"}`,
        subRight: `$${treasuryData?.dlpValue ? formatMoney(treasuryData?.dlpValue, 2) : "---"}`,
        icons: [require("img/ic_snr.png")],
      },
      {
        left: "DIP/ETH LP",
        right: `${treasuryData?.lpAmount ? formatMoney(treasuryData?.lpAmount, 6) : "---"}`,
        subRight: `$${treasuryData?.lpValue ? formatMoney(treasuryData?.lpValue, 2) : "---"}`,
        icons: [DIPIcon, require("img/ETH.png")],
      },
    ],
  };
  const stakingData = [
    {
      icon: DIPIcon,
      amount: formatAmount(userInfoData?.amount, defaultTokenDecimals, 4, true),
      value: `~$${formatMoney(formatAmount(userInfoData?.amount, defaultTokenDecimals, 4) * dgtPairPrice?.dipPairPrice?.dipPrice, 2, true)}`,
      button: "UNSTAKE",
      buttonClick: () => {
        withdraw();
      },
      title: "Your Staked DIP",
    },
    {
      icon: DGTIcon,
      amount: formatAmount(claimableRewards, defaultTokenDecimals, 6, true),
      value: `~$${formatMoney(formatAmount(claimableRewards, defaultTokenDecimals, 6) * dgtPairPrice?.dgtPrice, 2, true)}`,
      button: "CLAIM",
      title: "Claimable DGT",
      buttonClick: () => {
        claim();
      },
    },
  ];
  const rightCells = [{ title: "Fee", value: "0.4%" }];

  const deposit = async () => {
    if (active && stakeTokenInfo) {
      const { token, amount } = stakeTokenInfo;
      const { decimals } = token;
      if (!token.isNative) {
        const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        const allowanceAmount = await tokenContract.allowance(account, daoStakingAddr);
        if (formatAmount(allowanceAmount, decimals, decimals) < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: daoStakingAddr,
            chainId,
          });
          return;
        }
      }

      setIsStaking(true);

      const contract = new ethers.Contract(daoStakingAddr, DaoStakingAbi.abi, signer);

      // return;
      callContract(chainId, contract, "stake", [account, expandOfStringDecimals(amount, token.decimals)], {
        sentMsg: `Deposit submitted!`,
        failMsg: `Deposit failed.`,
        // setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };
  const withdraw = () => {
    if (active) {
      setIsWithdrawing(true);
      const contract = new ethers.Contract(daoStakingAddr, DaoStakingAbi.abi, signer);
      callContract(chainId, contract, "unstake", [account, expandOfStringDecimals(userInfo.amount, stakeToken.decimals)], {
        sentMsg: `Withdraw submitted!`,
        failMsg: `Withdraw failed.`,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsWithdrawing(false);
        });
    } else {
      openConnectModal();
    }
  };
  const claim = () => {
    // setConfirming(true);
    const contract = new ethers.Contract(daoStakingAddr, DaoStakingAbi.abi, signer);
    callContract(chainId, contract, "claimRewards", [account], {
      sentMsg: `Claim submitted!`,
      failMsg: `Claim failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        // closeModal();
      })
      .finally(() => {
        // setConfirming(false);
      });

  };
  const getButtonText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    if (isApproving) {
      return "Approving";
    }
    if (isStaking) {
      return "Depositing";
    }
    if (isWithdrawing) {
      return "Withdrawing";
    }
    return state.tabIndex === 1 ? "Deposit" : "Withdraw";
  };
  return (
    <div className="mt-20">
      <div className={cx("DaoStaking-group", rowDefaultClass)}>
        <div>Estimated DGT TotalSupply</div>
        <div className="flex aligns-center">
          <div>{formatAmount(dgtTotalSupply, defaultTokenDecimals, 6, true)}</div>
          <img src={DGTIcon} alt="DGT Token" className="icon-sm ml-10"></img>
        </div>
      </div>
      <div className="row ">
        <div className=" col-12 col-md-6 order-0 order-md-2 DefaultCard mt-20">
          <div className="DefaultCard-title">
            <div>Staking Info</div>
          </div>
          <div className="DefaultCard-content">
            {stakingData.map((item, index) => (
              <div key={index} className="mb-10">
                <div className="DefaultCard-cell-subTitle">{item.title}</div>
                <div className={cx(rowDefaultClass, " mt-10 DaoStaking-cell-input")}>
                  <div className="flex aligns-center">
                    <img src={item.icon} className="icon-sm mr-10" alt=""></img>
                    <div className="DefaultCard-cell-subRight">
                      <div>{item.amount}</div>
                      <div className="DefaultCard-cell-subTitle">{item.value}</div>
                    </div>
                  </div>
                  <div className="align-right DaoStaking-cell-button" onClick={item.buttonClick}>{item.button}</div>
                </div>
              </div>
            ))}
          </div>
          <DefaultCard data={treasuryAssetsData}></DefaultCard>
        </div>
        <div className="col-12 col-md-6 order-0 order-md-2 mt-20">
          <div className="Ear-detail-right order-2">
            <div className="flex">
              <div
                className={cx("flex-1 Earn-detail-right-tab", {
                  "up-color ": state.tabIndex === 1,
                  "Earn-detail-right-tab-unactive": state.tabIndex !== 1,
                })}
                onClick={() => clickTab(1)}
              >
                Stake
              </div>
              <div
                className={cx("flex-1 Earn-detail-right-tab", {
                  "down-color": state.tabIndex === 2,
                  "Earn-detail-right-tab-unactive": state.tabIndex !== 2,
                })}
                onClick={() => clickTab(2)}
              >
                Redeem
              </div>
            </div>
            {state.tabIndex === 1 ? (
              <div>
                <div className="Earn-detail-right-buy Dao-card">
                  <div className={rowDefaultClass}>
                    <div className="flex-1 ">
                      <div className="Earn-detail-left-subtitle">Total Deposited</div>
                      <div> {totalDepositAmount} DIP</div>
                      <div className="Earn-detail-left-subtitle">~${formatMoney(totalDepositValue, 2)}</div>
                    </div>
                    <div className="DaoStaking-split"></div>
                    <div className="flex-1 text-right">
                      <div className="Earn-detail-left-subtitle">Staking APR</div>
                      <div> {formatMoney(apr, 2)}%</div>
                      <div className="Earn-detail-left-subtitle">{perDay ? formatMoney(perDay, 6) : "--"} DGT per day</div>
                    </div>
                  </div>
                </div>
                <CurrencySelect
                  setStakeTokenInfo={setStakeTokenInfo}
                  hideList={true}
                  customerToken={[stakeTokenName]}
                />
                <div className="Earn-detail-right-cell">
                  {rightCells.map((item, index) =>
                    item.split ? (
                      <div className="Liquidity-card-split"></div>
                    ) : (
                      <div className={cx(rowDefaultClass, { "mt-10": index > 0 })} key={index}>
                        <div className="Earn-detail-left-subtitle">{item.title}</div>
                        <div className="Earn-detail-left-value">{item.value}</div>
                      </div>
                    )
                  )}
                  <div className="Exchange-swap-button-container mt-20">
                    <Button variant="primary-action" className="w-100" onClick={deposit}>
                      {getButtonText()}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="Earn-detail-right-buy">
                  <div className="DefaultCard-cell-subTitle">Redeem DGT</div>
                  <div className={cx(rowDefaultClass, " mt-10 Earn-detail-right-buy-input")}>
                    <div></div>
                    <div className="align-right DaoStaking-cell-right-button mr-10">DGT</div>
                  </div>
                  <div className="Liquidity-card-split mt-20 mb-10"></div>
                  <div className="DefaultCard-cell-subTitle">Receive </div>
                  <div className={cx(rowDefaultClass, " mt-10 Earn-detail-right-buy-input")}>
                    <div></div>
                    <div className="align-right DaoStaking-cell-right-button mr-10">DIP/ETH LP</div>
                  </div>
                  <div className="text-center mt-10 mb-10">+</div>
                  <div className={cx(rowDefaultClass, " mt-10 Earn-detail-right-buy-input")}>
                    <div></div>
                    <div className="align-right DaoStaking-cell-right-button mr-10">Senior DLP</div>
                  </div>
                  <div className="Exchange-swap-button-container mt-20">
                    <Button variant="primary-action" className="w-100">
                      Opens on November 16th
                    </Button>
                  </div>
                </div>

              </div>
            )}


          </div>
        </div>
      </div>
    </div>
  );
}
