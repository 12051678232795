import React from "react";
import "./Buy.css";

function BuyCard({ data }) {
  return (
    <div className="buy-component-content flex aligns-center ">
      <img style={{ height: "auto", width: "6rem" }} src={data.icon} alt=""></img>
      <div className="ml-20">{data.content}</div>
    </div>
  );
}

export default BuyCard;
