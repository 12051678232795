import cx from "classnames";
import "./Referrals.css";
import Button from "components/Button/Button";
import { useState } from "react";
import { ethers } from "ethers";
import { callContract } from "lib/contracts";

export function AddReferralModal ({
  showModal,
  closeModal,
  account,
  chainId,
  referralControllerAddr,
  ReferralController,
  signer,
  setPendingTxns,
}) {
  const [address, setAddress] = useState();
  const [checkedAddress, setCheckedAddress] = useState();
  const buttonClick = () => {
    callContract(
      chainId,
      new ethers.Contract(referralControllerAddr, ReferralController.abi, signer),
      "setReferrer(address)",
      [address],
      {
        sentMsg: `Set referrer address submitted!`,
        failMsg: `Set referrer address failed.`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        // setIsStaking(false);
        setAddress(void 0);
      });
    closeModal();
  };
  const addressChanged = (e) => {
    let value = e.target.value;
    setAddress(value);
    setCheckedAddress(ethers.utils.isAddress(value) && value !== account && value !== ethers.constants.AddressZero);
  };
  return (
    <div
      className={cx("modal fade Exchange-modal", { show: showModal })}
      aria-modal={showModal}
      style={{ display: showModal ? "block" : "none" }}
      role="dialog"
      aria-hidden={showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3">Add Referral Address</h1>
            <div data-bs-theme="dark">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </div>
          <div className="Liquidity-card-split "></div>

          <div className="modal-body mt-10">
            <div className="text-center">
              <img src={require("img/bg_referral.png")} alt="" width={320} height="auto"></img>
            </div>
            <div className="mt-20">
              Join DIP Exchange Referral Program to Earn Rewards Together You have been invited to participate in DIP
              Exchange referral program
            </div>
            <div className="Earn-detail-right-buy-input mt-20">
              <input style={{ width: "100%" }} onChange={addressChanged}></input>
            </div>
            <div className="">
              <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
                <Button variant="primary-action" className="w-100" disabled={!checkedAddress}>
                  Enter Address
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
