import cx from "classnames";
import React, { useState, useEffect } from "react";
import { formatAmount, formatMoney } from "lib/numbers";
import { getTokenBySymbol, getPoolAssets } from "config/tokens";
import DropDownIcon from "img/ic_dropdown.png";
import Button from "components/Button/Button";
import { ethers } from "ethers";
import { callContract } from "lib/contracts";
import { defaultTokenDecimals } from "lib/pool";
import { useTokenPrice } from "domain/prices";

export default function ClaimModal ({
  DLPToken,
  chainId,
  account,
  signer,
  showModal,
  closeModal,
  claimable,
  setPendingTxns,
  dipStakingAddr,
  DIPStakingAbi,
  dlpPrice,
}) {
  const [inputAmount, setInputAmount] = useState(formatAmount(claimable, defaultTokenDecimals, 6));
  const tokens = getPoolAssets(chainId).concat([DLPToken]);
  const [showTokenList, setShowTokenList] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [selectedToken, setSelectedToken] = useState(DLPToken);

  const tokenPrice = useTokenPrice(chainId, signer);
  const selectToken = (index) => {
    const token = tokens[index];
    setSelectedToken(token);
    setShowTokenList(false);
    const amount = (formatAmount(claimable, defaultTokenDecimals, 6) * getPrice(DLPToken)) / getPrice(token);
    setInputAmount(amount.toFixed(10));
  };

  const getPrice = (token) => {
    if (token.symbol === DLPToken.symbol) {
      return dlpPrice?.snrDLPPrice;
    } else {
      return tokenPrice[token.baseSymbol]?.price;
    }
  };

  const clickShowToken = () => {
    setShowTokenList(!showTokenList);
  };

  const getButtonText = () => {
    if (confirming) {
      return "Confirming...";
    }
    return "Confirm";
  };
  const buttonClick = () => {
    setConfirming(true);
    const contract = new ethers.Contract(dipStakingAddr, DIPStakingAbi.abi, signer);
    if (selectedToken.symbol === DLPToken.symbol) {
      callContract(chainId, contract, "claimRewards", [account], {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        setPendingTxns,
      })
        .then(async (res) => {
          closeModal();
        })
        .finally(() => {
          setConfirming(false);
        });
    } else {
      callContract(
        chainId,
        contract,
        "claimRewardsToSingleToken",
        [account, selectedToken.isNative ? selectedToken.wrappedAddress : selectedToken.address, 0],
        {
          sentMsg: `Claim submitted!`,
          failMsg: `Claim failed.`,
          setPendingTxns,
        }
      )
        .then(async (res) => {
          // setIsVisible(false);
          closeModal();
        })
        .finally(() => {
          setConfirming(false);
        });
    }
  };
  return (
    <div
      className={cx("modal fade Exchange-modal Position-close", { show: showModal })}
      aria-modal={showModal}
      style={{ display: showModal ? "block" : "none" }}
      role="dialog"
      aria-hidden={showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content" style={{ overflow: "visible" }}>
          <div className="modal-header">
            <h1 className="modal-title fs-3"> Claim</h1>
            <div data-bs-theme="dark">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </div>
          <div className="modal-body mt-10">
            <div className="flex aligns-center">
              <div className="Earn-detail-left-subtitle">Your reward </div>
              <div className="ml-10"> {formatAmount(claimable, defaultTokenDecimals, 4, true)}</div>
              <div className="Earn-detail-left-subtitle ml-10"> {DLPToken?.name}</div>
            </div>
            <div className="Liquidity-card-split mb-20 mt-20"></div>

            <div className="flex justify-between aligns-center ">
              <div> You will receive reward</div>
            </div>
            <div
              className="Exchange-modal-input  Exchange-modal-edit-input mt-10 flex justify-between aligns-center"
            // style={{ position: "relative" }}
            >
              <div>
                <input
                  value={inputAmount}
                  type="text"
                  className={cx("fw-bold", {
                    "down-color": inputAmount > claimable,
                  })}
                  disabled={true}
                  style={{ padding: "0" }}
                />
                {inputAmount && (
                  <div className="Exchange-modal-sub-title mt-10">
                    ≈ {formatMoney(getPrice(selectedToken) * inputAmount, 6)}
                  </div>
                )}
              </div>

              <div className="Currency-selected-group flex justify-around aligns-center mr-10" onClick={clickShowToken}>
                {selectedToken?.imageUrl && (
                  <img src={selectedToken?.imageUrl} className="icon-xs " alt={selectedToken?.symbol}></img>
                )}
                <div className="ml-5"> {selectedToken?.symbol} </div>
                <img src={DropDownIcon} className="icon-ss ml-5" alt="more"></img>
              </div>
            </div>
          </div>
          {showTokenList && (
            <div
              className="Position-close-currency-dropdown"
              style={{ top: "24rem", right: "2rem", left: "auto", zIndex: "9999" }}
            >
              {tokens &&
                tokens.map((token, index) => (
                  <div
                    className="flex aligns-center App-header-dropdown-menu"
                    onClick={() => {
                      selectToken(index);
                    }}
                    key={index}
                  >
                    <img src={token.imageUrl} alt={token.symbol} className="icon-sm"></img>
                    <div className="ml-10">{token.symbol} </div>
                  </div>
                ))}
            </div>
          )}
          <div>
            <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
              <Button variant="primary-action" className="w-100">
                {getButtonText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
