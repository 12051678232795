import React, { useState, useEffect, useMemo, useImperativeHandle, forwardRef } from "react";
import { getPoolAssets, getTokenBySymbols, WRAPPED_TOKENS_MAP } from "config/tokens";
import { useChainId } from "lib/chains";
import "./Currency.css";
import DropDownIcon from "img/ic_dropdown.png";
import { ethers } from "ethers";
import TokenABI from "abis/Token.json";
import { formatAmount } from "lib/numbers";
import Oracle from "abis/Oracle.json";
import { getContract } from "config/contracts";
import { getProvider } from "lib/rpc";
import { formatMoney } from "lib/numbers";
import useWallet from "lib/wallets/useWallet";

const Currency = forwardRef(
  (
    { setStakeTokenInfo, customerToken, hideList, hideMax, title, displayAmount, hideBalance, defaultSelectedToken },
    ref
  ) => {
    const { signer, account } = useWallet();
    const { chainId } = useChainId();
    const tokens = customerToken ? getTokenBySymbols(chainId, customerToken) : getPoolAssets(chainId);

    const [selectedToken, setSelectedToken] = useState(defaultSelectedToken ? defaultSelectedToken : tokens[0]);
    const [showTokenList, setShowTokenList] = useState(false);
    const [inputAmount, setInputAmount] = useState("");
    useImperativeHandle(ref, () => ({
      clearInput () {
        clear();
      },
    }));

    const clickShowToken = () => {
      setShowTokenList(!showTokenList);
    };

    const selectToken = (index) => {
      setSelectedToken(tokens[index]);
      setShowTokenList(false);
    };

    const [balance, setBalance] = useState(0);
    const [price, setPrice] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();

    const setTokenPrice = (token) => {
      if (!token) return;
      const contract = new ethers.Contract(getContract(chainId, "Oracle"), Oracle.abi, getProvider(signer, chainId));
      contract.getPrice(token.address, false).then((minPrice) => {
        contract.getPrice(token.address, true).then((maxPrice) => {
          // console.log("setprice", formatAmount(minPrice.add(maxPrice).div(2), 12 + (18 - token.decimals), 4));
          const decimalsPrice = formatAmount(minPrice.add(maxPrice).div(2), 12 + (18 - token.decimals), 4);
          const minDecimalsPrice = formatAmount(minPrice, 12 + (18 - token.decimals), 4);
          const maxDecimalsPrice = formatAmount(maxPrice, 12 + (18 - token.decimals), 4);
          setPrice(decimalsPrice);
          setMaxPrice(maxDecimalsPrice);
          setMinPrice(minDecimalsPrice);
          if (setStakeTokenInfo)
            setStakeTokenInfo({
              amount: "",
              token: selectedToken,
              price: decimalsPrice,
              maxPrice: maxDecimalsPrice,
              minPrice: minDecimalsPrice,
            });
        });
      });
    };

    const clear = () => {
      setInputAmount("");
      if (selectedToken) {
        if (selectedToken.isNative) {
          if (account && signer) {
            signer?.provider.getBalance(account).then((balance) => {
              console.log("account", account, balance)
              setBalance(formatAmount(balance, selectedToken.decimals));
            });
          }
          setTokenPrice(WRAPPED_TOKENS_MAP[chainId]);
        } else {
          if (account && signer) {
            const contract = new ethers.Contract(selectedToken.address, TokenABI.abi, signer);
            contract.balanceOf(account).then((balance) => {
              setBalance(formatAmount(balance, selectedToken.decimals));
            });
          }
          if (selectedToken.isPoolAssets) setTokenPrice(selectedToken);
        }
      }
    };
    useEffect(() => {
      clear();
      return () => { };
    }, [selectedToken, account]);

    useEffect(() => {
      if (customerToken && tokens.some((token) => token?.symbol === selectedToken?.symbol)) return;
      setSelectedToken(defaultSelectedToken ? defaultSelectedToken : tokens[0]);
    }, [tokens]);

    useMemo(() => {
      setSelectedToken(defaultSelectedToken);
    }, [defaultSelectedToken]);

    const clickMax = () => {
      setInputAmount(balance);
      if (setStakeTokenInfo)
        setStakeTokenInfo({
          amount: balance,
          token: selectedToken,
          price: price,
          minPrice: minPrice,
          maxPrice: maxPrice,
        });
    };
    const handleChange = (e) => {
      const value = e.target.value;
      const regex = /^[0-9.]*$/;
      if (regex.test(value)) {
        setInputAmount(value);
        if (setStakeTokenInfo)
          setStakeTokenInfo({
            amount: value,
            token: selectedToken,
            price: price,
            minPrice: minPrice,
            maxPrice: maxPrice,
          });
      }
    };
    return (
      <div>
        <div className="Earn-detail-right-buy">
          <div className="flex justify-between">
            <div>{title ? title : "AMOUNT"}</div>
            <div className="Earn-detail-left-subtitle">
              {!hideBalance && `Balance ${balance} ${selectedToken?.symbol}`}
            </div>
          </div>
          <div className="Earn-detail-right-buy-input mt-10">
            <div className="flex justify-between aligns-center">
              <input
                value={displayAmount ? displayAmount : inputAmount}
                type="text"
                onChange={handleChange}
                className="flex-1"
                disabled={displayAmount ? true : false}
              />

              {!hideMax && (
                <div className="Currency-selected-max mr-10 cursor-pointer" onClick={clickMax}>
                  MAX
                </div>
              )}
              <div className="Currency-selected-group flex justify-around aligns-center mr-10" onClick={clickShowToken}>
                {selectedToken?.imageUrl && (
                  <img src={selectedToken?.imageUrl} className="icon-xs " alt={selectedToken?.symbol}></img>
                )}
                <div className="ml-5"> {selectedToken?.symbol} </div>
                {!hideList && <img src={DropDownIcon} className="icon-ss ml-5" alt="more"></img>}
              </div>
              {showTokenList && !hideList && (
                <div className="Currency-dropdown">
                  {tokens &&
                    tokens.map((token, index) => (
                      <div
                        className="flex aligns-center App-header-dropdown-menu"
                        onClick={() => {
                          selectToken(index);
                        }}
                        key={index}
                      >
                        <img src={token.imageUrl} alt={token.symbol} className="icon-sm"></img>
                        <div className="ml-10">{token.symbol} </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          {(inputAmount > 0 || displayAmount > 0) && selectedToken.isPoolAssets && (
            <div className="Exchange-modal-sub-title mt-10 ml-10">
              ≈ ${formatMoney(price * (displayAmount ? displayAmount : inputAmount), 2)}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default Currency;
