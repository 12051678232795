import { formatMoney } from "lib/numbers";
import { useState, useEffect } from "react";
import CurrencyDefault from "components/Currency/CurrencyDefault";
import cx from "classnames";

const WalletAssets = ({ assetToken, lpTokens, balanceMap, tokenPrice, dipPairPrice, walletTotalValue, dgtPairPrice }) => {
  const getValue = (token) => {
    // if (!tokenPrice) return "---";

    let price;
    if (token.isStable) {
      price = { lastPrice: 1 };
    } else if (token.symbol === "DIP") {
      price = { lastPrice: dipPairPrice.dipPrice };
    } else if (token.symbol === "DGT") {
      price = { lastPrice: dgtPairPrice.dgtPrice };
    } else if (token.symbol === "DIP/ETH LP") {
      price = { lastPrice: dipPairPrice.lpPrice };
    } else if (token.symbol === "DGT/DIP LP") {
      price = { lastPrice: dgtPairPrice.lpPrice };
    } else {
      price = tokenPrice?.find((price) => price.symbol.includes(token.baseSymbol));
    }
    if (price) {
      return formatMoney(price.lastPrice * balanceMap[token.symbol], 2);
    } else {
      return "---";
    }
  };
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div>
      <div className="ml-10 mt-20">
        <div className="fs-2">Wallets</div>
        <div className="Assets-total-card  mt-10">
          <div className="Earn-detail-left-subtitle">Total</div>
          <div className="fs-3 fw-bold">{formatMoney(walletTotalValue, 2)} USD</div>
        </div>
      </div>
      {/* <div className="Liquidity-card-split mt-10" style={{ margin: "auto" }}></div> */}

      <div className="mt-20">
        <div className="flex aligns-center">
          <div
            className={cx({
              AssetsTab: tabIndex !== 0,
              "AssetsTab-active": tabIndex === 0,
            })}
            onClick={() => {
              setTabIndex(0);
            }}
          >
            Assets
          </div>
          <div
            className={cx("", {
              AssetsTab: tabIndex !== 1,
              "AssetsTab-active": tabIndex === 1,
            })}
            onClick={() => {
              setTabIndex(1);
            }}
          >
            DLP`s
          </div>
        </div>
      </div>
      <div className="scroll-x">
        <table className="table table-dark table-hover table-striped align-middle" style={{ marginBottom: 0 }}>
          <thead className="Order-header">
            <tr>
              <th scope="col">Token</th>
              <th scope="col">Balance</th>
              <th scope="col">USD Value</th>
              {/* <th scope="col"></th> */}
            </tr>
          </thead>
          {tabIndex === 0 && (
            <tbody>
              {assetToken &&
                assetToken.map((token, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex aligns-center">
                        {token.imageUrl ? (
                          <img src={token.imageUrl} alt={token.symbol} className="icon-32"></img>
                        ) : (
                          <CurrencyDefault />
                        )}
                        <div className="flex-col ml-20">
                          <div className="fw-bold">{token.symbol}</div>
                          <div className="Earn-detail-left-subtitle">{token.name}</div>
                        </div>
                      </div>
                    </td>
                    <td>{balanceMap && balanceMap[token.symbol] ? formatMoney(balanceMap[token.symbol], 2) : "---"}</td>
                    <td>${getValue(token)}</td>

                    {/* <td>
                        <div className=" Order-symbol" onClick={() => {}}>
                          Cancel
                        </div>
                      </td> */}
                  </tr>
                ))}
            </tbody>
          )}
          {tabIndex === 1 && (
            <tbody>
              {lpTokens &&
                lpTokens.map((token, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex aligns-center">
                        {token.imageUrl ? (
                          <img src={token.imageUrl} alt={token.symbol} className="icon-32"></img>
                        ) : (
                          <CurrencyDefault />
                        )}
                        <div className="flex-col ml-20">
                          <div className="fw-bold">{token.symbol}</div>
                          <div className="Earn-detail-left-subtitle">{token.name}</div>
                        </div>
                      </div>
                    </td>
                    <td>{balanceMap && balanceMap[token.symbol] ? formatMoney(balanceMap[token.symbol], 2) : "---"}</td>
                    <td>${getValue(token)}</td>

                    {/* <td>
                        <div className=" Order-symbol" onClick={() => {}}>
                          Cancel
                        </div>
                      </td> */}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default WalletAssets;
