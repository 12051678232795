import { getDiffDay } from "lib/dates";
import { formatMoney, formatAmount } from "lib/numbers";

// const dipInitPrice = 0.005;
const defaultDecimals = 18;

export function getPoolData(res, isNoPrice, type, lpPrice, dlpPrice) {
  // if (type !== undefined && !isDevelopment()) {
  //   return getTempPerDay(type);
  // }
  if (!res || res.length < 1) return;
  if (!dlpPrice) dlpPrice = 1;
  const lpPool = {
    depositTime: res[0].toString(),
    startRewardTime: res[1].toString(),
    unlockTime: res[2].toString(),
    baseRewards: formatAmount(res[3], defaultDecimals),
    bonusRewards: formatAmount(res[4], defaultDecimals),
    totalAmount: formatAmount(res[5], defaultDecimals),
    totalBoostedAmount: formatAmount(res[6], defaultDecimals),
    // management: formatAmount(res[5].add(res[6]), defaultDecimals),
  };

  const rewardDay = getDiffDay(lpPool.startRewardTime, lpPool.unlockTime, true);
  const perDayBase = parseFloat(lpPool.baseRewards) / rewardDay;
  const aprBase =
    ((perDayBase * 365 * lpPrice?.dipPrice) /
      (parseFloat(lpPool.totalAmount) === 0
        ? 1
        : parseFloat(lpPool.totalAmount) * getDepositPrice(type, lpPrice, dlpPrice))) *
    100;
  const perDayBonus = parseFloat(lpPool.bonusRewards) / rewardDay;

  const aprBonus =
    ((perDayBonus * 365 * lpPrice?.dipPrice) /
      (parseFloat(lpPool.totalBoostedAmount) === 0
        ? 1
        : parseFloat(lpPool.totalBoostedAmount) * getDepositPrice(type, lpPrice, dlpPrice))) *
    100;

  lpPool.perDay = (perDayBonus + perDayBase).toFixed(4);
  lpPool.apr = (aprBase + aprBonus).toFixed(2);
  lpPool.price = formatMoney(dlpPrice, 4);
  lpPool.totalValue = formatAmount(res[5], defaultDecimals);
  lpPool.totalAmount = (parseFloat(lpPool.totalValue) * getDepositPrice(type, lpPrice, dlpPrice)).toFixed(
    isNoPrice ? 4 : 2
  );
  return lpPool;
}

export function getUserInfoData(res) {
  if (!res || res.length < 1) return;
  return {
    amount: formatAmount(res[0], defaultDecimals),
    boostedAmount: formatAmount(res[1], defaultDecimals),
    rewardDebt: formatAmount(res[2], defaultDecimals),
  };
}

function getDepositPrice(type, lpPrice, dlpPrice) {
  return type === 3 ? lpPrice?.lpPrice : dlpPrice;
}
