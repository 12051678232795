import Davatar from "@davatar/react";
import { Menu } from "@headlessui/react";
import { t, Trans } from "@lingui/macro";
import { ETH_MAINNET } from "config/chains";
import copy from "img/ic_copy_16.svg";
import DisCordImg from "img/ic_discord_blue.png";
import AssetsImg from "img/ic_assets.png";
import CashImg from "img/ic_cashapp.png";
import externalLink from "img/ic_new_link_16.svg";
import disconnect from "img/ic_sign_out_16.svg";
import { helperToast } from "lib/helperToast";
import { useENS } from "lib/legacy";
import { useJsonRpcProvider } from "lib/rpc";
import { FaChevronDown } from "react-icons/fa";
import { createBreakpoint, useCopyToClipboard } from "react-use";
import "./AddressDropdown.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { bindDiscord, dcUserInfoUrl } from "lib/api";
import { useEffect, useState } from "react";
import { isFantomTestnet } from "config/env";
import { useHistory } from "react-router-dom";
import { useChainId } from "lib/chains";
import { shortenAddressOrEns } from "lib/wallets";


type Props = {
  account: string;
  accountUrl: string;
  disconnectAccountAndCloseSettings: () => void;
};

function AddressDropdown({ account, accountUrl, disconnectAccountAndCloseSettings }: Props) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const [, copyToClipboard] = useCopyToClipboard();
  const { ensName } = useENS(account);
  const { chainId } = useChainId();
  const { provider: ethereumProvider } = useJsonRpcProvider(ETH_MAINNET);
  const displayAddressLength = breakpoint === "S" ? 9 : 13;

  const [dcUserInfo, setDcUserInfo] = useState({ avatarUrl: undefined, userName: undefined });
  const history = useHistory();
  useEffect(() => {
    try {
      if (account && isFantomTestnet(chainId)) {
        fetch(`${dcUserInfoUrl}/${account}`).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setDcUserInfo(data.errno === 0 ? data.data : { avatarUrl: undefined, userName: undefined });
            });
          }
        });
      }
    } catch (e) { }
  }, [account, chainId]);
  return (
    <Menu>
      <Menu.Button as="div">
        <button className="App-cta small transparent address-btn">
          <div className="user-avatar">
            {ethereumProvider && <Davatar size={20} address={account} provider={ethereumProvider} />}
          </div>
          <span className="user-address">{shortenAddressOrEns(ensName || account, displayAddressLength)}</span>
          <FaChevronDown />
        </button>
      </Menu.Button>
      <div>
        <Menu.Items as="div" className="menu-items">
          {isFantomTestnet(chainId) && (
            <Menu.Item>
              <div
                className="menu-item"
                onClick={() => {
                  if (!dcUserInfo.userName) bindDiscord(account);
                  else helperToast.error("Already Verification Discord!");
                }}
                style={{ backgroundColor: "#5252f9", color: "#FFF" }}
              >
                <img src={DisCordImg} alt="discord" className="icon-sm"></img>
                {dcUserInfo.avatarUrl && <img src={dcUserInfo.avatarUrl} alt="discord" className="icon-sm ml-10"></img>}
                <p>
                  <Trans>{dcUserInfo.userName || "Verification Discord"}</Trans>
                </p>
              </div>
            </Menu.Item>
          )}
          <Menu.Item>
            <div className="menu-item" onClick={() => history.push("/assets")}>
              <img src={AssetsImg} alt="Assets" className="icon-xs" />
              <p>
                <Trans>Assets</Trans>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={() => history.push("/fiat")}>
              <img src={CashImg} alt="Fiat" className="icon-xs" />
              <p>
                <Trans>Fiat On/Off</Trans>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={() => {
                copyToClipboard(account);
                helperToast.success(t`Address copied to your clipboard`);
              }}
            >
              <img src={copy} alt="Copy user address" />
              <p>
                <Trans>Copy Address</Trans>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <ExternalLink href={accountUrl} className="menu-item">
              <img src={externalLink} alt="Open address in explorer" />
              <p>
                <Trans>View in Explorer</Trans>
              </p>
            </ExternalLink>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={disconnectAccountAndCloseSettings}>
              <img src={disconnect} alt="Disconnect the wallet" />
              <p>
                <Trans>Disconnect</Trans>
              </p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default AddressDropdown;
