import { defaultApiUrl, getApiPathName } from "lib/api";
import { useEffect, useState } from "react";
export function useReferralInfo(chainId, account) {
  const [referralInfo, setReferralInfo] = useState();
  useEffect(() => {
    fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/referral/info/${account}`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          if (data.errno === 0) {
            setReferralInfo(data.data);
          } else {
            setReferralInfo(undefined);
          }
        });
      }
    });
  }, [chainId, account]);
  return referralInfo;
}
