import { formatMoney, formatAmount, bigNumberify } from "lib/numbers";

const weightDecimals = 5;

export function getPoolData(res, token, price, targetWeight) {
  if (!res || res.length < 1 || !targetWeight) return;
  return {
    balance: formatAmount(res?.poolBalance, token.decimals, 4, true),
    value: formatMoney(price * formatAmount(res?.poolBalance, token.decimals, 4), 4, true),
    targetWeight: (formatAmount(targetWeight, weightDecimals) * 100).toFixed(2),
  };
}

export function getTranchePoolData(res, token, price, targetWeight, total) {
  if (!res || res.length < 1 || !targetWeight) return;
  const poolAmount = formatAmount(res?.poolAmount, token.decimals, 8);
  const reservedAmount = formatAmount(res?.reservedAmount, token.decimals, 8);
  // console.log(reservedAmount, poolAmount, reservedAmount / poolAmount);
  const utilization = res?.poolAmount.toString() === "0" ? 0 : parseFloat(reservedAmount) / parseFloat(poolAmount);
  // console.log("utilization", utilization.toString());
  // console.log("reservedAmount", reservedAmount);
  // console.log("poolAmount", poolAmount);
  // console.log(
  //   "utilization",
  //   res?.poolAmount.toString() !== "0" ? res?.reservedAmount.div(res?.poolAmount).toString() : "01112"
  // );
  // console.log("----------------------------------------------------------------");
  // console.log("poolAmount1", res?.reservedAmount.div(res?.poolAmount));

  return {
    balance: formatAmount(res?.poolAmount, token.decimals, 4, true),
    value: formatMoney(price * formatAmount(res?.poolAmount, token.decimals, 4), 4),
    totalValue: formatMoney(price * formatAmount(total?.poolBalance, token.decimals, 4), 4),
    targetWeight: (formatAmount(targetWeight, weightDecimals) * 100).toFixed(2),
    utilization: (utilization * 100).toFixed(2),
  };
}

export function getCurrentWeights(btc, eth, usdt) {
  if (!btc || !eth || !usdt) return;

  const btcValue = parseFloat(btc.replace(/,/g, ""));
  const ethValue = parseFloat(eth.replace(/,/g, ""));
  const usdtValue = parseFloat(usdt.replace(/,/g, ""));
  let totalValue = btcValue + usdtValue + ethValue;
  if (totalValue == 0) totalValue = 1;
  return {
    ETH: ((ethValue / totalValue) * 100).toFixed(2),
    BTC: ((btcValue / totalValue) * 100).toFixed(2),
    USDbC: ((usdtValue / totalValue) * 100).toFixed(2),
  };
}
