import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import moment from "moment";
// import nftVideo from "video/DipMascot_Orbit.mp4";
import "./Nft.css";

function Nft () {
  const [showTimeDown, setShowTimeDown] = useState(0);
  const startTimeStr = "2023-08-28 14:00:00";
  const endTimeStr = "2023-08-20 14:00:00";
  const endTime = new Date(endTimeStr);
  const startTime = new Date(startTimeStr);
  const [timeSetInterval, setTimeSetInterval] = useState();
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  useEffect(() => {
    require("lib/firefly.js");
  }, []);
  const timeDown = () => {
    setTimeSetInterval(
      setInterval(() => {
        let date;
        if (moment(new Date()).isBefore(startTime)) {
          setShowTimeDown(0);
          date = startTime;
        } else if (moment(new Date()).isAfter(endTime)) {
          setShowTimeDown(-1);
          clearInterval(timeSetInterval);
        } else {
          setShowTimeDown(1);
          date = endTime;
        }
        let dur = moment.duration(moment(date) - moment(), "ms");
        setDays(dur.get("days"));
        setHours(dur.get("hours"));
        setMinutes(dur.get("minutes"));
        setSeconds(dur.get("seconds"));
      }, 1000)
    );
  };
  return (
    <div className="default-bg">
      <div className="buthu_mint1_bg">
        <canvas id="canvas" className=""></canvas>
      </div>
      <div className="buthu_mint_body_content">
        <div className="row">
          <div className="col-md-6">
            <div className="buthu_mint1_body_left animated fadeInLeft">
              <div className="buthu_mint1_body_left_content">
                <div className="mint-item_img">
                  <div className="mint-slideshow-container text-center">
                    <img src={require("img/DipMascot.png")} width={"100%"} height={"auto"} />
                  </div>
                  {/* <video id="launchVideo" width="100%" height="auto" controls autoPlay loop muted>
                    <source src={nftVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  <br />

                  <div style={{ display: "none" }}>
                    <span className="dotmint"></span>
                    <span className="dotmint"></span>
                    <span className="dotmint"></span>
                    <span className="dotmint"></span>
                  </div>
                </div>
                <div className="mint_count_list mt-0 ">
                  <ul className="mt-10">
                    <li>
                      <h5>TotalSupply</h5>
                      <h5>
                        <span>5000</span>
                      </h5>
                    </li>
                    <li>
                      <h5>Price</h5>
                      <h5>0.00 ETH</h5>
                    </li>
                    <li>
                      <h5>Quantity</h5>
                      <div className="mint_quantity_sect">
                        <button className="input-number-decrement">-</button>
                        <input className="input-number" type="text" value="1" readonly />
                        <button className="input-number-increment">+</button>
                      </div>
                      <h5>
                        <span>0.00</span> ETH
                      </h5>
                    </li>
                  </ul>
                </div>
                <Button className="primary-action w-100">Free Mint</Button>
                <p>
                  By clicking “MINT”, You agree to our
                  <a href="https://docs.dip.exchange/archive/terms-and-conditions">Terms of Service</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="buthu_mint1_body_right animated fadeInRight">
              <div className="mint1_mash_gard">
                <span>
                  <img src={require("img/v4_baner_mesh-grad.png")} alt="img" />
                </span>
              </div>
              <h4 className="mb-10">
                WHITELIST : SOLDOUT
                <span>
                  <img src={require("img/mint-right-text-icon.svg")} alt="icon" />
                </span>
              </h4>
              <h1>
                FREE MINT
                {showTimeDown == 0 ? " Coming soon" : showTimeDown == 1 ? " LIVE" : " End"}
              </h1>

              <div className="buthu_mint1_body_right_timer">
                <h5 className="uppercase">
                  FREE Mint
                  {showTimeDown == 0 ? "start with" : showTimeDown == 1 ? "end in" : "end"}
                </h5>
                <div className="timer timer_1">
                  <ul>
                    <li className="days">
                      {days}
                      <span>D</span>
                    </li>
                    <li className="hours">
                      {hours}
                      <span>H</span>
                    </li>
                    <li className="minutes">
                      {minutes}
                      <span>M</span>
                    </li>
                    <li className="seconds">
                      {seconds}
                      <span>S</span>
                    </li>
                  </ul>
                </div>
              </div>

              <h5 className="mb-12 uppercase">Max 5 NFTs per wallet</h5>
              <h5 className="mb-12 uppercase">Price 0.00 ETH + gas</h5>
              <h5 className="mb-12 uppercase" v-if="showTimeDown == 0">
                Free Mint is live with {new Date(startTimeStr).toUTCString()}
              </h5>

              <h5 className="mb-12 uppercase" v-else>
                Free Mint is live until {new Date(endTimeStr).toUTCString()}
              </h5>

              <div className="bithu_v1_baner_buttons"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nft;
