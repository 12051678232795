import { useMemo, useState } from "react";
import type {} from "styled-jsx";
// import { FEE_COLLECTOR, FEE_TENTH_BPS, PARTNER_ID } from "./config";
import { theme, FontFamily } from "./theme";
import "./Bridge.css";
import StargateIcon from "img/ic_stargate.png";
import cx from "classnames";

export const StargateWidget = ({ theme, children }) => {
  const jsonTheme = useMemo(() => JSON.stringify(theme), [theme]);
  return (
    <div className="Bridge-stargate-content">
      <style jsx global>{`
        .MuiScopedCssBaseline-root {
          background-color: transparent !important;
          font-size: 14px;
        }
        .StgHeader {
          border-bottom: 1px solid ${theme.palette.divider} !important;
        }
        .StgHeader .MuiTypography-subtitle1 {
          font-family: ${FontFamily.ROBOTO};
          font-weight: 600;
          font-size: 25px;
        }
        .StgHeader svg {
          width: 20px;
          height: 20px;
        }
        .MuiPaper-root {
          background: #212435;
          min-height: 100%;
        }
        .label {
          font-size: 1.5rem;
        }
        button {
          margin: 1rem auto !important;
           {
            /* font-size: 1.5rem !important; */
          }
        }
        .MuiScopedCssBaseline-root .StgMaxButton {
          border-color: ${theme.palette.primary.main} !important;
          background-color: transparent;
          font-family: ${FontFamily.ROBOTO};
          padding: 11px 16px;
          font-size: 15px;
        }
        .MuiFormLabel-root.Mui-focused {
          color: ${theme.palette.text.primary} !important;
        }
      `}</style>
      {/* @ts-ignore */}
      <stargate-widget theme={jsonTheme} partnerId="0x0028" />
      {children}
    </div>
  );
};

export default function Bridge() {
  const [tabIndex, setTabIndex] = useState(0);

  const stargateClick = () => {
    window.open("https://stargate.finance/", "_blank");
  };
  return (
    <div className="default-bg">
      <div className="Bridge-stargate-container">
        {/* <div className="Earn-header default-container flex-col justify-center">
          <div className="Earn-header-title ">Bridge</div>
          <div className="">Bridge assets from or to BASE</div>
         </div> */}
        <div className="mt-20">
          <div className="flex aligns-center justify-center">
            <div
              className={cx({
                AssetsTab: tabIndex !== 0,
                "AssetsTab-active": tabIndex === 0,
              })}
              onClick={() => {
                setTabIndex(0);
              }}
            >
              Axelar
            </div>
            <div
              className={cx("", {
                AssetsTab: tabIndex !== 1,
                "AssetsTab-active": tabIndex === 1,
              })}
              onClick={() => {
                setTabIndex(1);
              }}
            >
              Stargate
            </div>
          </div>
        </div>
        <div className="mt-20">
          <div style={{ display: `${tabIndex === 1 ? "flex" : "none"}` }}>
            <StargateWidget theme={theme}>
              <div className="flex aligns-center justify-center cursor-pointer" onClick={stargateClick}>
                <div className="mr-15"> Power by</div>
                <img src={StargateIcon} style={{ height: "2rem" }} alt="stargate"></img>
              </div>
            </StargateWidget>
          </div>
          <div className="flex justify-center" style={{ display: `${tabIndex === 0 ? "flex" : "none"}` }}>
            <iframe
              title="squid_widget"
              width="420"
              height="691"
              src="https://widget.squidrouter.com/iframe?config=%7B%22integratorId%22%3A%22squid-swap-widget%22%2C%22companyName%22%3A%22Squid%22%2C%22style%22%3A%7B%22neutralContent%22%3A%22%23ffffff%22%2C%22baseContent%22%3A%22%23ffffff%22%2C%22base100%22%3A%22%233e3946%22%2C%22base200%22%3A%22%23212438%22%2C%22base300%22%3A%22%23e8e8e8%22%2C%22error%22%3A%22%23ED6A5E%22%2C%22warning%22%3A%22%23FFB155%22%2C%22success%22%3A%22%232EAEB0%22%2C%22primary%22%3A%22%235252f9%22%2C%22secondary%22%3A%22%23F89CC3%22%2C%22secondaryContent%22%3A%22%23F7F6FB%22%2C%22neutral%22%3A%22%23212433%22%2C%22roundedBtn%22%3A%2226px%22%2C%22roundedCornerBtn%22%3A%22999px%22%2C%22roundedBox%22%3A%221rem%22%2C%22roundedDropDown%22%3A%2220rem%22%7D%2C%22slippage%22%3A1.5%2C%22infiniteApproval%22%3Afalse%2C%22enableExpress%22%3Atrue%2C%22apiUrl%22%3A%22https%3A%2F%2Fapi.squidrouter.com%22%2C%22mainLogoUrl%22%3A%22https%3A%2F%2Fbafybeie6qu5qnqlubioixkansrxpl7fdu3utykrlf7sug4sng53coe6msi.ipfs.w3s.link%2Flogo_dip_blue.png%22%2C%22comingSoonChainIds%22%3A%5B%5D%2C%22titles%22%3A%7B%22swap%22%3A%22Swap%22%2C%22settings%22%3A%22Settings%22%2C%22wallets%22%3A%22Wallets%22%2C%22tokens%22%3A%22Select%20Token%22%2C%22chains%22%3A%22Select%20Chain%22%2C%22history%22%3A%22History%22%2C%22transaction%22%3A%22Transaction%22%2C%22allTokens%22%3A%22Select%20Token%22%2C%22destination%22%3A%22Destination%20address%22%7D%2C%22priceImpactWarnings%22%3A%7B%22warning%22%3A3%2C%22critical%22%3A5%7D%2C%22showOnRampLink%22%3Atrue%7D"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
