import "./Orders.css";
import React, { useState } from "react";
import longImg from "img/ic_long.png";
import shortImg from "img/ic_short.png";
import cx from "classnames";
import { formatMoney } from "lib/numbers";
import { PositionsEdit } from "components/Exchange/PositionsEdit";
import ShareIcon from "img/ic_share.png";
// import { ShareModal } from "components/Share/ShareModal";
import PositionShare from "./PositionShare";
import { PositionsClose } from "./PositionsClose";
import "./Positions.css";

export const Positions = ({ positions, orders, tokenPrice, chainId, signer, setPendingTxns, account, slippage }) => {
  const [showModal, setShowModal] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [closePositionsModal, setClosePositionsModal] = useState(false);

  const [editPosition, setEditPosition] = useState();
  const closeModal = () => {
    setShowModal(false);
    setEditPosition(undefined);
  };
  const clickEdit = (position) => {
    setEditPosition(position);
    setShowModal(true);
  };
  const shareClick = (position) => {
    setShowShare(true);
    setEditPosition(position);
  };
  const clickClose = (position) => {
    setEditPosition(position);
    setClosePositionsModal(true);
  };
  const closeCloseModal = () => {
    setClosePositionsModal(false);
    setEditPosition(undefined);
  };

  const isUpdating = (position) => {
    if (!position || !orders) return false;
    // console.log("position:...", orders);
    // console.log("position:...position", position);
    const result = orders.some(
      (order) =>
        order.collateralToken.baseSymbol === position.assetToken.baseSymbol &&
        order.indexToken.baseSymbol === position.indexToken.baseSymbol &&
        order.type === 0
    );
    // console.log("position:...result", result);
    return result;
  };

  return (
    <div className="scroll-x">
      <table className="table table-dark table-hover table-striped align-middle  " style={{ marginBottom: 0 }}>
        <thead className="Order-header">
          <tr>
            <th scope="col">Position</th>
            <th scope="col">Size</th>
            <th scope="col">Net Value</th>
            <th scope="col">Mark Price</th>
            <th scope="col">Entry Price</th>
            <th scope="col">Liquidation Price</th>
            {/* <th scope="col">Collateral Asset</th> */}
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          {positions &&
            positions.map((position, index) => (
              <tr key={index}>
                <td>
                  {isUpdating(position) === true && (
                    <div className="flex aligns-center">
                      <div className="Position-spinner"></div>
                      <div className="Position-spinner-text ml-5">Position is being updated…</div>
                    </div>
                  )}
                  <div className="flex-col">
                    <div className="fw-bold">{position?.indexToken?.baseSymbol}/USD</div>
                    <div
                      className={cx("flex aligns-center", {
                        "up-color": position?.isLong,
                        "down-color": !position?.isLong,
                      })}
                    >
                      <img src={position?.isLong ? longImg : shortImg} className="icon-ss" alt=""></img>
                      <div className="Order-smValue"> {position?.isLong ? "Long" : "Short"}</div>
                    </div>
                  </div>
                </td>
                <td>${formatMoney(position?.size, 2)}</td>
                <td>
                  <div className={cx("flex-col")}>
                    <div>${formatMoney(position?.netValue, 2)}</div>
                    <div
                      className={cx("Order-smValue", {
                        "up-color": position?.netValuePercent >= 0,
                        "down-color": position?.netValuePercent < 0,
                      })}
                      style={{ margin: "0" }}
                    >
                      {position?.netValuePercent >= 0 ? "+" : ""}$
                      {formatMoney(position?.netValue - position?.collateralValue, 2)}(
                      {position?.netValuePercent >= 0 ? "+" : ""}
                      {(position?.netValuePercent * 100).toFixed(2)}%)
                    </div>
                  </div>
                </td>
                <td>
                  $
                  {formatMoney(
                    tokenPrice && tokenPrice[position?.indexToken?.baseSymbol]
                      ? tokenPrice[position?.indexToken?.baseSymbol].price
                      : "---",
                    position?.indexToken?.displayDecimals
                  )}
                </td>
                <td>${formatMoney(position?.entryPrice, position?.indexToken?.displayDecimals)}</td>

                <td>
                  <span className="waring-color">
                    ${formatMoney(position?.liqPrice, position?.indexToken?.displayDecimals)}
                  </span>
                </td>
                {/* <td>{position.assetToken?.baseSymbol}</td> */}
                <td>
                  <div className=" Order-symbol">
                    <span
                      onClick={() => {
                        clickEdit(position);
                      }}
                      className="cursor-pointer"
                    >
                      Edit
                    </span>
                    <span
                      className="ml-10 cursor-pointer"
                      onClick={() => {
                        clickClose(position);
                      }}
                    >
                      Close
                    </span>
                    <img
                      src={ShareIcon}
                      alt="share"
                      className="icon-xs ml-10 cursor-pointer"
                      onClick={() => {
                        shareClick(position);
                      }}
                    ></img>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="Liquidity-card-split mb-20" style={{ marginTop: 0 }}></div>
      {/* {showShare && <ShareModal></ShareModal>} */}
      {showShare && (
        <PositionShare
          setIsPositionShareModalOpen={setShowShare}
          isPositionShareModalOpen={showShare}
          positionToShare={editPosition}
          chainId={chainId}
          account={account}
          tokenPrice={tokenPrice}
        />
      )}
      {showModal && (
        <PositionsEdit
          position={editPosition}
          tokenPrice={tokenPrice}
          chainId={chainId}
          signer={signer}
          slippage={slippage}
          setPendingTxns={setPendingTxns}
          onConfirmed={() => {
            closeModal();
          }}
          closeModal={() => {
            closeModal();
          }}
          showModal={showModal}
          fee={{}}
          account={account}
        />
      )}
      {closePositionsModal && (
        <PositionsClose
          position={editPosition}
          tokenPrice={tokenPrice}
          chainId={chainId}
          signer={signer}
          slippage={slippage}
          setPendingTxns={setPendingTxns}
          onConfirmed={() => {
            closeCloseModal();
          }}
          closeModal={() => {
            closeCloseModal();
          }}
          showModal={closePositionsModal}
          fee={{}}
          account={account}
        />
      )}
    </div>
  );
};
