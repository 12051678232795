import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Detail.css";
import LiquidityTab from "components/LiquidityCard/LiquidityTab";
import cx from "classnames";
import Button from "components/Button/Button";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { approveTokens } from "domain/tokens";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import LiquidityBootstrapping from "abis/LiquidityBootstrapping.json";
import { getPoolData, getUserInfoData } from "./services";
import CurrencySelect from "components/Currency/CurrencySelect";
import { ethers } from "ethers";
import ERC20 from "abis/ERC20.json";
import { formatAmount, expandOfStringDecimals } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";


export default function InnovationDetail ({ setPendingTxns }) {

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();

  const { chainId } = useChainId();
  const lbpContractsName = [""];

  const { data: multiPoolData } = useSWR(
    [`Innovation:info${active}`, chainId, getContract(chainId, lbpContractsName[0]), "info"],
    {
      fetcher: contractFetcher(signer, LiquidityBootstrapping),
    }
  );

  // const { data: dipFtmLBPPoolData } = useSWR(
  //   active && [active, chainId, getContract(chainId, lbpContractsName[3]), "info"],
  //   {
  //     fetcher: contractFetcher(signer, LiquidityBootstrapping),
  //   }
  // );
  const multiPool = getPoolData(multiPoolData);
  // const dipFtmLBPPool = getPoolData(dipFtmLBPPoolData);
  const { poolIndex } = useParams();
  const [trancheIndex, setTrancheIndex] = useState(poolIndex - 1);
  const history = useHistory();

  const tranches = [
    {
      title: "Stargate Pool LBP",
      risk: 3,
      contractName: lbpContractsName[0],
      lpToken: getTokenBySymbol(chainId, "StgDLP"),
      perDay: multiPool?.perDay,
      price: multiPool?.price ? multiPool?.price : "---",
      management: multiPool?.totalAmount ? multiPool?.totalAmount : "---",
      apr: multiPool?.apr ? multiPool?.apr : "---",
      assetSymbols: ["STG", "USDC"],
    },
  ];
  const defaultPoolInfo = tranches[0];
  const [poolInfo, setPoolInfo] = useState(undefined);
  const getPoolInfo = () => {
    return poolInfo ? poolInfo : defaultPoolInfo;
  };
  // let [poolInfo, setPoolInfo] = useState(tranches[0]);
  // useEffect(() => {
  //   setPoolInfo(tranches[trancheIndex]);
  // }, [snrLBPPoolData]);

  const { data: claimableRewards } = useSWR(
    active && [active, chainId, getContract(chainId, getPoolInfo().contractName), "claimableRewards"],
    {
      fetcher: contractFetcher(signer, LiquidityBootstrapping, [account]),
    }
  );
  const { data: userInfoData } = useSWR(
    active && [active, chainId, getContract(chainId, getPoolInfo().contractName), "userInfo"],
    {
      fetcher: contractFetcher(signer, LiquidityBootstrapping, [account]),
    }
  );

  const userinfo = getUserInfoData(userInfoData);

  const claimableDIP = formatAmount(claimableRewards ? claimableRewards : "0", 18);
  const leftCells = [
    { title: "Claimed Rewards", value: `${claimableDIP}`, unit: "DIP" },
    {
      title: "Your Deposit",
      value: userinfo?.amount,
      unit: getPoolInfo().lpToken.symbol,
    },
    // { title: "Total Rewards", value: `${claimableDIP + userinfo?.rewardDebt} DIP` },
    { split: true },
    { title: "LP Price", value: `$${getPoolInfo().price}` },
    { title: "Earn per day", value: getPoolInfo().perDay, unit: "DIP/Day" },
  ];
  const rightCells = [
    // { title: "Slippage", value: "0.3%" },
    // { title: "Minimum Received", value: "-- Senior LP" },
    // { title: "Weight/Target", value: "--%/--%" },
    // { title: "Fees", value: "0.21%" },
  ];
  const rowDefaultClass = "flex aligns-center justify-between";
  const goBack = () => {
    history.goBack();
  };
  const [stakeTokenInfo, setStakeTokenInfo] = useState();

  const [state, setState] = useState({ tabIndex: 1 });

  const switchPool = (index) => {
    setTrancheIndex(index);
    setPoolInfo(tranches[index]);
  };

  const clickTab = (index) => {
    setState({
      tabIndex: index,
    });
  };
  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const getButtonText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    if (isApproving) {
      return "Approving";
    }
    if (isStaking) {
      return "Depositing";
    }
    if (isWithdrawing) {
      return "Withdrawing";
    }

    return state.tabIndex === 1 ? "Deposit" : "Withdraw";
  };
  const withdraw = () => {
    if (active) {
      setIsWithdrawing(true);
      const lbpContractAddress = getContract(chainId, getPoolInfo().contractName);
      const contract = new ethers.Contract(lbpContractAddress, LiquidityBootstrapping.abi, signer);
      callContract(chainId, contract, "withdraw", [account, false], {
        sentMsg: `Withdraw submitted!`,
        failMsg: `Withdraw failed.`,
        // setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsWithdrawing(false);
        });
    } else {
      openConnectModal();
    }
  };
  const deposit = async () => {
    if (active && stakeTokenInfo) {
      const { token, amount } = stakeTokenInfo;
      const { decimals } = token;
      const lbpContractAddress = getContract(chainId, getPoolInfo().contractName);
      if (!token.isNative) {
        const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        const allowanceAmount = await tokenContract.allowance(account, lbpContractAddress);
        if (parseFloat(formatAmount(allowanceAmount, decimals, 4)) < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: lbpContractAddress,
            chainId,
          });
          return;
        }
      }

      setIsStaking(true);

      const contract = new ethers.Contract(lbpContractAddress, LiquidityBootstrapping.abi, signer);
      let methodName = "";
      let params = [];
      let value = undefined;
      if (token.isNative) {
        methodName = "depositETH";
        params = [0, account];
        value = expandOfStringDecimals(amount, token.decimals);
      } else {
        methodName = "deposit";
        params = [token.address, expandOfStringDecimals(amount, token.decimals), 0, account];
      }

      callContract(chainId, contract, methodName, params, {
        sentMsg: `Deposit submitted!`,
        failMsg: `Deposit failed.`,
        value: value,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };
  const claim = async () => {
    callContract(
      chainId,
      new ethers.Contract(
        getContract(chainId, getPoolInfo().contractName),
        LiquidityBootstrapping.abi,
        signer
      ),
      "claimRewards",
      [account],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        // setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };
  return (
    <div className="default-container mt-40">
      <div onClick={goBack} className="Earn-back">
        {"< Back"}
      </div>
      {/* <div className="row">
        {tranches.map((item, index) => (
          <div
            className="mt-20 col-4"
            key={index}
            onClick={() => {
              switchPool(index);
            }}
          >
            <LiquidityTab data={{ ...item, active: trancheIndex == index }}></LiquidityTab>
          </div>
        ))}
      </div> */}
      <div className="row mt-20">
        <div className="col-12 col-md-8 order-md-1 order-1">
          <div className="order-1">
            <div className={cx("Earn-detail-left-header", rowDefaultClass)}>
              <div>{getPoolInfo().title}</div>
              <div className="default-btn" onClick={claim}>
                Claim
              </div>
            </div>
            <div className="Earn-detail-left-content">
              {leftCells.map((item, index) =>
                item.split ? (
                  <div className="Liquidity-card-split" key={index}></div>
                ) : (
                  <div className={cx(rowDefaultClass, { "mt-15": index > 0 })} key={index}>
                    <div className="Earn-detail-left-subtitle">{item.title}</div>
                    <div className="Earn-detail-left-value flex aligns-center">
                      <div>{item.value}</div>
                      {item.unit && <div className="Earn-detail-left-subtitle ml-5">{item.unit}</div>}
                    </div>
                  </div>
                )
              )}
              <div className={cx(rowDefaultClass, "mt-10")}>
                <div className="Earn-detail-left-subtitle">APR</div>
                <div className="Earn-detail-left-value">{getPoolInfo().apr}%</div>
              </div>
              <div className={cx(rowDefaultClass, "mt-10")}>
                <div className="Earn-detail-left-subtitle">Total deposit</div>
                <div className="Earn-detail-left-value flex aligns-center">
                  <div> {getPoolInfo().management} </div>
                  <div className="Earn-detail-left-subtitle ml-5"> {getPoolInfo().lpToken.symbol}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 order-0 order-md-2">
          <div className="Ear-detail-right order-2">
            <div className="flex">
              <div
                className={cx("flex-1 Earn-detail-right-tab", {
                  "up-color ": state.tabIndex === 1,
                  "Earn-detail-right-tab-unactive": state.tabIndex !== 1,
                })}
                onClick={() => clickTab(1)}
              >
                Deposit
              </div>
              <div
                className={cx("flex-1 Earn-detail-right-tab", {
                  "down-color": state.tabIndex === 2,
                  "Earn-detail-right-tab-unactive": state.tabIndex !== 2,
                })}
                onClick={() => clickTab(2)}
              >
                Withdraw
              </div>
            </div>
            {state.tabIndex === 1 && (
              <div>
                <CurrencySelect setStakeTokenInfo={setStakeTokenInfo} customerToken={getPoolInfo().assetSymbols} />

                <div className="Earn-detail-right-cell">
                  {rightCells.map((item, index) =>
                    item.split ? (
                      <div className="Liquidity-card-split"></div>
                    ) : (
                      <div className={cx(rowDefaultClass, { "mt-10": index > 0 })} key={index}>
                        <div className="Earn-detail-left-subtitle">{item.title}</div>
                        <div className="Earn-detail-left-value">{item.value}</div>
                      </div>
                    )
                  )}
                  <div className="Exchange-swap-button-container mt-20">
                    <Button variant="primary-action" className="w-100" onClick={deposit}>
                      {getButtonText()}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {state.tabIndex === 2 && (
              <div>
                <div className="Earn-detail-right-buy">
                  <div className="flex justify-between aligns-center">
                    <div className="Earn-detail-left-subtitle">AMOUNT</div>
                    <div className="Currency-selected-group flex justify-around aligns-center">
                      <div className="mr-10">{userinfo?.amount}</div>
                      {/* {getPoolInfo()?.lpToken.imageUrl ? (
                        <img src={getPoolInfo()?.lpToken.imageUrl} className="icon-xs ml-5" alt="more"></img>
                      ) : (
                        {}
                      )} */}

                      <div className="ml-5 Earn-detail-left-subtitle "> {getPoolInfo()?.lpToken?.symbol} </div>
                    </div>
                  </div>
                </div>
                <div className="Earn-detail-right-cell">
                  <div className="Earn-detail-left-subtitle">
                    Note: Withdrawal will automatically transfer the deposit balance to the corresponding [Earn]
                    Stargate Pool.
                  </div>
                  <div className="Exchange-swap-button-container mt-20">
                    <Button variant="primary-action" className="w-100" onClick={withdraw}>
                      {getButtonText()}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
