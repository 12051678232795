import React from "react";
import "./LiquidityCard.css";
import LiquidityCard from "./LiquidityCard";
import RiskTip from "./RiskTip";
import cx from "classnames";

export default function TrancheCard ({ data }) {
  const { icons, title, management, price, apr, perDay, risk } = data;
  const cellClass = "flex-col justify-center  flex-1";
  const subCells = [
    { title: "Price", value: price },
    { title: "Assets Under Management", value: `$${management}` },
    { title: "APR", value: `${apr}%` },
  ];
  return (
    <div>
      <div className="tranche-card-m">
        <LiquidityCard item={{ ...data, type: 1 }}></LiquidityCard>
      </div>
      <div className="tranche-card">
        <div className="Liquidity-card">
          {risk && <RiskTip type={risk}></RiskTip>}
          <div className="flex justify-between aligns-center">
            <div className={cellClass}>
              <div className="flex">
                {icons
                  ? icons.map((icon, index) => (
                    <img
                      key={index}
                      alt=""
                      src={icon}
                      className={cx("icon-sm", {
                        " mr-base": index === icons.length - 1,
                        "DefaultCard--ml10": index > 0,
                      })}
                    ></img>
                  ))
                  : ""}
                <div className="Liquidity-card-value">{title}</div>
              </div>
              <div className="Liquidity-card-subtitle mt-10">Earn {perDay} DIP per Day</div>
            </div>
            {subCells.map((item, index) => (
              <div className={cellClass} key={index}>
                <div className="Liquidity-card-subtitle">{item.title}</div>
                <div className="Liquidity-card-value mt-10">{item.value}</div>
              </div>
            ))}
            <div>
              <img src={require("img/ic_right.png")} alt="detail"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
