import { useEffect, useRef, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { toJpeg } from "html-to-image";
import cx from "classnames";
import { BiCopy } from "react-icons/bi";
import { RiFileDownloadLine } from "react-icons/ri";
import { FiTwitter } from "react-icons/fi";
import { useCopyToClipboard, useMedia } from "react-use";
import Modal from "../Modal/Modal";
import gmxLogo from "img/logo-withname-white.png";
import "./PositionShare.css";
import { QRCodeSVG } from "qrcode.react";
import { getRootShareApiUrl, getTwitterIntentURL } from "lib/legacy";
// import { useAffiliateCodes } from "domain/referrals";
import SpinningLoader from "../Common/SpinningLoader";
import useLoadImage from "lib/useLoadImage";
import shareBgImg from "img/position-share.png";
import { helperToast } from "lib/helperToast";
import { formatMoney } from "lib/numbers";
import downloadImage from "lib/downloadImage";
import { useReferralInfo } from "domain/referral";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 0.95, canvasWidth: 518, canvasHeight: 292, type: "image/jpeg" };

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref && ref.address) {
    url = url + `&ref=${ref.address}`;
  }
  return url;
}

function PositionShare({
  setIsPositionShareModalOpen,
  isPositionShareModalOpen,
  positionToShare,
  account,
  chainId,
  tokenPrice,
}) {
  // const userAffiliateCode = useAffiliateCodes(chainId, account);
  // const userAffiliateCode = { success: undefined, code: undefined };

  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImg);
  const positionRef = useRef();
  const referralInfo = useReferralInfo(chainId, account);
  const tweetLink = getTwitterIntentURL(
    `Trade $${positionToShare?.indexToken?.baseSymbol} on @DIP_Exchange, let's #BTFD!`,
    getShareURL(uploadedImageInfo, referralInfo)
  );

  useEffect(() => {
    (async function () {
      const element = positionRef.current;
      if (element && sharePositionBgImg && positionToShare) {
        // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
        // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
        const image = await toJpeg(element, config)
          .then(() => toJpeg(element, config))
          .then(() => toJpeg(element, config));
        try {
          const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
          setUploadedImageInfo(imageInfo);
        } catch {
          setUploadedImageInfo(null);
          setUploadedImageError(t`Image generation error, please refresh and try again.`);
        }
      }
    })();
    // }, [userAffiliateCode, sharePositionBgImg, positionToShare]);
  }, [sharePositionBgImg, positionToShare]);

  async function handleDownload() {
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  function handleCopy() {
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, referralInfo);
    copyToClipboard(url);
    helperToast.success(t`Link copied to clipboard.`);
  }
  return (
    <Modal
      className="position-share-modal"
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={t`Share Position`}
    >
      <PositionShareCard
        // userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        tokenPrice={tokenPrice}
        referralInfo={referralInfo}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}

      <div className="flex justify-around aligns-center mt-20">
        <div disabled={!uploadedImageInfo} onClick={handleCopy} className="share-button flex aligns-center">
          <BiCopy className="icon-xs mr-5" />
          <div>Copy</div>
        </div>
        <div disabled={!uploadedImageInfo} onClick={handleDownload} className="share-button flex aligns-center">
          <RiFileDownloadLine className="icon-xs mr-5" />
          <div>Download</div>
        </div>
        <div className={cx("tweet-link-container", "share-button", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link flex aligns-center", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            <FiTwitter className="icon-xs mr-5" />
            <div>Tweet</div>
          </a>
        </div>
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  tokenPrice,
  referralInfo,
}) {
  const isMobile = useMedia("(max-width: 400px)");
  // const { code, success } = userAffiliateCode;
  const { netValuePercent, isLong, leverage, indexToken } = position;
  const markPrice = tokenPrice[indexToken.baseSymbol].price;

  // const homeURL = getAppBaseUrl();
  const homeURL = "https://app.dip.exchange";
  return (
    <div className="relative">
      <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
        {/* <div className="flex justify-between info">
          <div className="flex info">
            <div className={isLong ? "up-color fw-bold" : "down-color fw-bold"}>
              {isLong ? "LONG" : "SHORT"}&nbsp;
              {leverage.toFixed(2)}x&nbsp;
            </div>
            <div className="fw-bold">{indexToken.symbol}/USD</div>
          </div>
          <div>
            <img className="logo" src={gmxLogo} alt="DIP Logo" />
          </div>
        </div> */}
        <img className="logo" src={gmxLogo} alt="DIP Logo" />

        <ul className="info">
          <li className={isLong ? "up-color fw-bold" : "down-color fw-bold"}>
            {isLong ? "LONG" : "SHORT"}&nbsp;
            {leverage.toFixed(2)}x&nbsp;
          </li>
          <li className="fw-bold">{indexToken.baseSymbol}/USD</li>
        </ul>
        <h3 className={cx("pnl", { "up-color": netValuePercent > 0, "down-color": netValuePercent < 0 })}>
          {netValuePercent >= 0 ? "+" : ""}
          {(netValuePercent * 100).toFixed(2)}%
        </h3>
        <div className="prices">
          <div className="mr-10">
            <p>Entry Price</p>
            <p className="price">${formatMoney(position?.entryPrice, position?.indexToken?.displayDecimals)}</p>
          </div>
          <div>
            <p>Mark Price</p>
            <p className="price">${formatMoney(markPrice, position?.indexToken?.displayDecimals)}</p>
          </div>
        </div>
        <div className="referral-code">
          <div>
            <QRCodeSVG
              size={isMobile ? 24 : 32}
              value={referralInfo?.referralLink ? referralInfo?.referralLink : `${homeURL}`}
            />
          </div>
          {/* <div className="referral-code-info">
            {success && code ? (
              <>
                <p className="label">Referral Code:</p>
                <p className="code">{code}</p>
              </>
            ) : (
              <p className="code">{homeURL}</p>
            )}
          </div> */}
        </div>
      </div>
      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <Trans>Generating shareable image...</Trans>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionShare;
