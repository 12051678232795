import React, { useState } from "react";
import "../Earn/Detail.css";
import cx from "classnames";
import Button from "components/Button/Button";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { approveTokens } from "domain/tokens";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import DIPStakingAbi from "abis/DIPStaking.json";
import CurrencySelect from "components/Currency/CurrencySelect";
import { ethers } from "ethers";
import ERC20 from "abis/ERC20.json";
import { formatAmount, expandOfStringDecimals, formatMoney } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import { useDipStakingInfo } from "domain/pool";
import ClaimModal from "./ClaimModal";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";


export default function DIPStaking ({ setPendingTxns }) {
  const rewardTokenName = "SnrDLP";
  const stakeTokenName = "DIP";

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const { chainId } = useChainId();
  const [showModal, setShowModal] = useState(false);
  const dipStakingAddr = getContract(chainId, "DIPStaking");
  const lpToken = getTokenBySymbol(chainId, "SnrDLP");
  const stakeToken = getTokenBySymbol(chainId, "DIP");

  const { totalDepositAmount, totalDepositValue, apr, perDay, dlpPrice, dipPairPrice } = useDipStakingInfo(
    chainId,
    signer
  );
  const { data: claimableRewards } = useSWR(active && [active, chainId, dipStakingAddr, "pendingRewards", account], {
    fetcher: contractFetcher(signer, DIPStakingAbi),
  });
  const { data: userInfoData } = useSWR(active && [active, chainId, dipStakingAddr, "userInfo", account], {
    fetcher: contractFetcher(signer, DIPStakingAbi),
  });
  const userInfo = {
    amount: formatAmount(userInfoData?.amount, 18, 4, true),
    // rewardDebt: formatAmount(userInfoData?.rewardDebt, 18, 4, true),
  };

  const leftCells = [
    {
      title: "Claimable Rewards",
      value: `${formatAmount(claimableRewards ? claimableRewards : "0", 18, 4, true)}`,
      unit: rewardTokenName,
    },
    {
      title: "Your Deposit",
      value: userInfo?.amount,
      unit: stakeTokenName,
    },
    { split: true },
    {
      title: "Earn per day",
      value: formatMoney(perDay, 4),
      unit: rewardTokenName,
    },
  ];
  const rowDefaultClass = "flex aligns-center justify-between";

  const [stakeTokenInfo, setStakeTokenInfo] = useState();

  const [state, setState] = useState({ tabIndex: 1 });

  const clickTab = (index) => {
    setState({
      tabIndex: index,
    });
  };

  const withDrawMax = () => {
    setWithdrawAmount(userInfo?.amount);
  };

  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const rightCells = [{ title: "Deposit Fee", value: "0.4%" }];
  const getButtonText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    if (isApproving) {
      return "Approving";
    }
    if (isStaking) {
      return "Depositing";
    }
    if (isWithdrawing) {
      return "Withdrawing";
    }
    return state.tabIndex === 1 ? "Deposit" : "Withdraw";
  };
  const [withdrawAmount, setWithdrawAmount] = useState();
  const withdrawHandleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setWithdrawAmount(value);
    }
  };
  const withdraw = () => {
    if (active) {
      setIsWithdrawing(true);
      const contract = new ethers.Contract(dipStakingAddr, DIPStakingAbi.abi, signer);
      callContract(chainId, contract, "unstake", [account, expandOfStringDecimals(withdrawAmount, lpToken.decimals)], {
        sentMsg: `Withdraw submitted!`,
        failMsg: `Withdraw failed.`,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsWithdrawing(false);
        });
    } else {
      openConnectModal();
    }
  };

  const deposit = async () => {
    if (active && stakeTokenInfo) {
      const { token, amount } = stakeTokenInfo;
      const { decimals } = token;
      if (!token.isNative) {
        const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        const allowanceAmount = await tokenContract.allowance(account, dipStakingAddr);
        if (formatAmount(allowanceAmount, decimals, decimals) < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: dipStakingAddr,
            chainId,
          });
          return;
        }
      }

      setIsStaking(true);

      const contract = new ethers.Contract(dipStakingAddr, DIPStakingAbi.abi, signer);

      // return;
      callContract(chainId, contract, "stake", [account, expandOfStringDecimals(amount, token.decimals)], {
        sentMsg: `Deposit submitted!`,
        failMsg: `Deposit failed.`,
        // setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    // <div className="default-bg default-container page-layout">
    <div>
      {/* <div className="Earn-header-bg">
        <div className="Earn-header   flex-col justify-center">
          <div className="Earn-header-title ">Staking</div>
          <div className="">Stake DIP to earn protocol revenue.</div>
        </div>
      </div> */}
      <div className="">
        <div className="row">
          <div className="col-12 col-md-7 order-md-1 order-1">
            <div className="order-1">
              <div className={cx("Earn-detail-left-header", rowDefaultClass)}>
                <div className="flex aligns-center">
                  <img src={require("img/DIP-token.png")} alt="DIP" className="icon-sm"></img>
                  <div className="ml-10">DIP Staking</div>
                </div>
                <div
                  className="default-btn"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Claim
                </div>
              </div>
              <div className="Earn-detail-left-content">
                {leftCells.map((item, index) =>
                  item.split ? (
                    <div className="Liquidity-card-split mt-10" key={index}></div>
                  ) : (
                    <div className={cx(rowDefaultClass, { "mt-15": index > 0 })} key={index}>
                      <div className="Earn-detail-left-subtitle">{item.title}</div>
                      <div className="Earn-detail-left-value flex aligns-center">
                        <div>{item.value}</div>
                        {item.unit && <div className="Earn-detail-left-subtitle ml-5">{item.unit}</div>}
                      </div>
                    </div>
                  )
                )}
                <div className={cx(rowDefaultClass, "mt-10")}>
                  <div className="Earn-detail-left-subtitle">Estimated APR</div>
                  <div className="Earn-detail-left-value">{apr ? formatMoney(apr, 2) : "---"}%</div>
                </div>
                <div className={cx("flex justify-between  mt-20")}>
                  <div className="Earn-detail-left-subtitle">Total Deposited</div>

                  <div className="text-right">
                    <div className="Earn-detail-left-value flex aligns-center justify-content-end">
                      <div> {totalDepositAmount || "---"} </div>
                      <div className="Earn-detail-left-subtitle ml-5"> {stakeTokenName}</div>
                    </div>
                    <div className="Earn-detail-left-sub-value">
                      ${totalDepositValue ? formatMoney(totalDepositValue, 2) : "---"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 order-0 order-md-2">
            <div className="Ear-detail-right order-2">
              <div className="flex">
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "up-color ": state.tabIndex === 1,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 1,
                  })}
                  onClick={() => clickTab(1)}
                >
                  Deposit
                </div>
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "down-color": state.tabIndex === 2,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 2,
                  })}
                  onClick={() => clickTab(2)}
                >
                  Withdraw
                </div>
              </div>
              {state.tabIndex === 1 && (
                <div>
                  <CurrencySelect
                    setStakeTokenInfo={setStakeTokenInfo}
                    hideList={true}
                    customerToken={[stakeTokenName]}
                  />
                  {/* {stakeTokenInfo?.amount > 0 && (
                    <div className="Exchange-modal-sub-title mt-10 ml-10">
                      ≈ ${formatMoney(dipPairPrice?.dipPrice * stakeTokenInfo?.amount, 4)}
                    </div>
                  )} */}
                  <div className="Earn-detail-right-cell">
                    {rightCells.map((item, index) =>
                      item.split ? (
                        <div className="Liquidity-card-split"></div>
                      ) : (
                        <div className={cx(rowDefaultClass, { "mt-10": index > 0 })} key={index}>
                          <div className="Earn-detail-left-subtitle">{item.title}</div>
                          <div className="Earn-detail-left-value">{item.value}</div>
                        </div>
                      )
                    )}
                    <div className="Exchange-swap-button-container mt-20">
                      <Button variant="primary-action" className="w-100" onClick={deposit}>
                        {getButtonText()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {state.tabIndex === 2 && (
                <div>
                  <div className="Earn-detail-right-buy">
                    <div className="flex justify-between">
                      <div className="Earn-detail-left-subtitle">Staking</div>
                      <div>
                        {userInfo?.amount}
                        <span className="Earn-detail-left-subtitle ml-5">{stakeTokenName}</span>
                      </div>
                    </div>
                    <div className="Earn-detail-right-buy-input mt-10">
                      <div className="flex justify-between aligns-center">
                        <input value={withdrawAmount} type="text" onChange={withdrawHandleChange} className="flex-1" />
                        <div className="Currency-selected-max mr-10" onClick={withDrawMax}>
                          MAX
                        </div>
                        <div className="Currency-selected-group flex justify-around aligns-center mr-10">
                          {stakeToken?.imageUrl && (
                            <img src={stakeToken?.imageUrl} className="icon-xs " alt={stakeToken?.symbol}></img>
                          )}
                          <div className="ml-5"> {stakeToken?.symbol} </div>
                        </div>
                      </div>
                    </div>
                    {withdrawAmount > 0 && (
                      <div className="Exchange-modal-sub-title mt-10 ml-10">
                        ≈ ${formatMoney(dipPairPrice?.dipPrice * withdrawAmount, 4)}
                      </div>
                    )}
                  </div>
                  <div className="Earn-detail-right-cell">
                    {/* <div className="Earn-detail-left-subtitle">
                    Note: Withdrawal will automatically transfer the deposit balance to the corresponding Tranche Pool.
                  </div> */}
                    <div className="Exchange-swap-button-container mt-20">
                      <Button variant="primary-action" className="w-100" onClick={withdraw}>
                        {getButtonText()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ClaimModal
          chainId={chainId}
          signer={signer}
          account={account}
          showModal={showModal}
          claimable={claimableRewards}
          closeModal={closeModal}
          setPendingTxns={setPendingTxns}
          dipStakingAddr={dipStakingAddr}
          DIPStakingAbi={DIPStakingAbi}
          DLPToken={lpToken}
          dlpPrice={dlpPrice}
        />
      )}
      {/* <Footer /> */}
    </div>
  );
}
