import cx from "classnames";

export const ExchangeModalCell = ({ cells }) => {
  return (
    <div className="mt-10">
      {cells.map((item, index) =>
        item.split ? (
          <div className="Liquidity-card-split mb-20 mt-20" key={index}></div>
        ) : (
          item.value && (
            <div className={cx("flex justify-between aligns-center ", "mb-10")} key={index}>
              <div className="Earn-detail-left-subtitle">{item.title}</div>
              <div className="Earn-detail-left-value flex aligns-center">
                {item.rightIcon && <img src={item.rightIcon} alt={item.value} className="icon-xs mr-5"></img>}
                {item.changed && (
                  <div className="mr-10 Earn-detail-left-subtitle">
                    {item.beforeValue}
                    <span className="ml-10">→</span>
                  </div>
                )}
                <div className={[`valueClass ${item.valueClass ? item.valueClass : ""}`]}> {item.value}</div>
              </div>
            </div>
          )
        )
      )}
    </div>
  );
};
