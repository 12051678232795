import "./DefaultCard.css";
import cx from "classnames";

export default function Card({ data }) {
  return (
    <div className=" DefaultCard">
      <div className="DefaultCard-title">
        <div className="flex justify-between aligns-center">
          <div className="flex aligns-center">
            {data.icons &&
              data.icons.map((item, iconIndex) => (
                <img
                  src={item}
                  className={cx("icon-xs mr-10", {
                    "DefaultCard--ml10": iconIndex > 0,
                    "DefaultCard-first-icon": iconIndex === 0,
                    " mr-10": iconIndex === data.icons.length - 1,
                  })}
                  key={iconIndex}
                  alt=""
                ></img>
              ))}
            <div>{data.title}</div>
          </div>
          <div>{data.rightTitle}</div>
        </div>
      </div>
      <div className="DefaultCard-content">
        {data.cells &&
          data.cells.map((cell, index) => (
            <div key={index}>
              <div className={cx("flex justify-between aligns-center", { " mt-20": index > 0 })}>
                <div className="flex aligns-center">
                  {cell.icons &&
                    cell.icons.map((icon, iconIndex) => (
                      <img
                        src={icon}
                        className={cx("icon-sm", {
                          "DefaultCard--ml10": iconIndex > 0,
                          "DefaultCard-first-icon": iconIndex === 0,
                          " mr-10": iconIndex === cell.icons.length - 1,
                        })}
                        key={iconIndex}
                        alt=""
                      ></img>
                    ))}
                  <div className="DefaultCard-cell-subTitle"> {cell.left}</div>
                </div>
                <div className="align-right">
                  <div className={cx({ "DefaultCard-cell-subRight": cell.subRight })}>
                    <div>{cell.right}</div>
                    {cell.subRight && <div className="DefaultCard-cell-subTitle">{cell.subRight}</div>}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
