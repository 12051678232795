import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Detail.css";
import LiquidityTab from "components/LiquidityCard/LiquidityTab";
import cx from "classnames";
import Button from "components/Button/Button";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { approveTokens } from "domain/tokens";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import LPLiquidityBootstrapping from "abis/LPLiquidityBootstrapping.json";
import DIPZapForLBP from "abis/DIPZapForLBP.json";
import { getPoolData, getUserInfoData } from "./services";
import CurrencySelect from "components/Currency/CurrencySelect";
import { ethers } from "ethers";
import ERC20 from "abis/ERC20.json";
import { formatAmount, expandOfStringDecimals, formatMoney } from "lib/numbers";
import { userInfo } from "os";
import { getTokenBySymbol } from "config/tokens";
import { useDipPairPrice } from "domain/prices";
import ErrorBanner from "components/Banner/ErrorBanner";
import BackButton from "components/Button/BackButton";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";

export default function EarnDetail ({ setPendingTxns }) {

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();

  const { chainId } = useChainId();
  const lbpContractsName = "DIP_ETHLBP";
  const tokenName = "DIP/ETH LP";

  const [customerToken, setCustomerToken] = useState();
  const dipPairPrice = useDipPairPrice(chainId, signer);

  const { data: dipFtmLBPPoolData } = useSWR(
    active && [active, chainId, getContract(chainId, lbpContractsName), "info"],
    {
      fetcher: contractFetcher(signer, LPLiquidityBootstrapping),
    }
  );
  const dipFtmLBPPool = getPoolData(dipFtmLBPPoolData, true, 3, dipPairPrice);
  const history = useHistory();

  const { data: claimableRewards } = useSWR(
    active && [active, chainId, getContract(chainId, lbpContractsName), "claimableRewards"],
    {
      fetcher: contractFetcher(signer, LPLiquidityBootstrapping, [account]),
    }
  );
  const { data: userInfoData } = useSWR(
    active && [active, chainId, getContract(chainId, lbpContractsName), "userInfo"],
    {
      fetcher: contractFetcher(signer, LPLiquidityBootstrapping, [account]),
    }
  );

  const userinfo = getUserInfoData(userInfoData);
  const claimableDIP = formatAmount(claimableRewards ? claimableRewards : "0", 18);
  const leftCells = [
    { title: "Claimable Rewards", value: `${claimableDIP}`, unit: "DIP" },
    {
      title: "Your Deposit",
      value: userinfo?.amount ? formatMoney(userinfo?.amount, 4) : "---",
      unit: tokenName,
    },
    // { title: "Total Rewards", value: `${claimableDIP + userinfo?.rewardDebt} DIP` },
    { split: true },
    // { title: "LP Price", value: `$${dipFtmLBPPool?.price}` },
    { title: "Earn per day", value: dipFtmLBPPool?.perDay, unit: "DIP/Day" },
  ];
  const rightCells = [
    // { title: "Slippage", value: "0.3%" },
    // { title: "Minimum Received", value: "-- Senior LP" },
    // { title: "Weight/Target", value: "--%/--%" },
    // { title: "Fees", value: "0.21%" },
  ];
  const rowDefaultClass = "flex aligns-center justify-between";
  const goBack = () => {
    history.goBack();
  };
  const [stakeTokenInfo, setStakeTokenInfo] = useState();

  const [state, setState] = useState({ tabIndex: 1 });

  const clickTab = (index) => {
    setState({
      tabIndex: index,
    });
  };

  const [useFTMOnly, setUseFTMOnly] = useState(true);
  const [hideMax, setHideMax] = useState(false);
  const switchFTMOnly = () => {
    if (useFTMOnly) {
      setCustomerToken([tokenName]);
      setHideMax(true);
    } else {
      setCustomerToken(undefined);
    }
    setUseFTMOnly(!useFTMOnly);
  };
  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const getButtonText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    if (isApproving) {
      return "Approving";
    }
    if (isStaking) {
      return "Depositing";
    }

    return state.tabIndex === 1 ? "Deposit" : "Withdraw";
  };
  const withdraw = () => {
    if (active) {
      setIsWithdrawing(true);
      const lbpContractAddress = getContract(chainId, lbpContractsName);
      const contract = new ethers.Contract(lbpContractAddress, LPLiquidityBootstrapping.abi, signer);
      callContract(chainId, contract, "withdraw", [account, false], {
        sentMsg: `Withdraw submitted!`,
        failMsg: `Withdraw failed.`,
        setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsWithdrawing(false);
        });
    } else {
      openConnectModal();
    }
  };
  const zap = async () => {
    const { token, amount } = stakeTokenInfo;
    const address = getContract(chainId, "LBPZap");
    const contract = new ethers.Contract(address, DIPZapForLBP.abi, signer);
    callContract(chainId, contract, "zap", [0, 0, true], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed.`,
      value: expandOfStringDecimals(amount, token.decimals),
      setPendingTxns,
    })
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };
  const deposit = async () => {
    if (active && stakeTokenInfo) {
      if (useFTMOnly) {
        await zap();
        return;
      }
      const { token, amount } = stakeTokenInfo;
      const { decimals } = token;
      const lbpContractAddress = getContract(chainId, lbpContractsName);
      if (!token.isNative) {
        const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        const allowanceAmount = await tokenContract.allowance(account, lbpContractAddress);
        if (formatAmount(allowanceAmount, decimals, decimals) < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: lbpContractAddress,
            chainId,
          });
          return;
        }
      }

      setIsStaking(true);

      const contract = new ethers.Contract(lbpContractAddress, LPLiquidityBootstrapping.abi, signer);

      // return;
      callContract(
        chainId,
        contract,
        "deposit",
        [token.address, expandOfStringDecimals(amount, token.decimals), account],
        {
          sentMsg: `Deposit submitted!`,
          failMsg: `Deposit failed.`,
          // setPendingTxns,
        }
      )
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };
  const claim = async () => {
    callContract(
      chainId,
      new ethers.Contract(getContract(chainId, lbpContractsName), LPLiquidityBootstrapping.abi, signer),
      "claimRewards",
      [account],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        // setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };
  return (
    <div className="default-bg">
      <div className="default-container pt-40">
        <BackButton></BackButton>
        <ErrorBanner
          content="Note that your deposit will be locked in LBP and unlocked on 2023-09-26 2pm UTC. DIP will start rewarding on 2023-08-29 2pm UTC"
          path="/liquiditybootstrapping"
        />
        <div className="row mt-20">
          <div className="col-12 col-md-8 order-md-1 order-1">
            <div className="order-1">
              <div className={cx("Earn-detail-left-header", rowDefaultClass)}>
                <div className="flex  aligns-center">
                  <div>
                    <img alt="" src={require("img/DIP.png")} className="icon-sm"></img>
                    <img
                      alt=""
                      src={require("img/ETH.png")}
                      className="icon-sm  mr-base DefaultCard--ml10"
                    ></img>
                  </div>
                  <div>{tokenName} Pool</div>
                </div>
                <div className="default-btn" onClick={claim}>
                  Claim
                </div>
              </div>
              <div className="Earn-detail-left-content">
                {leftCells.map((item, index) =>
                  item.split ? (
                    <div className="Liquidity-card-split" key={index}></div>
                  ) : (
                    <div className={cx(rowDefaultClass, { "mt-20": index > 0 })} key={index}>
                      <div className="Earn-detail-left-subtitle">{item.title}</div>
                      <div className="Earn-detail-left-value flex aligns-center">
                        <div>{item.value}</div>
                        {item.unit && <div className="Earn-detail-left-subtitle ml-5">{item.unit}</div>}
                      </div>
                    </div>
                  )
                )}
                <div className={cx(rowDefaultClass, "mt-20")}>
                  <div className="Earn-detail-left-subtitle">APR</div>
                  <div className="Earn-detail-left-value">
                    {dipFtmLBPPool?.apr ? formatMoney(dipFtmLBPPool?.apr, 2) : "---"}%
                  </div>
                </div>
                <div className={cx("flex justify-between  mt-20")}>
                  <div className="Earn-detail-left-subtitle">Total Deposit</div>
                  <div className="text-right">
                    <div className="Earn-detail-left-value flex aligns-center">
                      <div> {dipFtmLBPPool?.totalValue ? formatMoney(dipFtmLBPPool?.totalValue, 4) : "---"} </div>
                      <div className="Earn-detail-left-subtitle ml-5"> {tokenName}</div>
                    </div>
                    <div className="Earn-detail-left-sub-value">${formatMoney(dipFtmLBPPool?.totalAmount, 2)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-2">
            <div className="Ear-detail-right order-2">
              <div className="flex">
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "up-color ": state.tabIndex === 1,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 1,
                  })}
                  onClick={() => clickTab(1)}
                >
                  Deposit
                </div>
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "down-color": state.tabIndex === 2,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 2,
                  })}
                  onClick={() => clickTab(2)}
                >
                  Withdraw
                </div>
              </div>
              {state.tabIndex === 1 && (
                <div>
                  <div className="Earn-detail-right-cell flex aligns-center  justify-content-between mt-20">
                    <div>Use ETH Only</div>
                    <div className="form-check form-switch ml-10" onClick={switchFTMOnly}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={useFTMOnly}
                      />
                    </div>
                  </div>
                  <CurrencySelect
                    setStakeTokenInfo={setStakeTokenInfo}
                    hideList={true}
                    customerToken={customerToken}
                    hideMax={hideMax}
                  />
                  <div className="Earn-detail-right-cell">
                    {rightCells.map((item, index) =>
                      item.split ? (
                        <div className="Liquidity-card-split"></div>
                      ) : (
                        <div className={cx(rowDefaultClass, { "mt-20": index > 0 })} key={index}>
                          <div className="Earn-detail-left-subtitle">{item.title}</div>
                          <div className="Earn-detail-left-value">{item.value}</div>
                        </div>
                      )
                    )}
                    <div className="Exchange-swap-button-container mt-20">
                      <Button variant="primary-action" className="w-100" onClick={deposit}>
                        {getButtonText()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {state.tabIndex === 2 && (
                <div>
                  <div className="Earn-detail-right-buy">
                    <div className="flex justify-between">
                      <div className="Earn-detail-left-subtitle">AMOUNT</div>
                      <div>
                        {userinfo?.amount}
                        <span className="Earn-detail-left-subtitle ml-5">{tokenName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="Earn-detail-right-cell">
                    <div className="Earn-detail-left-subtitle">
                      Note: Withdrawal will automatically transfer the deposit balance to the corresponding [Earn]
                      Tranche Pool.
                    </div>
                    <div className="Exchange-swap-button-container mt-20">
                      <Button variant="primary-action" className="w-100" onClick={withdraw}>
                        {getButtonText()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
