import { formatAmount, formatMoney } from "lib/numbers";
import { useState, useEffect } from "react";
import CurrencyDefault from "components/Currency/CurrencyDefault";
import cx from "classnames";
import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import EXP from "abis/EXP.json";
import { getTokenBySymbol } from "config/tokens";
import { formatUTCTime } from "lib/dates";
import { useExpBatchData } from "domain/trading";
import LeftPageIcon from "img/ic_left_page.png";
import UnActiveLeftPageIcon from "img/ic_left_page_unactive.png";
import RightPageIcon from "img/ic_right_page.png";
import UnActiveRightPageIcon from "img/ic_right_page_unactive.png";
import { getContract } from "config/contracts";
import { ethers, BigNumber } from "ethers";
import { defaultTokenDecimals } from "lib/pool";
import "./TradingMining.css"
import ERC20 from "abis/ERC20.json";

const TradingMining = ({ setPendingTxns }) => {
  const { chainId } = useChainId();
  const { signer, account, active } = useWallet();
  const [page, setPage] = useState(1);
  const historyData = useExpBatchData(chainId, account, page - 1);
  const exp = getTokenBySymbol(chainId, "EXP");

  const { data: currentBatchId } = useSWR([active, chainId, exp?.address, "currentBatchId"], {
    fetcher: contractFetcher(signer, EXP),
  });
  const startTime = 1700229600;

  const [batchs, setBatchs] = useState([]);
  const [batchIds, setBatchIds] = useState([]);
  useEffect(() => {
    if (!currentBatchId) return;
    console.log("currentBatchId", currentBatchId.toString())

    let batchs = []
    let ids = []
    for (let i = parseFloat(currentBatchId.toString()); i >= 0; i--) {
      batchs.push({
        batchStartTime: startTime + 86400 * i,
        batchId: i
      });
      ids.push(i);
    }
    setBatchIds(ids)
    setBatchs(batchs)
  }, [currentBatchId]);

  const { data: nextBatch } = useSWR(["EXP:nextBatch", chainId, exp?.address, "getNextBatch"], {
    fetcher: contractFetcher(signer, EXP),
  });
  const { data: claimableList } = useSWR(
    active && account && ["EXP:claimableList", chainId, exp?.address, "claimableMultiple", batchIds, account],
    {
      fetcher: contractFetcher(signer, EXP),
    }
  );
  console.log("claimableList", claimableList?.toString(), account)
  const { data: expBalance } = useSWR(
    [
      `EXP:Balance${active}`,
      chainId,
      exp?.address,
      "balanceOf",
      account,
    ],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const { data: totalSupply } = useSWR([active, chainId, exp?.address, "totalSupply"], {
    fetcher: contractFetcher(signer, EXP),
  });
  console.log("currentBatchId", currentBatchId)
  const clickPage = (type) => {
    let newPage;
    if (type === 0) {
      newPage = page > 1 ? page - 1 : 1;
    } else {
      newPage = historyData?.list?.length < 10 ? page : page + 1;
    }
    setPage(newPage);
  };
  const claim = async (batchId) => {
    callContract(
      chainId,
      new ethers.Contract(getContract(chainId, "EXP"), EXP.abi, signer),
      "claimRewards",
      [batchId, account],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        // setIsStaking(false);
      });
  };
  return (
    <div>
      <div className="pb-30">
        <div className="Earn-header-bg">
          <div className="Earn-header default-container flex-col justify-center">
            <div className="Earn-header-title ">Trade Mining Program</div>
            <div className="">Similar to how the liquidity providers are rewarded for being early, DIP will also incentivize traders during the first month through Trader Incentive Program. Traders are rewarded EXP per $1 fee paid which is automatically converted to DIP on a daily basis. The EXP/DIP rate is set daily at 2pm UTC.</div>
          </div>
        </div>
      </div>
      <div className="default-container">
        <div className="">
          <div className="flex justify-between ">
            <div className="Earn-detail-left-subtitle mt-10"></div>
            <a href="https://docs.dip.exchange/onchain-summer/trade-mining-program"> View Doc</a>
          </div>

        </div>
        <div className="row">
          <div className="col-md-6 trading-mining-left-bg col-12 ">
            <div className=" align-self-center">
              <div className="Earn-detail-left-subtitle mt-10">Next Batch</div>
              <div className="fs-2">
                {nextBatch && formatUTCTime(new Date(nextBatch?._nextEpochTimestamp?.toString() * 1000))} UTC
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="Testnet-rewards-card flex justify-between">
              <div className="Liquidity-card-subtitle">Your Balance</div>
              <div>{expBalance ? formatAmount(expBalance, defaultTokenDecimals, 6, true) : "0"} <span className="Liquidity-card-subtitle">EXP</span></div>
            </div>
            <div className="Testnet-rewards-card flex justify-between">
              <div className="Liquidity-card-subtitle">EXP Supply</div>
              <div>{totalSupply ? formatAmount(totalSupply, defaultTokenDecimals, 6, true) : "---"} <span className="Liquidity-card-subtitle">EXP</span></div>
            </div>
            <div className="Testnet-rewards-card flex justify-between">
              <div className="Liquidity-card-subtitle">Your Estimated Rewards</div>
              <div>{expBalance && totalSupply ? formatAmount(BigNumber.from("100000").mul(BigNumber.from(10).pow(defaultTokenDecimals)).div(totalSupply.lte("0") ? BigNumber.from("1") : totalSupply).mul(expBalance), defaultTokenDecimals, 6, true) : "0"} <span className="Liquidity-card-subtitle">DIP</span></div>
            </div>
            <div className="Testnet-rewards-card flex justify-between">
              <div className="Liquidity-card-subtitle">Allocation</div>
              <div>100,000.000000 <span className="Liquidity-card-subtitle">DIP</span></div>
            </div>
          </div>
        </div>

        {/* <div className="Liquidity-card-split mt-10" style={{ margin: "auto" }}></div> */}
        <div className="scroll-x">

          <table className="table table-dark table-hover table-striped align-middle" style={{ marginBottom: 0 }}>
            <thead className="Order-header">
              <tr>
                <th scope="col">Batch Ended</th>
                <th scope="col">Earned EXP</th>
                {/* <th scope="col">Rewards DIP</th> */}
                <th scope="col">Claimed DIP</th>
                {/* <th scope="col">Claimable DIP</th> */}
                <th scope="col">Action</th>
              </tr>
            </thead>

            {/* <tbody>
            {historyData?.list &&
              historyData?.list.map((item, index) => (
                <tr key={index}>
                  <td>{formatUTCTime(new Date(item.batchStartTime.toString() * 1000))}UTC</td>
                  <td>{item.amount < 0.01 ? "< 0.01" : formatMoney(item.amount, 4)}</td>
                  <td>
                    {item.perExpOfDip * item.amount < 0.01 ? "<0.01" : formatMoney(item.perExpOfDip * item.amount, 2)}
                  </td>
                  <td>
                    {claimableList ? formatAmount(claimableList[index].toString(), defaultTokenDecimals, 2, true) : ""}
                  </td>
                  <td
                    onClick={() => {
                      claim(item.batchId);
                    }}

                  >
                    <span className="Order-symbol">Claim</span>

                  </td>
                </tr>
              ))}
          </tbody> */}
            <tbody>
              {
                batchs.map((item, index) => (
                  <tr key={index}>
                    <td>{formatUTCTime(new Date(item.batchStartTime?.toString() * 1000))}UTC</td>
                    <td>{claimableList ? formatAmount(claimableList.multipleAmounts[index].toString(), defaultTokenDecimals, 6, true) : ""}</td>
                    {/* <td>
                    {item.perExpOfDip * item.amount < 0.01 ? "<0.01" : formatMoney(item.perExpOfDip * item.amount, 2)}
                  </td> */}
                    <td>
                      {claimableList ? formatAmount(claimableList.multipleRewards[index].toString(), defaultTokenDecimals, 4, true) : ""}
                    </td>
                    <td
                      onClick={() => {
                        if (claimableList && claimableList.multipleRewards[index].gt("0")) {
                          claim(item.batchId);
                        }
                      }}

                    >
                      <div className={claimableList && claimableList.multipleRewards[index].gt("0") ? "Order-symbol" : "Liquidity-card-subtitle"} >Claim</div>

                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-content-end mt-20 mr-20">
          <div className="flex aligns-center">
            <img
              alt=""
              src={page === 1 ? UnActiveLeftPageIcon : LeftPageIcon}
              style={{ height: "1.5rem", width: "auto", marginRight: "0.5rem" }}
              onClick={() => {
                clickPage(0);
              }}
              disabled={page === 1}
            ></img>
            Page {page}
            <img
              alt=""
              src={historyData?.list?.length < 10 ? UnActiveRightPageIcon : RightPageIcon}
              style={{ height: "1.5rem", width: "auto", marginLeft: "0.5rem" }}
              onClick={() => {
                clickPage(1);
              }}
              disabled={historyData?.list?.length < 10}
            ></img>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TradingMining;
