import React from "react";
import "./Earn.css";
import TrancheCard from "components/LiquidityCard/TrancheCard";
import TokenDistribution from "components/TokenDistribution";
import StakeCard from "components/LiquidityCard/StakeCard";
import DipIcon from "img/DIP-token.png";
import ETHIcon from "img/ETH.png";
import SnrIcon from "img/ic_snr.png";
import JnrIcon from "img/ic_jnr.png";
import MezzIcon from "img/ic_mezz.png";

import useWallet from "lib/wallets/useWallet";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { callContract, contractFetcher } from "lib/contracts";
import useSWR from "swr";
import ERC20 from "abis/ERC20.json";

import { getTokenBySymbol } from "config/tokens";
import { getPoolData } from "./services";
import { useHistory } from "react-router-dom";
import { helperToast } from "lib/helperToast";
import { isFantomTestnet } from "config/env";
import ErrorBanner from "components/Banner/ErrorBanner";
import { useDLPPrices } from "domain/pool";
import { formatMoney } from "lib/numbers";
import { useDipPairPrice } from "domain/prices";
import Footer from "components/Footer/Footer";

export default function Earn () {
  const { active, signer } = useWallet();
  const history = useHistory();
  const { chainId } = useChainId();
  const dipMasterAddr = getContract(chainId, "DIPMaster");

  // const { data: snrLBPPoolData } = useSWR(
  //   [`DIPMaster:info${active}`, chainId, getContract(chainId, "DIPMaster"), "pool"],
  //   {
  //     fetcher: contractFetcher(signer, DIPMaster),
  //   }
  // );

  const SnrDLP = getTokenBySymbol(chainId, "SnrDLP");
  const MezzDLP = getTokenBySymbol(chainId, "MezzDLP");
  const JnrDLP = getTokenBySymbol(chainId, "JnrDLP");

  const { data: snrManagementData } = useSWR(
    [`Earn:SnrBalance${active}`, chainId, SnrDLP.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const { data: mezzManagementData } = useSWR(
    [`Earn:MezzBalance${active}`, chainId, MezzDLP.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const { data: jnrManagementData } = useSWR(
    [`Earn:JnrBalance${active}`, chainId, JnrDLP.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const { data: lpManagementData } = useSWR(
    [
      `Earn:DIP/FTMLPBalance${active}`,
      chainId,
      getTokenBySymbol(chainId, "DIP/ETH LP").address,
      "balanceOf",
      dipMasterAddr,
    ],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const dlpPrices = useDLPPrices(chainId, signer);
  const pairPrice = useDipPairPrice(chainId, signer);
  const snrPool = getPoolData(snrManagementData, 0, dlpPrices?.snrDLPPrice, pairPrice?.dipPrice);
  const mezzPool = getPoolData(mezzManagementData, 1, dlpPrices?.mezzDLPPrice, pairPrice?.dipPrice);
  const jnrPool = getPoolData(jnrManagementData, 2, dlpPrices?.jnrDLPPrice, pairPrice?.dipPrice);
  const lpPool = getPoolData(lpManagementData, 3, pairPrice?.lpPrice, pairPrice?.dipPrice);
  const xPool = {};

  const xPools = [
    {
      title: "X Pool",
      risk: 3,
      perDay: xPool?.perDay,
      price: `$${xPool?.price ? xPool?.price : "---"}`,
      management: `${xPool?.totalValue ? xPool?.totalValue : "---"}`,
      apr: xPool?.apr ? xPool?.apr : "---",
      // icons: [require("img/ic_stg.png")],
    },
    // {
    //   title: "CRV Pool",
    //   risk: 3,
    //   perDay: innovationPool?.perDay,
    //   price: `$${innovationPool?.price ? innovationPool?.price : "---"}`,
    //   management: `$${innovationPool?.totalAmount ? innovationPool?.totalAmount : "---"}`,
    //   apr: innovationPool?.apr ? innovationPool?.apr : "---",
    //   icons: [CrvIcon],
    // },
  ];
  const tranches = [
    {
      title: "Senior    Tranche",
      risk: 1,
      perDay: "6,000",
      price: `$${snrPool?.lpPrice ? formatMoney(snrPool?.lpPrice, 4) : "---"}`,
      management: `${snrPool?.totalValue ? snrPool?.totalValue : "---"}`,
      // management: `$${formatAmount(SnrTrancheValue, 30, 2, true)}`,
      apr: snrPool?.apr ? formatMoney(snrPool?.apr, 2) : "---",
      icons: [SnrIcon],
    },
    {
      title: "Mezzanine Tranche",
      perDay: "6,000",

      price: `$${mezzPool?.lpPrice ? formatMoney(mezzPool?.lpPrice, 4) : "---"}`,
      management: `${mezzPool?.totalValue ? mezzPool?.totalValue : "---"}`,
      apr: mezzPool?.apr ? formatMoney(mezzPool?.apr, 2) : "---",
      icons: [MezzIcon],
    },
    {
      title: "Junior    Tranche",
      risk: 3,
      perDay: "12,000",

      price: `$${jnrPool?.lpPrice ? formatMoney(jnrPool?.lpPrice, 4) : "---"}`,
      management: `${jnrPool?.totalValue ? jnrPool.totalValue : "---"}`,
      apr: jnrPool?.apr ? formatMoney(jnrPool?.apr, 2) : "---",
      icons: [JnrIcon],
    },
  ];
  const liquiditys = [
    {
      title: "DIP/ETH",
      perDay: "12,000",
      price: "--",
      management: lpPool?.totalValue ? `${lpPool?.totalValue}` : "---",
      apr: lpPool?.apr ? formatMoney(lpPool?.apr, 2) : "---",
      icons: [DipIcon, ETHIcon],
    },
  ];
  const goToDetail = (path) => {
    if (isFantomTestnet(chainId)) {
      helperToast.error("Available on Mainnet");
      return;
    } else {
      history.push(path);
    }
  };
  return (
    <div className="default-bg  page-layout">
      <div className="pb-30">
        <div className="Earn-header-bg">
          <div className="Earn-header default-container flex-col justify-center">
            <div className="Earn-header-title ">LIQUIDITY</div>
            <div className="">Provide liquidity and start earning rewards .</div>
          </div>
        </div>

        <div className="default-container">
          {/* {!isFantomTestnet(chainId) && (
            <ErrorBanner
              content="Please navigate to [Liquidity Bootstrapping] as [Earn] liquidity pool deposit/withdrawals will only open on August 24th 2023 2pm UTC. Earn Pool APR will be 10 times lower than Liquidity Bootstrapping Reward Pool for the duration of the event."
              path="/liquiditybootstrapping"
            />
          )} */}
          <div className="Earn-header-title ">DIP/ETH Liquidity Pool</div>
          <div className="Liquidity-card-subtitle">
            Provide liquidity for DIP/ETH pair on Liquidity Pool. Deposit LP token here and earn DIP tokens.
          </div>
          {liquiditys.map((item, index) => (
            <div className="mt-20" key={index}>
              <div
                onClick={() => {
                  goToDetail("/lpDetail");
                }}
              >
                <StakeCard data={item}></StakeCard>
              </div>
            </div>
          ))}
        </div>
        <div className="default-container mt-20">
          <div className="Earn-header-title ">DIP Trading Pools</div>
          <div className="Liquidity-card-subtitle">
            Deposit any asset to one or more tranches to start earning protocol revenues (from swaps and leveraged
            trading) as well as DIP token incentives on top.
          </div>
          {tranches.map((item, index) => (
            <div className="mt-20" key={index}>
              <div
                onClick={() => {
                  goToDetail(`/earndetail/${index + 1}`);
                }}
              >
                <TrancheCard data={item}></TrancheCard>
              </div>
            </div>
          ))}
        </div>
        <div className="default-container mt-20">
          <div className="Earn-header-title ">Innovation Pools </div>
          <div className="Liquidity-card-subtitle">Deposit x or USDC to get x DLP and start earning DIP.</div>
          <div className="mt-20">
            {xPools.map((item, index) => (
              <div className="mt-20" key={index}>
                <div
                  onClick={() => {
                    helperToast.error("Coming soon!");
                  }}
                >
                  <TrancheCard data={item}></TrancheCard>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="default-container mt-20">
          <div className="Earn-header-title"> Token Distribution</div>
          <div>
            <TokenDistribution></TokenDistribution>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
