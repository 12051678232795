import cx from "classnames";
import React, { useState, useEffect, useRef } from "react";
import CurrencySelect from "components/Currency/CurrencySelect";
import SingleSwapIcon from "img/ic_single_swap.png";
import Button from "components/Button/Button";
import { approveTokens } from "domain/tokens";
import longImg from "img/ic_long_white.png";
import longDarkImg from "img/ic_long_dark.png";
import shortImg from "img/ic_short_white.png";
import shortDarkImg from "img/ic_short_dark.png";
import { AVAILABLE_CHART_TOKENS, getToken, getTokenBySymbol } from "config/tokens";
import { useChainId } from "lib/chains";
import { ethers } from "ethers";
import ERC20 from "abis/ERC20.json";
import { getContract } from "config/contracts";
import { formatAmount, formatMoney, isNumber } from "lib/numbers";
import { getMarketInfo, getSwapAndExecutionFee, encodeSwapData } from "lib/positions";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import Pool from "abis/Pool.json";
import { helperToast } from "lib/helperToast";
import "./ExchangeRight.css";
import { getConstant } from "config/chains";
import { expandOfStringDecimals } from "lib/numbers";
import { defaultTokenDecimals } from "lib/pool";
import OrderManager from "abis/OrderManager.json";
import { callContract } from "lib/contracts";
import { getReferrerBytes } from "lib/referral";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";


export function SpotRight ({ defaultTokens, setToTokenAddress, setPendingTxns, tokenPrice }) {
  const { chainId } = useChainId();
  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");

  const [state, setState] = useState({ tabIndex: 0 });
  const [payTokenInfo, setPayTokenInfo] = useState();
  const [isApproving, setIsApproving] = useState();
  const [allowanceAmount, setAllowanceAmount] = useState();
  const orderManagerAddr = getContract(chainId, "OrderManager");
  const [receiveAmount, setReceiveAmount] = useState();
  const receiveToken = useRef();
  const poolAddress = getContract(chainId, "Pool");
  const [inProcess, setInProcess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [slippage, setSlippage] = useState(0.003);
  const [executionFee, setExecutionFee] = useState("");
  const [swapFee, setSwapFee] = useState();
  const [orderType, setOrderType] = useState({ type: 0 }); //0: market 1:limit
  const payTokenChild = useRef(null);
  const [disabledButton, setDisabledButton] = useState(false);

  const isBuy = () => {
    return state.tabIndex === 0;
  };
  const getAllowanceAmount = async (token) => {
    if (token.isNative) {
      setAllowanceAmount("9999999999999999999999999999");
      return;
    }
    try {
      const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
      const allowanceAmount = await tokenContract.allowance(account, orderManagerAddr);
      setAllowanceAmount(parseFloat(formatAmount(allowanceAmount, token.decimals, token.decimals)));
    } catch (e) {
      // console.log("error in ", e);
    }
  };

  const defaultTokensInfo = [
    {
      type: "Buy",
      from: getTokenBySymbol(chainId, defaultCollateralSymbol), // todo temp for testnet
      to: getToken(chainId, defaultTokens.Buy.to),
    },
    {
      type: "Sell",
      from: getToken(chainId, defaultTokens.Sell.from), // todo temp for testnet
      to: getTokenBySymbol(chainId, defaultCollateralSymbol),
    },
  ];

  const checkAvailableAmount = (amount) => {
    if (amount && amount > marketInfo?.available) {
      return false;
    }
    return true;
  };

  const getButtonText = () => {
    if (!active) return "Connect Wallet";
    if (isApproving) {
      return "Approving";
    }

    if (!checkAvailableAmount(receiveAmount)) {
      return `Max Allow ${marketInfo?.available} ${defaultTokensInfo[state.tabIndex]?.to?.baseSymbol}`;
    }
    if (allowanceAmount && payTokenInfo && allowanceAmount < payTokenInfo.amount) {
      return `Approve ${payTokenInfo.token.symbol}`;
    }
    const symbol = receiveToken?.current?.token?.symbol;
    return `${isBuy() ? "BUY" : "SELL"}  ${symbol ? symbol : "Token"}`;
  };

  const tabs = [
    {
      icon: isBuy() ? longImg : longDarkImg,
      text: "Buy",
      activeClass: "Exchange-right-long-active",
    },
    {
      icon: !isBuy() ? shortImg : shortDarkImg,
      text: "Sell",
      activeClass: "Exchange-right-short-active",
    },
  ];

  const { data: poolAsset } = useSWR(
    [
      `Exchange:poolAsset${active}`,
      chainId,
      poolAddress,
      "getPoolAsset",
      defaultTokensInfo[state.tabIndex]?.to?.isNative
        ? defaultTokensInfo[state.tabIndex]?.to?.wrappedAddress
        : defaultTokensInfo[state.tabIndex]?.to?.address,
    ],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const marketInfo = getMarketInfo(poolAsset, defaultTokensInfo[state.tabIndex]?.to);

  const clickTab = (index) => {
    setState({ tabIndex: index });
  };

  const setReceiveToken = (tokenInfo) => {
    // console.log("setReceiveToken", tokenInfo.token);
    receiveToken.current = tokenInfo;
    setReceiveAmount(undefined);
    payTokenChild?.current?.clearInput();
    // console.log("tokenInfo.token", tokenInfo.token);
    if (!tokenInfo.token.isStable) setToTokenAddress(defaultTokensInfo[state.tabIndex].type, tokenInfo.token.address);
  };

  useEffect(() => {
    if (!payTokenInfo) return;
    if (account) {
      getAllowanceAmount(payTokenInfo.token);
    }
    if (payTokenInfo.token.symbol) {
      if (receiveToken?.current?.token?.isStable && !payTokenInfo?.token?.isStable) {
        setToTokenAddress(defaultTokensInfo[state.tabIndex].type, payTokenInfo.token.address);
      }
      setReceiveAmount(undefined);
    }
  }, [payTokenInfo, account]);

  useEffect(() => {
    if (!payTokenInfo || !payTokenInfo.token || !receiveToken.current || !receiveToken.current.token) return;
    const payAmount = payTokenInfo.amount;
    if (payAmount && payAmount !== "" && isNumber(payAmount)) {
      getSwapAndExecutionFee(chainId, signer, payTokenInfo, receiveToken.current).then((fee) => {
        setSwapFee(fee.swapFee);
        setExecutionFee(fee.executionFee);
      });
      const receive =
        (payAmount * tokenPrice[payTokenInfo.token.baseSymbol]?.min) /
        tokenPrice[receiveToken.current.token.baseSymbol]?.max;
      setDisabledButton(!checkAvailableAmount(receive));

      setReceiveAmount(receive);
    } else {
      setSwapFee(undefined);
    }
  }, [payTokenInfo, orderType]);

  const rightCells = [
    { split: true },

    {
      title: `${payTokenInfo?.token?.baseSymbol ? payTokenInfo?.token?.baseSymbol : ""} Price`,
      value: `$${formatMoney(tokenPrice[payTokenInfo?.token?.baseSymbol]?.min, payTokenInfo?.token?.displayDecimals)}`,
    },
    {
      title: `${receiveToken.current?.token?.baseSymbol ? receiveToken.current?.token?.baseSymbol : ""} Price`,
      value: `$${formatMoney(
        tokenPrice[receiveToken.current?.token?.baseSymbol]?.max,
        receiveToken.current?.token?.displayDecimals
      )}`,
    },
    {
      title: `Price`,
      value: `${payTokenInfo?.token?.baseSymbol ? "1 " + payTokenInfo?.token?.baseSymbol : ""} ≈ ${formatMoney(
        tokenPrice[payTokenInfo?.token?.baseSymbol]?.min / tokenPrice[receiveToken.current?.token?.baseSymbol]?.max,
        4
      )} ${receiveToken.current?.token?.baseSymbol ? receiveToken.current?.token?.baseSymbol : ""}`,
    },
  ];

  const marketInfoCells = [
    {
      title: "Available Liquidity",
      value: `${marketInfo?.available} ${defaultTokensInfo[state.tabIndex]?.to?.symbol}`,
    },
  ];
  const buttonClick = async () => {
    if (active && account) {
      const { token, amount } = payTokenInfo;
      if (!amount || !isNumber(amount)) {
        helperToast.error("Please enter a amount!");
        return;
      }
      // if (receiveAmount && receiveAmount < MIN_COLLATERAL_VALUE) {
      //   helperToast.error("Min Collateral Value: $10");
      //   return;
      // }
      let { decimals, address } = token;
      const receive = receiveToken.current.token;
      if (!token.isNative) {
        if (allowanceAmount < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: orderManagerAddr,
            chainId,
            onApproveSubmitted: () => {
              setAllowanceAmount(amount);
            },
          });
          return;
        }
      }

      setInProcess(true);
      if (!payTokenInfo || !receiveToken || !receiveAmount || !amount) return;
      let msgValue = executionFee;
      if (token.isNative) {
        address = token.placeHolderAddress;
        msgValue += parseFloat(amount);
      }
      const payAmount = expandOfStringDecimals(amount, decimals);

      msgValue = expandOfStringDecimals(msgValue, defaultTokenDecimals);
      console.log("msgValue", msgValue.toString())
      const contract = new ethers.Contract(orderManagerAddr, OrderManager.abi, signer);
      // console.log("orderPrice", orderPrice.toString());
      // console.log("payAmount", payAmount.toString());
      // console.log("positionValue", positionValue.toString());
      // console.log("msgValue", msgValue.toString());
      // console.log(
      //   "indexToken",
      //   positionsToken.token.isNative ? positionsToken.token.wrappedAddress : positionsToken.token.address
      // );

      // console.log("assetToken", assetToken.isNative ? assetToken.wrappedAddress : assetToken.address);
      // console.log("payToken", address);

      callContract(
        chainId,
        contract,
        "swap(address,address,uint256,uint256,bytes)",
        [
          address,
          receive.isNative ? receive.placeHolderAddress : receive.address,
          payAmount,
          0,
          encodeSwapData(getReferrerBytes()),
        ],
        {
          sentMsg: `Confirm submitted!`,
          failMsg: `Confirm failed.`,
          value: msgValue,
          setPendingTxns,
        }
      )
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          confirmed();
          setInProcess(false);
        });
    } else {
      openConnectModal();
    }
  };

  const confirmed = () => {
    setShowModal(false);
    payTokenChild?.current?.clearInput();
  };
  return (
    <div className="Exchange-swap-box">
      <div className={cx("Tab", "block", "Exchange-swap-option-tabs")}>
        {tabs.map((opt, index) => (
          <div
            className={cx(`${state.tabIndex === index ? opt.activeClass : ""}`, "Tab-option", "muted")}
            onClick={() => clickTab(index)}
            key={index}
          >
            {/* <img className="Tab-option-icon icon-xs" src={opt.icon} alt={opt.icon} /> */}
            <span> {opt.text}</span>
          </div>
        ))}
      </div>
      {/* <OrderType callBack={setOrderType} tokenPrice={tokenPrice} token={receiveToken?.current?.token} /> */}
      <CurrencySelect
        setStakeTokenInfo={setPayTokenInfo}
        title={"Pay"}
        customerToken={!isBuy() ? AVAILABLE_CHART_TOKENS[chainId] : undefined}
        ref={payTokenChild}
        defaultSelectedToken={defaultTokensInfo[state.tabIndex]?.from}
      />
      <div className="flex justify-center">
        <img src={SingleSwapIcon} className="icon-xs" alt="Swap"></img>
      </div>
      <CurrencySelect
        setStakeTokenInfo={setReceiveToken}
        title={"Receive"}
        displayAmount={receiveAmount}
        hideMax={true}
        customerToken={isBuy() ? AVAILABLE_CHART_TOKENS[chainId] : undefined}
        hideBalance={true}
        defaultSelectedToken={defaultTokensInfo[state.tabIndex]?.to}
      />

      <div className="Earn-detail-right-cell">
        <div className={cx("flex justify-between aligns-center ", "mt-10")}>
          <div className="Earn-detail-left-subtitle">Slippage</div>
          <div className="Earn-detail-left-value flex aligns-center">
            <div className="ml-5"> {slippage * 100}%</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
          <Button
            variant={`${state.tabIndex === 0 ? "Long-action" : "Short-action"} `}
            className="w-100"
            disabled={disabledButton}
          >
            {getButtonText()}
          </Button>
        </div>
        {rightCells.map((item, index) =>
          item.split ? (
            <div className="Liquidity-card-split mb-20" key={index}></div>
          ) : (
            <div className={cx("flex justify-between aligns-center ", "mb-10")} key={index}>
              <div className="Earn-detail-left-subtitle">{item.title}</div>
              <div className="Earn-detail-left-value flex aligns-center">
                {item.rightIcon && <img src={item.rightIcon} alt={item.value} className="icon-xs"></img>}
                {item.changed && (
                  <div className="mr-10 Earn-detail-left-subtitle">
                    {item.beforeValue}
                    <span className="ml-10">→</span>
                  </div>
                )}
                <div className={`ml-5 ${item.valueClass ? item.valueClass : ""}`}> {item.value}</div>
              </div>
            </div>
          )
        )}

        <div className={cx("flex justify-between aligns-center ", "mb-10")}>
          <div className="Earn-detail-left-subtitle">Swap Fee</div>
          <div className="Earn-detail-left-value flex aligns-center">
            <div className="ml-5"> {swapFee?.toFixed(4)}%</div>
          </div>
        </div>
        <div className={cx("flex justify-between aligns-center ", "mb-10")}>
          <div className="Earn-detail-left-subtitle">execution Fee</div>
          <div className="Earn-detail-left-value flex aligns-center">
            <div className="ml-5"> {executionFee} ETH</div>
          </div>
        </div>
        <div className="Liquidity-card-split mb-20 mt-20"></div>
        <div className="mb-10">Market Info</div>
        {marketInfoCells.map((item, index) =>
          item.split ? (
            <div className="Liquidity-card-split mb-20" key={index}></div>
          ) : (
            <div className={cx("flex justify-between aligns-center ", "mb-10")} key={index}>
              <div className="Earn-detail-left-subtitle">{item.title}</div>
              <div className="Earn-detail-left-value flex aligns-center">
                {item.rightIcon && <img src={item.rightIcon} alt={item.value} className="icon-xs"></img>}
                <div className="ml-5"> {item.value}</div>
              </div>
            </div>
          )
        )}
      </div>
      {/* <ExchangeModal
        payToken={payTokenInfo}
        assetToken={defaultTokensInfo[state.tabIndex].to}
        receiveToken={receiveToken.current}
        side={state.tabIndex}
        chainId={chainId}
        account={account}
        active={active}
        signer={signer}
        receiveInfo={receiveInfo}
        leverage={leverageOption}
        slippage={slippage}
        isOpenPosition={true}
        orderType={orderType}
        setPendingTxns={setPendingTxns}
        tokenPrice={tokenPrice}
        closeModal={() => {
          setShowModal(false);
        }}
        onConfirmed={() => {
          confirmed();
        }}
        showModal={showModal}
        fee={{
          borrowFee: marketInfo?.borrowFee,
          swapFee: swapFee,
          executionFee: executionFee,
          positionFee: receiveInfo?.positionFee,
        }}
      /> */}
    </div>
  );
}
