import React, { useEffect, useState } from "react";
import useWallet from "lib/wallets/useWallet";
import { useChainId } from "lib/chains";
import cx from "classnames";
import USDCAirdrop from "./USDCAirdrop";
import DIPAirdrop from "./DIPAirdrop";
import "./TestnetRewards.css";

function TestnetRewards () {
  const { account } = useWallet();
  const { chainId } = useChainId();

  const [leftIndex, setLeftIndex] = useState(0);

  const leftMenuCell = [
    {
      icon: require("img/DIP-token.png"),
      content: "DIP AirDrop",
    },
    {
      icon: require("img/ic_usdc_40.svg").default,
      content: "USDC AirDrop",
    },
  ];
  const menuClick = (index) => {
    setLeftIndex(index);
  };
  return (
    <div className="default-bg">
      <div className="Earn-header-bg">
        <div className="Earn-header default-container flex-col justify-center">
          <div className="Earn-header-title ">Trading Contest AirDrop </div>
          <div className="">Airdrop USDC/DIP rewards for users who join in the testnet trading contest.</div>
        </div>
      </div>
      <div className="default-container">
        <div className="row">
          <div className=" col-12 col-md-2 ">
            <div className="row">
              {leftMenuCell.map((item, index) => (
                <div
                  className={cx("col-4 col-md-12 Dao-left-cell flex aligns-center ", {
                    "Dao-left-cell-active": leftIndex === index,
                  })}
                  key={index}
                  onClick={() => menuClick(index)}
                >
                  {item.icon && (
                    <img
                      className={cx("icon-sm", { "UnActive-Img": leftIndex !== index })}
                      src={item.icon}
                      alt={item.content}
                    />
                  )}
                  <div className="ml-10">{item.content}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-md-10">
            {leftIndex === 0 && <DIPAirdrop account={account} chainId={chainId} />}
            {leftIndex === 1 && <USDCAirdrop account={account} chainId={chainId} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestnetRewards;
