import React from "react";
import "./ButtonWithIcon.css";
import { useHistory } from "react-router-dom";

export default function BackButton () {
  const history = useHistory();
  const onClick = () => {
    history.goBack();
  };
  return (
    <div className="Back-button">
      <div className=" flex aligns-center" onClick={onClick}>
        <img width="15px" src={require("img/ic_back.png")} alt=""></img>
        <div className="ml-5">BACK </div>
      </div>
    </div>
  );
}
