import React, { useState, useEffect, useMemo } from "react";
import { helperToast } from "lib/helperToast";
import cx from "classnames";
import DIPStaking from "./DIPStaking";
import DGTStaking from "./DGTStaking";
import Footer from "components/Footer/Footer";
import ErrorBanner from "components/Banner/ErrorBanner";

function Staking ({ setPendingTxns }) {
  const [leftIndex, setLeftIndex] = useState(0);

  const leftMenuCell = [
    {
      icon: require("img/DIP-token.png"),
      activeIcon: require("img/DIP-token.png"),
      content: "DIP Staking",
    },
    {
      icon: require("img/DGT-token.png"),
      activeIcon: require("img/DGT-token.png"),
      content: "DGT Staking",
    },
  ];
  const menuClick = (index) => {
    if (index > 2) {
      helperToast.error("Coming Soon!");
      return;
    }
    setLeftIndex(index);
  };
  return (
    <div className="default-bg default-container page-layout">
      <div className="Earn-header-bg">
        <div className="Earn-header   flex-col justify-center">
          <div className="Earn-header-title ">Staking</div>
          <div className="">Stake DIP or DGT to earn protocol revenue.</div>
        </div>
      </div>

      <div className="row">
        <div className=" col-12 col-md-2 ">
          <div className="row">
            {leftMenuCell.map((item, index) => (
              <div
                className={cx("col-4 col-md-12 Dao-left-cell flex aligns-center ", {
                  "Dao-left-cell-active": leftIndex === index,
                })}
                key={index}
                onClick={() => menuClick(index)}
              >
                {item.icon && (
                  <img className="icon-xs" src={leftIndex === index ? item.activeIcon : item.icon} alt={item.content} />
                )}

                <div className="ml-10">{item.content}</div>
              </div>
            ))}
          </div>
        </div>
        <div className=" col-12 col-md-10 ">
          {leftIndex === 0 ? (
            <DIPStaking setPendingTxns={setPendingTxns} />
          ) : (
            <DGTStaking setPendingTxns={setPendingTxns} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Staking;
