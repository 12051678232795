import React from "react";

export default function LiquidityCard({ data }) {
  const { icon, symbol, price, amount, value, weight, utilization } = data;
  const cellClass = "flex justify-between aligns-center mt-20";
  return (
    <div className="Liquidity-card mt-20">
      <div className="flex justify-between aligns-center">
        <div className="flex">
          {icon ? <img alt="" src={icon} className="icon-sm mr-base"></img> : ""}
          <div className="Liquidity-card-title">{symbol}</div>
        </div>
        <div className="Liquidity-card-value">${price}</div>
      </div>
      <div className=" Liquidity-card-split"></div>
      <div className={cellClass}>
        <div className="Liquidity-card-subtitle">Amount</div>
        <div className="Liquidity-card-value">{amount}</div>
      </div>
      <div className={cellClass}>
        <div className="Liquidity-card-subtitle">Pool</div>
        <div className="Liquidity-card-value">{value}</div>
      </div>
      <div className={cellClass}>
        <div className="Liquidity-card-subtitle">Weight/Target</div>
        <div className="Liquidity-card-value">{weight}</div>
      </div>
      <div className={cellClass}>
        <div className="Liquidity-card-subtitle">Utilization</div>
        <div className="Liquidity-card-value">{utilization}</div>
      </div>
    </div>
  );
}
