import React, { useState } from "react";
import "./Dao.css";
import cx from "classnames";
import DaoOverview from "./DaoOverview";
import DaoStaking from "./DaoStaking";
import Footer from "components/Footer/Footer";
import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import { useTreasuryInfo, useDaoStakingInfo } from "domain/pool";
import { getTokenBySymbol } from "config/tokens";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import ERC20 from "abis/ERC20.json";

export default function Dao ({ setPendingTxns }) {
  const [leftIndex, setLeftIndex] = useState(0);

  const { chainId } = useChainId();
  const { signer, account, active } = useWallet();
  const treasuryData = useTreasuryInfo(chainId, signer);

  const vestingEnd = 1815660000;
  const vestingCycle = vestingEnd - 1692108000;
  const dipDaoLock = (97916667 * (vestingEnd - Date.now() / 1000)) / vestingCycle;
  const dipTreasuryReserve = 97916667 - dipDaoLock;

  const { totalDepositAmount, totalDepositValue, apr, perDay, dgtPairPrice } = useDaoStakingInfo(
    chainId,
    signer
  );
  const { data: dgtTotalSupply } = useSWR(
    [`DaoStaking:DGTTotalSupply`, chainId, getTokenBySymbol(chainId, "DGT")?.address, "totalSupply"],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const leftMenuCell = [
    {
      icon: require("img/ic_overview.png"),
      activeIcon: require("img/ic_overview-active.png"),
      content: "Overview",
    },
    {
      icon: require("img/ic_staking-active.png"),
      activeIcon: require("img/ic_staking.png"),
      content: "Staking",
    },
    {
      icon: require("img/ic_proposals.png"),
      activeIcon: require("img/ic_proposals-active.png"),
      content: "Proposals",
    },
  ];
  const menuClick = (index) => {
    if (index === 2) {
      window.open("https://snapshot.org/#/dip-exchange.eth");
      return;
    }
    setLeftIndex(index);
  };
  return (
    <div
      className="default-bg"
      style={{
        minHeight: "calc(100vh - 62px)",
        paddingBottom: "calc(4.65rem + 20rem)",
      }}
    >
      <div className="default-container">
        <div className="row mt-20 ">
          <div className=" col-12 col-md-2 ">
            <div className="row">
              {leftMenuCell.map((item, index) => (
                <div
                  className={cx("col-4 col-md-12 Dao-left-cell flex aligns-center ", {
                    "Dao-left-cell-active": leftIndex === index,
                  })}
                  key={index}
                  onClick={() => menuClick(index)}
                >
                  <img className="icon-xs" src={leftIndex === index ? item.activeIcon : item.icon} alt={item.content} />
                  <div className="ml-10">{item.content}</div>
                </div>
              ))}
            </div>
          </div>
          <div className=" col-12 col-md-10">
            {leftIndex === 0 && (
              <DaoOverview
                dipDaoLock={dipDaoLock}
                dipTreasuryReserve={dipTreasuryReserve}
                chainId={chainId}
                signer={signer}
                active={active}
                dipPairPrice={treasuryData?.dipPairPrice}
                treasuryData={treasuryData}
                totalDepositAmount={totalDepositAmount}
                totalDepositValue={totalDepositValue}
                apr={apr}
                perDay={perDay}
                dgtPairPrice={dgtPairPrice}
                dgtTotalSupply={dgtTotalSupply}
              />
            )}
            {leftIndex === 1 && (
              <DaoStaking
                dipDaoLock={dipDaoLock}
                dipTreasuryReserve={dipTreasuryReserve}
                chainId={chainId}
                signer={signer}
                active={active}
                dipPairPrice={treasuryData?.dipPairPrice}
                account={account}
                setPendingTxns={setPendingTxns}
                treasuryData={treasuryData}
                totalDepositAmount={totalDepositAmount}
                totalDepositValue={totalDepositValue}
                apr={apr}
                perDay={perDay}
                dgtPairPrice={dgtPairPrice}
                dgtTotalSupply={dgtTotalSupply}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
