import cx from "classnames";
import React, { useState, useEffect } from "react";
import "./ExchangeModal.css";
import { expandOfStringDecimals, formatMoney, isNumber } from "lib/numbers";
import Button from "components/Button/Button";
import { ethers } from "ethers";
import { callContract } from "lib/contracts";
import OrderManager from "abis/OrderManager.json";
import { getContract } from "config/contracts";
import { approveTokens } from "domain/tokens";

import {
  encodePositionData,
  getPositionsInfoAndPosition,
  MIN_COLLATERAL_VALUE,
  MAX_LEVERAGE,
  LIMIT_MARGIN_PERCENT,
  getPositionsInfoDePosition,
  encodeDePositionData,
} from "lib/positions";
import { getTokenBySymbol, placeHolderAddress } from "config/tokens";
import { defaultTokenDecimals, defaultPriceDecimals, defaultValueDecimals, getExecutionFee } from "lib/pool";
import { ExchangeModalCell } from "components/Exchange/ExchangeModalCell";
import longImg from "img/ic_long.png";
import shortImg from "img/ic_short.png";
import { formatAmount } from "lib/numbers";
import ERC20 from "abis/ERC20.json";
import { helperToast } from "lib/helperToast";
import { getConstant } from "config/chains";
import { getReferrerBytes } from "lib/referral";
export function PositionsEdit ({
  position,
  tokenPrice,
  chainId,
  slippage,
  signer,
  setPendingTxns,
  onConfirmed,
  closeModal,
  showModal,
  account,
}) {
  const { side, leverage, isLong } = position;
  const indexToken = position.indexToken.symbol === "WETH" ? getTokenBySymbol(chainId, "ETH") : position.indexToken;
  const [confirming, setConfirming] = useState(false);
  const orderManagerAddr = getContract(chainId, "OrderManager");
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const USDT = getTokenBySymbol(chainId, defaultCollateralSymbol);

  const assetToken = isLong ? indexToken : USDT;
  const { executionFee } = getExecutionFee();
  const [isApproving, setIsApproving] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const [inputAmount, setInputAmount] = useState();
  const [positionsInfo, setPositionsInfo] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [balance, setBalance] = useState();
  const [buttonText, setButtonText] = useState("Confirm");

  const isDepositProcess = () => {
    return tabIndex === 0;
  };
  const tabs = [
    { text: "Deposit", icon: longImg },
    { text: "WithDraw", icon: shortImg },
  ];
  const clickTab = (index) => {
    setTabIndex(index);
    setInputAmount("");
  };
  const [allowanceAmount, setAllowanceAmount] = useState();

  const [maxWithdraw, setMaxWithdraw] = useState();

  const processInfo = () => {
    setPositionsInfo(
      isDepositProcess()
        ? getPositionsInfoAndPosition(
          tokenPrice,
          { type: 0 },
          { token: assetToken, amount: parseFloat(inputAmount) },
          { token: indexToken },
          assetToken,
          leverage,
          isLong,
          slippage,
          [position]
        )
        : getPositionsInfoDePosition(tokenPrice, indexToken, isLong, inputAmount, slippage, position, { type: 0 })
    );
  };

  const canConfirm = () => {
    if (isDepositProcess()) {
      const allowSize = position.size > position.netValue ? position.size - position.netValue : 0;
      const maxDeposit = isLong ? allowSize / tokenPrice[indexToken.baseSymbol].price : allowSize;
      if (maxDeposit < inputAmount) {
        setButtonText(`Max Allow Deposit: ${formatMoney(maxDeposit, isLong ? 4 : 2)} ${assetToken.baseSymbol}`);
        setDisableButton(true);
        return;
      }
    } else {
      if (maxWithdraw < inputAmount) {
        setButtonText(`Max Allow Withdraw: ${formatMoney(maxWithdraw, assetToken.displayDecimals)} USD`);
        setDisableButton(true);
        return;
      }
    }
    setDisableButton(false);
    setButtonText("Confirm");
  };

  useEffect(() => {
    let initRemainingValue = (position.size / MAX_LEVERAGE) * LIMIT_MARGIN_PERCENT;
    if (initRemainingValue > position.netValue) initRemainingValue = position.netValue;

    setMaxWithdraw(
      position.netValue -
      (initRemainingValue >= MIN_COLLATERAL_VALUE ? initRemainingValue - MIN_COLLATERAL_VALUE : MIN_COLLATERAL_VALUE)
    );

    if (indexToken.isNative || !isDepositProcess()) {
      setAllowanceAmount(9999999999);
    }
    if (account && signer) {
      if (assetToken.isNative) {
        signer?.provider.getBalance(account).then((balance) => {
          setBalance(parseFloat(formatAmount(balance, assetToken.decimals)));
        });
      } else {
        const contract = new ethers.Contract(assetToken.address, ERC20.abi, signer);
        if (isDepositProcess()) {
          contract.allowance(account, orderManagerAddr).then((allowanceAmount) => {
            setAllowanceAmount(parseFloat(formatAmount(allowanceAmount, assetToken.decimals, assetToken.decimals)));
          });
        }
        contract.balanceOf(account).then((balance) => {
          setBalance(parseFloat(formatAmount(balance, assetToken.decimals)));
        });
      }
    }
  }, []);

  useEffect(() => {
    processInfo();
    canConfirm();
  }, [inputAmount]);

  /**
   * UpdatePositionType _updateType,
   * Side _side,
   * address _indexToken,
   * address _collateralToken,
   * OrderType _orderType,
   * bytes calldata data */

  /**
   * Order Price: 26810.633214867600
   * Order Pay Token: 0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c
   * Purchase Amount: 0.000300000000000000
   * Request Size Change: 381.863455559999980906827222000000
   * Request Collateral: 0.00300000000000000
   * Extradata:
   */
  const buttonClick = () => {
    if (!position || !inputAmount) return;
    if (!isDepositProcess() && inputAmount > maxWithdraw) {
      helperToast.error("Exceeded maxWithdraw allowable!");
      return;
    }
    if (isDepositProcess() && !assetToken.isNative) {
      // if(!allowanceAmount){
      //   const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
      //   const allowance = await tokenContract.allowance(account, orderManagerAddr);
      //   setAllowanceAmount(formatAmount(allowance,token.decimals,token.decimals));
      // }
      if (allowanceAmount < inputAmount) {
        approveTokens({
          setIsApproving,
          signer,
          tokenAddress: assetToken.address,
          spender: orderManagerAddr,
          chainId,
          onApproveSubmitted: () => {
            setAllowanceAmount(inputAmount);
          },
        });
        return;
      }
    }
    let { decimals, address, isNative, wrappedAddress } = assetToken;
    let msgValue = parseFloat(executionFee);
    if (isNative) {
      address = placeHolderAddress;
      if (isDepositProcess()) {
        msgValue += parseFloat(inputAmount);
      }
    }
    // console.log("msgValue", msgValue);

    const orderPrice = expandOfStringDecimals(
      positionsInfo.triggerPrice,
      defaultPriceDecimals + defaultTokenDecimals - indexToken.decimals
    );
    const payAmount = expandOfStringDecimals(inputAmount, isDepositProcess() ? decimals : defaultValueDecimals);
    const positionValue = isDepositProcess()
      ? expandOfStringDecimals(positionsInfo?.positionValue, defaultValueDecimals)
      : expandOfStringDecimals(inputAmount, defaultValueDecimals);
    msgValue = expandOfStringDecimals(msgValue, defaultTokenDecimals);
    const contract = new ethers.Contract(orderManagerAddr, OrderManager.abi, signer);
    // console.log("confirm:orderPrice", orderPrice.toString());
    // console.log("confirm:payAmount", payAmount.toString());
    // console.log("confirm:positionValue", positionValue.toString());
    // console.log("confirm:msgValue", msgValue.toString());
    // console.log("confirm:indexToken", indexToken);

    // console.log("confirm:payToken", address);
    // console.log("confirm:position.Side", position.side);

    callContract(
      chainId,
      contract,
      "placeOrder",
      [
        tabIndex,
        position.side,
        indexToken.isNative ? indexToken.wrappedAddress : indexToken.address,
        isNative ? wrappedAddress : address,
        0,
        //order.price, order.payToken, request.sizeChange, request.collateral, extradata
        isDepositProcess()
          ? encodePositionData([orderPrice, address, payAmount, 0, payAmount, getReferrerBytes()])
          : encodeDePositionData([orderPrice, address, 0, positionValue, getReferrerBytes()], true),
      ],

      {
        sentMsg: `Confirm submitted!`,
        failMsg: `Confirm failed.`,
        value: msgValue,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setConfirming(false);
        onConfirmed();
      });
  };
  const getButtonText = () => {
    if (confirming) {
      return "Confirming...";
    }
    if (isApproving) {
      return "Approving";
    }
    if (allowanceAmount && isDepositProcess() && allowanceAmount < inputAmount) {
      return `Approve ${assetToken.baseSymbol}`;
    }
    return buttonText;
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setInputAmount(value);
    }
  };
  // console.log("positionsInfo..", positionsInfo);
  const cells = [
    {
      title: "Leverage",
      value: isNumber(positionsInfo?.changed?.new?.leverage)
        ? `${positionsInfo?.changed?.new?.leverage.toFixed(2)}x`
        : " ",
      beforeValue: `${formatMoney(positionsInfo?.changed?.old?.leverage, 2)}x`,
      changed: positionsInfo?.changed !== undefined,
    },
    { title: "Slippage", value: `${slippage * 100}%` },
    { split: true },

    {
      title: "Collateral Asset",
      value: assetToken.baseSymbol,
      rightIcon: assetToken.imageUrl,
    },
    {
      title: "Collateral Value",
      value: `$${formatMoney(positionsInfo?.changed?.new?.collateralValue, 2)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.collateralValue, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Net Value",
      value: `$${formatMoney(positionsInfo?.changed?.new?.netValue, 2)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.netValue, 2)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    { split: true },
    {
      title: "Trigger Condition",
      value: `Mark Price ${side === 0 ? "≤" : "≥"} $${formatMoney(positionsInfo?.triggerPrice, 2)}`,
    },

    {
      title: "Entry Price",
      value: `$${formatMoney(positionsInfo?.changed?.new?.entryPrice, indexToken?.displayDecimals)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.entryPrice, indexToken?.displayDecimals)}`,
      changed: positionsInfo?.changed !== undefined,
    },
    {
      title: "Liquidation Price",
      value: `$${formatMoney(positionsInfo?.changed?.new?.liqPrice, indexToken?.displayDecimals)}`,
      beforeValue: `$${formatMoney(positionsInfo?.changed?.old?.liqPrice, indexToken?.displayDecimals)}`,
      changed: positionsInfo?.changed !== undefined,

      valueClass: "waring-color",
    },
    { split: true },

    // { title: "Position Fee", value: `$${formatMoney(fee?.positionFee, 4)}` },
    { title: "Execution Fee", value: `${executionFee ? executionFee : ""} ETH` },
    // { title: "Borrow Fee", value: `${fee?.borrowFee ? fee?.borrowFee : ""}% per hour ` },
  ];
  return (
    <div
      className={cx("modal fade Exchange-modal", { show: showModal })}
      aria-modal={showModal}
      style={{ display: showModal ? "block" : "none" }}
      role="dialog"
      aria-hidden={showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3">{isDepositProcess() ? "Deposit Collateral" : "Withdraw Collateral"}</h1>
            <div data-bs-theme="dark">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </div>
          <div className="Liquidity-card-split "></div>
          <div className="modal-body mt-10">
            <div className={cx("Tab", "block", "Exchange-swap-option-tabs")}>
              {tabs.map((opt, index) => (
                <div
                  className={cx("Tab-option", "muted", { active: tabIndex === index })}
                  onClick={() => clickTab(index)}
                  key={index}
                >
                  <img className="Tab-option-icon icon-xs" src={opt.icon} alt={opt.icon} />
                  {opt.text}
                </div>
              ))}
            </div>
            <div className="  flex justify-between aligns-center mt-10">
              <div> Amount</div>
              {isDepositProcess() && <div className="Earn-detail-left-subtitle">Balance: {balance}</div>}
              {!isDepositProcess() && (
                <div className="Earn-detail-left-subtitle">Max Withdraw: ${formatMoney(maxWithdraw, 2)}</div>
              )}
            </div>
            <div className="Exchange-modal-input  Exchange-modal-edit-input flex justify-between aligns-center mt-10">
              <div className={cx(" flex-col flex-1  ")}>
                <input
                  value={inputAmount}
                  type="text"
                  onChange={handleChange}
                  className={cx("fw-bold", {
                    "down-color":
                      (isDepositProcess() && inputAmount > balance) ||
                      (!isDepositProcess() && inputAmount > maxWithdraw),
                  })}
                  style={{ padding: "0" }}
                />
                {inputAmount && (
                  <div className="Exchange-modal-sub-title">
                    {isDepositProcess() && isLong
                      ? `≈ $${formatMoney(inputAmount * tokenPrice[indexToken?.baseSymbol]?.price, 2)}`
                      : `≈ ${formatMoney(inputAmount / tokenPrice[indexToken?.baseSymbol]?.price, 4)} ${indexToken?.baseSymbol
                      }`}
                  </div>
                )}
              </div>
              <div className="flex aligns-center Currency-selected-group">
                {isDepositProcess() && <img src={assetToken?.imageUrl} alt="" className="icon-xs mr-10"></img>}
                <div>{isDepositProcess() ? assetToken?.baseSymbol : "USD"}</div>
              </div>
            </div>

            <ExchangeModalCell cells={cells} />
          </div>
          <div className="">
            <div className="Exchange-swap-button-container mt-20 mb-20" onClick={buttonClick}>
              <Button variant="primary-action" className="w-100 fw-bold" disabled={disableButton}>
                {getButtonText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
