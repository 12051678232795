import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Detail.css";
import cx from "classnames";
import Button from "components/Button/Button";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { approveTokens } from "domain/tokens";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import DIPMaster from "abis/DIPMaster.json";
import DIPZap from "abis/DIPZap.json";
import { getPoolData, getUserInfoData } from "./services";
import CurrencySelect from "components/Currency/CurrencySelect";
import { ethers } from "ethers";
import ERC20 from "abis/ERC20.json";
import { formatAmount, expandOfStringDecimals, formatMoney } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import { useDLPPrices } from "domain/pool";
import { useDipPairPrice } from "domain/prices";
import BackButton from "components/Button/BackButton";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";


export default function EarnDetail ({ setPendingTxns }) {

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const { chainId } = useChainId();
  const poolId = 3;
  const tokenName = "DIP/ETH LP";

  const dipMasterAddr = getContract(chainId, "DIPMaster");
  const lpToken = getTokenBySymbol(chainId, tokenName);

  const [customerToken, setCustomerToken] = useState();

  const { data: managementData } = useSWR(
    [`LpEarn:SnrBalance${active}`, chainId, lpToken.address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const dipPairPrice = useDipPairPrice(chainId, signer);
  const dipFtmPool = getPoolData(managementData, 3, dipPairPrice?.lpPrice, dipPairPrice?.dipPrice);

  const history = useHistory();
  const { data: claimableRewards } = useSWR(
    active && [active, chainId, dipMasterAddr, "pendingReward", poolId, account],
    {
      fetcher: contractFetcher(signer, DIPMaster),
    }
  );
  const claimableDIP = formatAmount(claimableRewards ? claimableRewards : "0", 18, 4, true);

  const { data: userInfoData } = useSWR(active && [active, chainId, dipMasterAddr, "userInfo", poolId, account], {
    fetcher: contractFetcher(signer, DIPMaster),
  });
  const userInfo = {
    amount: formatAmount(userInfoData?.amount, 18, 4, true),
  };
  const leftCells = [
    { title: "Claimable Rewards", value: `${claimableDIP}`, unit: "DIP" },
    {
      title: "Your Deposit",
      value: userInfo?.amount,
      unit: tokenName,
    },
    // { title: "Total Rewards", value: `${claimableDIP + userinfo?.rewardDebt} DIP` },
    { split: true },
    // { title: "LP Price", value: `$${dipFtmPool?.price}` },
    { title: "Earn per day", value: "12,000", unit: "DIP/Day" },
  ];
  const rightCells = [
    // { title: "Slippage", value: "0.3%" },
    // { title: "Minimum Received", value: "-- Senior LP" },
    // { title: "Weight/Target", value: "--%/--%" },
    // { title: "Fees", value: "0.21%" },
  ];
  const rowDefaultClass = "flex aligns-center justify-between";
  const goBack = () => {
    history.goBack();
  };
  const [stakeTokenInfo, setStakeTokenInfo] = useState();

  const [state, setState] = useState({ tabIndex: 1 });

  const clickTab = (index) => {
    setState({
      tabIndex: index,
    });
  };
  const [hideMax, setHideMax] = useState(false);

  const [useFTMOnly, setUseFTMOnly] = useState(true);
  const switchFTMOnly = () => {
    if (useFTMOnly) {
      setCustomerToken([tokenName]);
      setHideMax(true);
    } else {
      setCustomerToken(undefined);
    }
    setUseFTMOnly(!useFTMOnly);
  };
  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const getButtonText = () => {
    if (!active) {
      return "Connect Wallet";
    }
    if (isApproving) {
      return "Approving";
    }
    if (isStaking) {
      return "Depositing";
    }
    if (isWithdrawing) {
      return "Withdrawing";
    }
    return state.tabIndex === 1 ? "Deposit" : "Withdraw";
  };
  const [withdrawAmount, setWithdrawAmount] = useState();
  const withdrawHandleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setWithdrawAmount(value);
    }
  };
  const withdraw = () => {
    if (active) {
      setIsWithdrawing(true);
      const contract = new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer);
      callContract(
        chainId,
        contract,
        "withdraw",
        [poolId, expandOfStringDecimals(withdrawAmount, lpToken.decimals), account],
        {
          sentMsg: `Withdraw submitted!`,
          failMsg: `Withdraw failed.`,
          setPendingTxns,
        }
      )
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsWithdrawing(false);
        });
    } else {
      openConnectModal();
    }
  };
  const zap = async () => {
    const { token, amount } = stakeTokenInfo;
    const address = getContract(chainId, "DIPZap");
    const contract = new ethers.Contract(address, DIPZap.abi, signer);
    callContract(chainId, contract, "zap", [0, 0, true], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed.`,
      value: expandOfStringDecimals(amount, token.decimals),
      setPendingTxns,
    })
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };
  const deposit = async () => {
    if (active && stakeTokenInfo) {
      if (useFTMOnly) {
        await zap();
        return;
      }
      const { token, amount } = stakeTokenInfo;
      const { decimals } = token;
      if (!token.isNative) {
        const tokenContract = new ethers.Contract(token.address, ERC20.abi, signer);
        const allowanceAmount = await tokenContract.allowance(account, dipMasterAddr);
        // console.log("-------");
        // console.log(allowanceAmount.toString(), formatAmount(allowanceAmount, decimals, decimals), amount);
        if (formatAmount(allowanceAmount, decimals, decimals) < amount) {
          approveTokens({
            setIsApproving,
            signer,
            tokenAddress: token.address,
            spender: dipMasterAddr,
            chainId,
            setPendingTxns,
          });
          return;
        }
      }

      setIsStaking(true);

      const contract = new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer);

      // return;
      callContract(chainId, contract, "deposit", [poolId, expandOfStringDecimals(amount, token.decimals), account], {
        sentMsg: `Deposit submitted!`,
        failMsg: `Deposit failed.`,
        // setPendingTxns,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setIsStaking(false);
        });
    } else {
      openConnectModal();
    }
  };
  const claim = async () => {
    callContract(
      chainId,
      new ethers.Contract(dipMasterAddr, DIPMaster.abi, signer),
      "harvest",
      [poolId, account],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };
  return (
    <div className="default-bg">
      <div className="default-container pt-40">
        <BackButton></BackButton>

        <div className="row mt-20">
          <div className="col-12 col-md-8 order-md-1 order-1">
            <div className="order-1">
              <div className={cx("Earn-detail-left-header", rowDefaultClass)}>
                <div>{tokenName} Pool</div>
                <div className="default-btn" onClick={claim}>
                  Claim
                </div>
              </div>
              <div className="Earn-detail-left-content">
                {leftCells.map((item, index) =>
                  item.split ? (
                    <div className="Liquidity-card-split" key={index}></div>
                  ) : (
                    <div className={cx(rowDefaultClass, { "mt-15": index > 0 })} key={index}>
                      <div className="Earn-detail-left-subtitle">{item.title}</div>
                      <div className="Earn-detail-left-value flex aligns-center">
                        <div>{item.value}</div>
                        {item.unit && <div className="Earn-detail-left-subtitle ml-5">{item.unit}</div>}
                      </div>
                    </div>
                  )
                )}
                <div className={cx(rowDefaultClass, "mt-10")}>
                  <div className="Earn-detail-left-subtitle">APR</div>
                  <div className="Earn-detail-left-value">
                    {dipFtmPool?.apr ? formatMoney(dipFtmPool?.apr, 2) : "---"}%
                  </div>
                </div>
                <div className={cx(rowDefaultClass, "mt-10")}>
                  <div className="Earn-detail-left-subtitle">Total deposit</div>
                  <div className="flex-col justify-center align-items-end">
                    <div className="Earn-detail-left-value flex aligns-center">
                      <div> {dipFtmPool?.totalAmount} </div>
                      <div className="Earn-detail-left-subtitle ml-5"> {tokenName}</div>
                    </div>
                    <div className="Earn-detail-left-sub-value">${dipFtmPool?.totalValue}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-2">
            <div className="Ear-detail-right order-2">
              <div className="flex">
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "up-color ": state.tabIndex === 1,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 1,
                  })}
                  onClick={() => clickTab(1)}
                >
                  Deposit
                </div>
                <div
                  className={cx("flex-1 Earn-detail-right-tab", {
                    "down-color": state.tabIndex === 2,
                    "Earn-detail-right-tab-unactive": state.tabIndex !== 2,
                  })}
                  onClick={() => clickTab(2)}
                >
                  Withdraw
                </div>
              </div>
              {state.tabIndex === 1 && (
                <div>
                  <div className="Earn-detail-right-cell flex aligns-center  justify-content-between mt-10">
                    <div>Use ETH Only</div>
                    <div className="form-check form-switch ml-10" onClick={switchFTMOnly}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={useFTMOnly}
                      />
                    </div>
                  </div>
                  <CurrencySelect
                    setStakeTokenInfo={setStakeTokenInfo}
                    hideList={true}
                    customerToken={customerToken}
                    hideMax={hideMax}
                  />
                  <div className="Earn-detail-right-cell">
                    {rightCells.map((item, index) =>
                      item.split ? (
                        <div className="Liquidity-card-split"></div>
                      ) : (
                        <div className={cx(rowDefaultClass, { "mt-10": index > 0 })} key={index}>
                          <div className="Earn-detail-left-subtitle">{item.title}</div>
                          <div className="Earn-detail-left-value">{item.value}</div>
                        </div>
                      )
                    )}
                    <div className="Exchange-swap-button-container mt-20">
                      <Button variant="primary-action" className="w-100" onClick={deposit}>
                        {getButtonText()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {state.tabIndex === 2 && (
                <div>
                  <div className="Earn-detail-right-buy">
                    <div className="flex justify-between">
                      <div className="Earn-detail-left-subtitle">Staking</div>
                      <div>
                        {userInfo?.amount}
                        <span className="Earn-detail-left-subtitle ml-5">{tokenName}</span>
                      </div>
                    </div>
                    <div className="Earn-detail-right-buy-input mt-10">
                      <input value={withdrawAmount} type="text" onChange={withdrawHandleChange} className="flex-1" />
                    </div>
                  </div>
                  <div className="Earn-detail-right-cell">
                    {/* <div className="Earn-detail-left-subtitle">
                    Note: Withdrawal will automatically transfer the deposit balance to the corresponding Tranche Pool.
                  </div> */}
                    <div className="Exchange-swap-button-container mt-20">
                      <Button variant="primary-action" className="w-100" onClick={withdraw}>
                        {getButtonText()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
