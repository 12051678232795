import React from "react";
import "./LiquidityCard.css";
import RiskTip from "./RiskTip";
import cx from "classnames";
import { isMobileDevice } from "lib/legacy";
import { formatMoney } from "lib/numbers";

export default function TrancheTab({ data }) {
  const { title, apr, perDay, shortTitle, risk, active } = data;
  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const cellClass = "flex-col justify-between";
  return (
    <div>
      <div className="tranche-card-m">
        <div className={cx("Liquidity-tab", { "Liquidity-tab-active": active })}>
          {risk && <RiskTip type={risk}></RiskTip>}
          <div className="Liquidity-m-title"> {userOnMobileDevice ? shortTitle : title}</div>
        </div>
      </div>
      <div className="tranche-card">
        <div className={cx("Liquidity-tab", { "Liquidity-tab-active": active })}>
          {risk && <RiskTip type={risk}></RiskTip>}
          <div className="Liquidity-card-value">{title}</div>
          <div className="flex justify-between aligns-center mt-10">
            <div className={cx(cellClass)}>
              <div className="Liquidity-card-subtitle">Earn DIP per day</div>
              <div className="Liquidity-card-value mt-10">{perDay} </div>
            </div>
            <div className={cx(cellClass, "ml-20")}>
              <div className="Liquidity-card-subtitle">APR</div>
              <div className="Liquidity-card-value mt-10">{apr ? formatMoney(apr, 2) : "---"}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
