import React from "react";
import "./TokenDistribution.css";
import { getTokenIcon } from "config/icons";
import useSWR from "swr";
import { getQuoteLink } from "config/links";
import { formatMoney, formatAmount, formatPercent } from "lib/numbers";
import MTokenDistribution from "./MTokenDistribution";
import { getContract } from "config/contracts";
import Pool from "abis/Pool.json";
import useWallet from "lib/wallets/useWallet";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import { WRAPPED_TOKENS_MAP, getTokenBySymbol } from "config/tokens";
import { getPoolData, getCurrentWeights } from "./service";
import { getConstant } from "config/chains";

function TokenDistribution (props) {
  const { showInDetail } = props;
  const { active, signer } = useWallet();
  const { chainId } = useChainId();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const poolAddress = getContract(chainId, "Pool");
  const BTC = getTokenBySymbol(chainId, "BTC");
  const ETH = getTokenBySymbol(chainId, "ETH");

  const USDT = getTokenBySymbol(chainId, defaultCollateralSymbol);
  // const FTM = WRAPPED_TOKENS_MAP[chainId];
  // const USDC = getTokenBySymbol(chainId, "USDC");
  // const DAI = getTokenBySymbol(chainId, "DAI");
  const { data: quotes } = useSWR([getQuoteLink(chainId)], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const getPrice = (symbol, isReturnNumber) => {
    if (!quotes) return "--";
    if (symbol == "USDT" || symbol == "USDC" || symbol == "DAI") return 1;
    for (let item of quotes) {
      if (item.symbol.indexOf(symbol) !== -1) {
        if (isReturnNumber) {
          return parseFloat(item.lastPrice);
        }
        return formatMoney(parseFloat(item.lastPrice), getDecimals(item.symbol));
      }
    }
    return 1;
  };

  const getDecimals = (symbol) => {
    switch (symbol) {
      case "FTMUSDT":
        return 4;
      default:
        return 2;
    }
  };

  const { data: btcPoolData } = useSWR([`TokenDistribution:btcPool:${active}`, chainId, poolAddress, "poolTokens"], {
    fetcher: contractFetcher(signer, Pool, [BTC.address]),
  });

  const { data: btcTargetWeightData } = useSWR(
    [`TokenDistribution:btcPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
    {
      fetcher: contractFetcher(signer, Pool, [BTC.address]),
    }
  );

  const { data: ethPoolData } = useSWR([`TokenDistribution:ethPool:${active}`, chainId, poolAddress, "poolTokens"], {
    fetcher: contractFetcher(signer, Pool, [ETH.wrappedAddress]),
  });

  const { data: ethTargetWeightData } = useSWR(
    [`TokenDistribution:ethPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
    {
      fetcher: contractFetcher(signer, Pool, [ETH.wrappedAddress]),
    }
  );

  const { data: usdtPoolData } = useSWR([`TokenDistribution:usdtPool:${active}`, chainId, poolAddress, "poolTokens"], {
    fetcher: contractFetcher(signer, Pool, [USDT.address]),
  });

  const { data: usdtTargetWeightData } = useSWR(
    [`TokenDistribution:usdtPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
    {
      fetcher: contractFetcher(signer, Pool, [USDT.address]),
    }
  );

  // const { data: ftmPoolData } = useSWR([`TokenDistribution:ftmPool:${active}`, chainId, poolAddress, "poolTokens"], {
  //   fetcher: contractFetcher(signer, Pool, [FTM.address]),
  // });

  // const { data: ftmTargetWeightData } = useSWR(
  //   [`TokenDistribution:ftmPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
  //   {
  //     fetcher: contractFetcher(signer, Pool, [FTM.address]),
  //   }
  // );

  // const { data: usdcPoolData } = useSWR([`TokenDistribution:usdcPool:${active}`, chainId, poolAddress, "poolTokens"], {
  //   fetcher: contractFetcher(signer, Pool, [USDC.address]),
  // });

  // const { data: usdcTargetWeightData } = useSWR(
  //   [`TokenDistribution:usdcPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
  //   {
  //     fetcher: contractFetcher(signer, Pool, [USDC.address]),
  //   }
  // );

  // const { data: daiPoolData } = useSWR([`TokenDistribution:daiPool:${active}`, chainId, poolAddress, "poolTokens"], {
  //   fetcher: contractFetcher(signer, Pool, [DAI.address]),
  // });

  // const { data: daiTargetWeightData } = useSWR(
  //   [`TokenDistribution:daiPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
  //   {
  //     fetcher: contractFetcher(signer, Pool, [DAI.address]),
  //   }
  // );

  const ethPool = getPoolData(ethPoolData, ETH, getPrice(ETH.baseSymbol, true), ethTargetWeightData);

  const btcPool = getPoolData(btcPoolData, BTC, getPrice(BTC.symbol, true), btcTargetWeightData);

  const usdtPool = getPoolData(usdtPoolData, USDT, getPrice(USDT.symbol, true), usdtTargetWeightData);
  // const ftmPool = getPoolData(ftmPoolData, FTM, getPrice("ETH", true), ftmTargetWeightData); //todo fix to fantom testnet

  // const usdcPool = getPoolData(ftmPoolData, FTM, getPrice("USDC", true), usdcTargetWeightData);
  // const daiPool = getPoolData(ftmPoolData, FTM, getPrice("DAI", true), daiTargetWeightData);

  const currentWeights = getCurrentWeights(btcPool?.value, ethPool?.value, usdtPool?.balance);
  const title = ["Token", "Price", "Amount", "Value", "Weight/Target", "Utilization", "Fee"];
  const cells = [
    {
      symbol: "BTC",
      amount: btcPool?.balance ? btcPool?.balance : "---",
      value: `$${btcPool?.value ? btcPool?.value : "---"}`,
      weight: `${currentWeights ? currentWeights[BTC.symbol] : "--"}% / ${btcPool?.targetWeight ? btcPool?.targetWeight : "---"
        }%`,
      icon: BTC.imageUrl,
      utilization: "--%",
      fee: "--%",
    },
    {
      symbol: "ETH",
      amount: ethPool?.balance ? ethPool?.balance : "---",
      value: `$${ethPool?.value ? ethPool?.value : "---"}`,
      weight: `${currentWeights ? currentWeights[ETH.symbol] : "--"}% / ${ethPool?.targetWeight ? ethPool?.targetWeight : "---"
        }%`,
      icon: ETH.imageUrl,

      utilization: "--%",
      fee: "--%",
    },
    // {
    //   symbol: "FTM",
    //   amount: ftmPool?.balance ? ftmPool?.balance : "---",
    //   value: `$${ftmPool?.value ? ftmPool?.value : "---"}`,
    //   weight: `${currentWeights ? currentWeights["FTM"] : "--"}% / ${
    //     ftmPool?.targetWeight ? ftmPool?.targetWeight : "---"
    //   }%`,
    //   icon: FTM.imageUrl,

    //   utilization: "--%",
    //   fee: "--%",
    // },
    //todo fix to fantom testnet
    {
      symbol: defaultCollateralSymbol,
      amount: usdtPool?.balance ? usdtPool?.balance : "---",
      value: `$${usdtPool?.balance ? usdtPool?.balance : "---"}`,
      weight: `${currentWeights ? currentWeights[USDT.symbol] : "--"}% / ${usdtPool?.targetWeight ? usdtPool?.targetWeight : "---"
        }%`,
      icon: USDT.imageUrl,

      utilization: "--%",
      fee: "--%",
    },
    // {
    //   symbol: "USDC",
    //   amount: usdcPool?.balance ? usdcPool?.balance : "---",
    //   value: `$${usdcPool?.balance ? usdcPool?.balance : "---"}`,
    //   weight: `${currentWeights ? currentWeights["USDC"] : "--"}% / ${
    //     usdcPool?.targetWeight ? usdcPool?.targetWeight : "---"
    //   }%`,
    //   icon: USDC.imageUrl,

    //   utilization: "--%",
    //   fee: "--%",
    // },
    // {
    //   symbol: "DAI",
    //   amount: daiPool?.balance ? daiPool?.balance : "---",
    //   value: `$${daiPool?.balance ? daiPool?.balance : "---"}`,
    //   weight: `${currentWeights ? currentWeights["DAI"] : "--"}% / ${
    //     daiPool?.targetWeight ? daiPool?.targetWeight : "---"
    //   }%`,
    //   icon: DAI.imageUrl,

    //   utilization: "--%",
    //   fee: "--%",
    // },
  ];

  return (
    <div>
      <div className="token-dis-table-m">
        {cells.map((item, index) => (
          <div>
            <MTokenDistribution data={{ ...item, icon: item.icon, price: getPrice(item.symbol) }} />
          </div>
        ))}
      </div>
      <table className="token-dis-table">
        <thead>
          <tr>{title.map((item, index) => (!showInDetail && item == "Fee" ? <></> : <th key={index}>{item}</th>))}</tr>
        </thead>
        <tbody>
          {cells.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="flex aligns-center ">
                  <img src={item.icon} alt={item.symbol} className="icon-sm"></img>
                  <div className="ml-10">{item.symbol}</div>
                </div>
              </td>
              <td>${getPrice(item.symbol)}</td>
              <td>{item.amount}</td>
              <td>{item.value}</td>
              <td>{item.weight}</td>
              <td>{item.utilization}</td>
              {!showInDetail ? <></> : <td>{item.fee}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TokenDistribution;
