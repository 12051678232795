import React from "react";
import "./TokenDistribution.css";
import { getTokenIcon } from "config/icons";
import useSWR from "swr";
import { getQuoteLink } from "config/links";
import { formatMoney, formatAmount, formatPercent } from "lib/numbers";
import MTokenDistribution from "./MTokenDistribution";
import { getContract } from "config/contracts";
import Pool from "abis/Pool.json";
import useWallet from "lib/wallets/useWallet";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import { WRAPPED_TOKENS_MAP, getTokenBySymbol } from "config/tokens";
import { getTranchePoolData, getCurrentWeights } from "./service";
import { getConstant } from "config/chains";

function TrancheTokenDistribution (props) {
  const { showInDetail, trancheAddress } = props;
  const { active, signer } = useWallet();
  const { chainId } = useChainId();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const poolAddress = getContract(chainId, "Pool");
  const BTC = getTokenBySymbol(chainId, "BTC");
  const ETH = getTokenBySymbol(chainId, "ETH");
  const USDT = getTokenBySymbol(chainId, defaultCollateralSymbol);
  // const FTM = WRAPPED_TOKENS_MAP[chainId];
  // const USDC = getTokenBySymbol(chainId, "USDC");
  // const DAI = getTokenBySymbol(chainId, "DAI");
  const { data: quotes } = useSWR([getQuoteLink(chainId)], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const getPrice = (symbol, isReturnNumber) => {
    if (!quotes) return "--";
    if (symbol == "USDT" || symbol == "USDC" || symbol == "DAI") return 1;
    for (let item of quotes) {
      if (item.symbol.indexOf(symbol) !== -1) {
        if (isReturnNumber) {
          return parseFloat(item.lastPrice);
        }
        return formatMoney(parseFloat(item.lastPrice), getDecimals(item.symbol));
      }
    }
    return 1;
  };

  const getDecimals = (symbol) => {
    switch (symbol) {
      case "FTMUSDT":
        return 4;
      default:
        return 2;
    }
  };

  const poolMethodName = "trancheAssets";
  const { data: btcPoolData } = useSWR(
    [`TokenDistribution:btcPool:${active}`, chainId, poolAddress, poolMethodName, trancheAddress, BTC.address],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const { data: btcTargetWeightData } = useSWR(
    [`TokenDistribution:btcPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
    {
      fetcher: contractFetcher(signer, Pool, [BTC.address]),
    }
  );

  const { data: ethPoolData } = useSWR(
    [`TokenDistribution:ethPool:${active}`, chainId, poolAddress, poolMethodName, trancheAddress, ETH.wrappedAddress],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const { data: ethTargetWeightData } = useSWR(
    [`TokenDistribution:ethPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
    {
      fetcher: contractFetcher(signer, Pool, [ETH.wrappedAddress]),
    }
  );

  const { data: usdtPoolData } = useSWR(
    [`TokenDistribution:usdtPool:${active}`, chainId, poolAddress, poolMethodName, trancheAddress, USDT.address],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const { data: usdtTargetWeightData } = useSWR(
    [`TokenDistribution:usdtPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
    {
      fetcher: contractFetcher(signer, Pool, [USDT.address]),
    }
  );

  // const { data: ftmPoolData } = useSWR(
  //   [`TokenDistribution:ftmPool:${active}`, chainId, poolAddress, poolMethodName, trancheAddress, FTM.address],
  //   {
  //     fetcher: contractFetcher(signer, Pool),
  //   }
  // );

  // const { data: ftmTargetWeightData } = useSWR(
  //   [`TokenDistribution:ftmPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
  //   {
  //     fetcher: contractFetcher(signer, Pool, [FTM.address]),
  //   }
  // );

  // const { data: usdcPoolData } = useSWR(
  //   [`TokenDistribution:usdcPool:${active}`, chainId, poolAddress, poolMethodName, trancheAddress, USDC.address],
  //   {
  //     fetcher: contractFetcher(signer, Pool),
  //   }
  // );

  // const { data: usdcTargetWeightData } = useSWR(
  //   [`TokenDistribution:usdcPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
  //   {
  //     fetcher: contractFetcher(signer, Pool, [USDC.address]),
  //   }
  // );

  // const { data: daiPoolData } = useSWR(
  //   [`TokenDistribution:daiPool:${active}`, chainId, poolAddress, poolMethodName, trancheAddress, DAI.address],
  //   {
  //     fetcher: contractFetcher(signer, Pool),
  //   }
  // );

  // const { data: daiTargetWeightData } = useSWR(
  //   [`TokenDistribution:daiPoolTargetWeight:${active}`, chainId, poolAddress, "targetWeights"],
  //   {
  //     fetcher: contractFetcher(signer, Pool, [DAI.address]),
  //   }
  // );
  const { data: btcTotal } = useSWR([`TokenDistribution:btcTotal:${active}`, chainId, poolAddress, "poolTokens"], {
    fetcher: contractFetcher(signer, Pool, [BTC.address]),
  });

  const { data: ethTotal } = useSWR([`TokenDistribution:ethTotal:${active}`, chainId, poolAddress, "poolTokens"], {
    fetcher: contractFetcher(signer, Pool, [ETH.wrappedAddress]),
  });

  const { data: usdtTotal } = useSWR([`TokenDistribution:usdtTotal:${active}`, chainId, poolAddress, "poolTokens"], {
    fetcher: contractFetcher(signer, Pool, [USDT.address]),
  });
  // const { data: ftmTotal } = useSWR([`TokenDistribution:ftmTotal:${active}`, chainId, poolAddress, "poolTokens"], {
  //   fetcher: contractFetcher(signer, Pool, [FTM.address]),
  // });
  // const { data: usdcTotal } = useSWR([`TokenDistribution:usdcTotal:${active}`, chainId, poolAddress, "poolTokens"], {
  //   fetcher: contractFetcher(signer, Pool, [USDC.address]),
  // });

  // const { data: daiTotal } = useSWR([`TokenDistribution:daiTotal:${active}`, chainId, poolAddress, "poolTokens"], {
  //   fetcher: contractFetcher(signer, Pool, [DAI.address]),
  // });

  const ethPool = getTranchePoolData(ethPoolData, ETH, getPrice(ETH.symbol, true), ethTargetWeightData, ethTotal);

  const btcPool = getTranchePoolData(btcPoolData, BTC, getPrice(BTC.symbol, true), btcTargetWeightData, btcTotal);

  const usdtPool = getTranchePoolData(usdtPoolData, USDT, getPrice(USDT.symbol, true), usdtTargetWeightData, usdtTotal);
  // const ftmPool = getTranchePoolData(ftmPoolData, FTM, getPrice("ETH", true), ftmTargetWeightData, ftmTotal); //todo fix to fantom testnet
  // const usdcPool = getTranchePoolData(usdcPoolData, USDC, getPrice("USDC", true), usdcTargetWeightData, usdcTotal);
  // const daiPool = getTranchePoolData(usdcPoolData, DAI, getPrice("DAI", true), daiTargetWeightData, daiTotal);

  const currentWeights = getCurrentWeights(btcPool?.totalValue, ethPool?.totalValue, usdtPool?.totalValue);

  const title = ["Token", "Price", "Amount", "Value", "Weight/Target", "Utilization", "Fee"];
  const cells = [
    {
      symbol: "BTC",
      amount: btcPool?.balance ? btcPool?.balance : "---",
      value: `$${btcPool?.value ? btcPool?.value : "---"}`,
      weight: `${currentWeights ? currentWeights[BTC.symbol] : "--"}% / ${btcPool?.targetWeight ? btcPool?.targetWeight : "---"
        }%`,
      icon: BTC.imageUrl,
      utilization: `${btcPool?.utilization ? btcPool?.utilization : "---"}%`,
      fee: "--%",
    },
    {
      symbol: "ETH",
      amount: ethPool?.balance ? ethPool?.balance : "---",
      value: `$${ethPool?.value ? ethPool?.value : "---"}`,
      weight: `${currentWeights ? currentWeights[ETH.symbol] : "--"}% / ${ethPool?.targetWeight ? ethPool?.targetWeight : "---"
        }%`,
      icon: ETH.imageUrl,
      utilization: `${ethPool?.utilization ? ethPool?.utilization : "---"}%`,
      fee: "--%",
    },
    // {
    //   symbol: "FTM",
    //   amount: ftmPool?.balance ? ftmPool?.balance : "---",
    //   value: `$${ftmPool?.value ? ftmPool?.value : "---"}`,
    //   weight: `${currentWeights ? currentWeights["FTM"] : "--"}% / ${
    //     ftmPool?.targetWeight ? ftmPool?.targetWeight : "---"
    //   }%`,
    //   icon: FTM.imageUrl,
    //   utilization: `${ftmPool?.utilization ? ftmPool?.utilization : "---"}%`,
    //   fee: "--%",
    // },
    //todo fix to fantom testnet

    {
      symbol: defaultCollateralSymbol,
      amount: usdtPool?.balance ? usdtPool?.balance : "---",
      value: `$${usdtPool?.balance ? usdtPool?.balance : "---"}`,
      weight: `${currentWeights ? currentWeights[USDT.symbol] : "--"}% / ${usdtPool?.targetWeight ? usdtPool?.targetWeight : "---"
        }%`,
      icon: USDT.imageUrl,
      utilization: `${usdtPool?.utilization ? usdtPool?.utilization : "---"}%`,
      fee: "--%",
    },
    // {
    //   symbol: "USDC",
    //   amount: usdcPool?.balance ? usdcPool?.balance : "---",
    //   value: `$${usdcPool?.balance ? usdcPool?.balance : "---"}`,
    //   weight: `${currentWeights ? currentWeights[USDC.symbol] : "--"}% / ${
    //     usdcPool?.targetWeight ? usdcPool?.targetWeight : "---"
    //   }%`,
    //   icon: USDC.imageUrl,
    //   utilization: `${usdcPool?.utilization ? usdcPool?.utilization : "---"}%`,
    //   fee: "--%",
    // },
    // {
    //   symbol: "DAI",
    //   amount: daiPool?.balance ? daiPool?.balance : "---",
    //   value: `$${daiPool?.balance ? daiPool?.balance : "---"}`,
    //   weight: `${currentWeights ? currentWeights[DAI.symbol] : "--"}% / ${
    //     daiPool?.targetWeight ? daiPool?.targetWeight : "---"
    //   }%`,
    //   icon: DAI.imageUrl,
    //   utilization: `${daiPool?.utilization ? daiPool?.utilization : "---"}%`,
    //   fee: "--%",
    // },
  ];

  return (
    <div>
      <div className="token-dis-table-m">
        {cells.map((item, index) => (
          <div key={index}>
            <MTokenDistribution data={{ ...item, icon: item.icon, price: getPrice(item.symbol) }} />
          </div>
        ))}
      </div>
      <table className="token-dis-table">
        <thead>
          <tr>{title.map((item, index) => (!showInDetail && item == "Fee" ? <></> : <th key={index}>{item}</th>))}</tr>
        </thead>
        <tbody>
          {cells.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="flex aligns-center ">
                  <img src={item.icon} alt={item.symbol} className="icon-sm"></img>
                  <div className="ml-10">{item.symbol}</div>
                </div>
              </td>
              <td>${getPrice(item.symbol)}</td>
              <td>{item.amount}</td>
              <td>{item.value}</td>
              <td>{item.weight}</td>
              <td>{item.utilization}</td>
              {!showInDetail ? <></> : <td>{item.fee}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TrancheTokenDistribution;
