import React, { useState, useEffect } from "react";
import "./Faucet.css";
import { TOKENS, getTokenBySymbols } from "config/tokens";
import { useChainId } from "lib/chains";
import DropDownIcon from "img/ic_dropdown.png";
import DiscordImg from "img/ic_discord_blue.png";
import { bindDiscord, dcUserInfoUrl } from "lib/api";
import { ethers } from "ethers";
import TestnetToken from "abis/TestnetToken.json";
import { expandOfStringDecimals } from "lib/numbers";
import Button from "components/Button/Button";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import useWallet from "lib/wallets/useWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";

function Faucet () {

  const { active, account, signer } = useWallet();
  const { openConnectModal } = useConnectModal();
  const { chainId } = useChainId();
  const [tokens, setTokens] = useState();
  const [dcUserInfo, setDcUserInfo] = useState({ avatarUrl: undefined, userName: undefined });

  const [selectedToken, setSelectedToken] = useState();
  const [showTokenList, setShowTokenList] = useState(false);
  const faucetFTM = "https://faucet.fantom.network/";
  const getAmount = () => {
    const symbol = selectedToken?.symbol;
    if (!symbol || symbol === "FTM") {
      return faucetFTM;
    } else if (symbol === "BTC") {
      return 1;
    } else if (symbol === "ETH") {
      return 10;
    } else if (symbol === "USDT") {
      return 100;
    }
  };
  useEffect(() => {
    const allTokens = TOKENS[chainId];
    setTokens(allTokens.filter((t) => t.symbol === "FTM" || t.symbol === "USDT"));
  }, []);
  useEffect(() => {
    if (tokens?.length > 0) setSelectedToken(tokens[0]);
  }, [tokens]);

  const clickShowToken = () => {
    setShowTokenList(!showTokenList);
  };

  const selectToken = (index) => {
    setSelectedToken(tokens[index]);
    setShowTokenList(false);
  };
  const [mining, setMining] = useState(false);
  const mint = () => {
    // helperToast.error("Need bind discord account!");
    // return;
    if (selectedToken.symbol === "FTM") {
      window.open(faucetFTM);
      return;
    }
    if (active) {
      setMining(true);
      const address = selectedToken.address;
      const contract = new ethers.Contract(address, TestnetToken.abi, signer);
      callContract(chainId, contract, "mintInTestnet", [expandOfStringDecimals(getAmount(), selectedToken.decimals)], {
        sentMsg: `Request testnet ${selectedToken.symbol} submitted!`,
        failMsg: `Request testnet ${selectedToken.symbol}  failed.`,
      })
        .then(async (res) => {
          // setIsVisible(false);
        })
        .finally(() => {
          setMining(false);
        });
    } else {
      openConnectModal();
    }
  };

  const clickVerificationDiscord = () => {
    if (!account) {
      helperToast.error("Please connect wallet!");
      return;
    }
    if (!dcUserInfo.userName) bindDiscord(account);
    else helperToast.error("Already Verification Discord!");
  };

  useEffect(() => {
    try {
      if (account) {
        fetch(`${dcUserInfoUrl}/${account}`).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setDcUserInfo(data.errno === 0 ? data.data : { avatarUrl: undefined, userName: undefined });
            });
          }
        });
      }
    } catch (e) { }
  }, [account]);
  return (
    <div className="pb-30">
      <div className="Earn-header-bg">
        <div className="Earn-header default-container flex-col justify-center">
          <div className="Earn-header-title ">Testnet Opera Faucet</div>
          <div className="">Feel free to get test FTMs/BTCs/ETHs/USDTs to your wallet</div>
        </div>
      </div>

      <div className="flex  justify-center">
        <div className="Earn-detail-right-buy flex-1" style={{ maxWidth: "50rem" }}>
          <h1>Testnet Opera Faucet</h1>
          <div className="mt-20">
            <div
              className="Activity-discord flex aligns-center"
              onClick={() => {
                clickVerificationDiscord();
              }}
            >
              <img src={DiscordImg} alt="discord" className="icon-sm"></img>
              {dcUserInfo.avatarUrl && <img src={dcUserInfo.avatarUrl} alt="discord" className="icon-sm ml-10"></img>}
              <div className="ml-10">
                {dcUserInfo.userName
                  ? `Hi ${dcUserInfo.userName}, Your discord verification success!`
                  : "Click here to Verification your Discord Account!"}
              </div>
            </div>
          </div>
          <div className="Earn-detail-right-buy-input mt-20">
            <div className="flex justify-between aligns-center">
              <input value={getAmount()} type="text" display={true} className="flex-1" />

              <div className="Currency-selected-group flex justify-around aligns-center mr-10" onClick={clickShowToken}>
                {selectedToken?.imageUrl && (
                  <img src={selectedToken?.imageUrl} className="icon-xs " alt={selectedToken?.symbol}></img>
                )}
                <div className="ml-5"> {selectedToken?.symbol} </div>
                <img src={DropDownIcon} className="icon-ss ml-5" alt="more"></img>
              </div>
              {showTokenList && (
                <div className="Currency-dropdown">
                  {tokens &&
                    tokens.map((token, index) => (
                      <div
                        className="flex aligns-center App-header-dropdown-menu"
                        onClick={() => {
                          selectToken(index);
                        }}
                        key={index}
                      >
                        <img src={token.imageUrl} alt={token.symbol} className="icon-sm"></img>
                        <div className="ml-10">{token.symbol} </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="Exchange-swap-button-container mt-20">
            <Button variant="primary-action" className="w-100" onClick={mint} disabled={!dcUserInfo.userName}>
              {!mining ? "Request" : "Requesting..."} Testnet {selectedToken?.symbol}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faucet;
