import React, { useState, useEffect } from "react";
import cx from "classnames";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import useWallet from "lib/wallets/useWallet";
import { contractFetcher, callContract } from "lib/contracts";
import VestingABI from "abis/Vesting.json";
import { ethers } from "ethers";
import { formatUTCTime } from "lib/dates";

import useSWR from "swr";
import { formatAmount } from "lib/numbers";
import { defaultTokenDecimals } from "lib/pool";

function Vesting () {
  const [leftIndex, setLeftIndex] = useState(0);
  const { chainId } = useChainId();
  const { active, signer, account } = useWallet();
  const rowDefaultClass = "flex aligns-center justify-between";

  const leftMenuCell = [
    {
      content: "DaoVesting",
    },
    {
      content: "LpVesting",
    },
    {
      content: "TradeVesting",
    },
    {
      content: "TeamVesting",
    },
  ];

  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const { data: claimable } = useSWR(
    [`${leftIndex}Vesting:claimable${active}`, chainId, selectedContract, "claimableDIP"],
    {
      fetcher: contractFetcher(signer, VestingABI),
    }
  );
  const { data: start } = useSWR([`${leftIndex}Vesting:start${active}`, chainId, selectedContract, "START"], {
    fetcher: contractFetcher(signer, VestingABI),
  });
  const { data: end } = useSWR([`${leftIndex}Vesting:end${active}`, chainId, selectedContract, "END"], {
    fetcher: contractFetcher(signer, VestingABI),
  });
  const { data: claimedAmount } = useSWR(
    [`${leftIndex}Vesting:claimedAmount${active}`, chainId, selectedContract, "claimedAmount"],
    {
      fetcher: contractFetcher(signer, VestingABI),
    }
  );
  const { data: allocation } = useSWR(
    [`${leftIndex}Vesting:allocation${active}`, chainId, selectedContract, "allocation"],
    {
      fetcher: contractFetcher(signer, VestingABI),
    }
  );
  const { data: vestingAddress } = useSWR(
    [`${leftIndex}Vesting:vesting${active}`, chainId, selectedContract, "vesting"],
    {
      fetcher: contractFetcher(signer, VestingABI),
    }
  );
  useEffect(() => {
    let vestingContracts = [];
    for (let cell of leftMenuCell) {
      vestingContracts.push(getContract(chainId, cell.content));
    }
    setContracts(vestingContracts);

    setSelectedContract(vestingContracts[0]);
  }, []);

  const menuClick = (index) => {
    setLeftIndex(index);
    setSelectedContract(contracts[index]);
  };
  const claim = async () => {
    callContract(
      chainId,
      new ethers.Contract(selectedContract, VestingABI.abi, signer),
      "withdraw",
      [claimable, account],
      {
        sentMsg: `Claim submitted!`,
        failMsg: `Claim failed.`,
        // setPendingTxns,
      }
    )
      .then(async (res) => {
        // setIsVisible(false);
      })
      .finally(() => {
        // setIsStaking(false);
      });
  };

  const isVestingAddress = () => {
    return account && vestingAddress && account.toUpperCase() === vestingAddress.toUpperCase();
    // return true;
  };
  return (
    <div className="default-bg">
      <div className="default-container">
        <div className="pb-30 ">
          <div className="Earn-header-bg">
            <div className="Earn-header default-container flex-col justify-center">
              <div className="Earn-header-title ">Vesting page</div>
              <div className="">Vesting info page for DaoVesting, LpVesting, TradeVesting, TeamVesting</div>
            </div>
          </div>
          <div className="row mt-20">
            <div className=" col-12 col-md-2 ">
              <div className="row">
                {leftMenuCell.map((item, index) => (
                  <div
                    className={cx("col-4 col-md-12 Dao-left-cell flex aligns-center ", {
                      "Dao-left-cell-active": leftIndex === index,
                    })}
                    key={index}
                    onClick={() => menuClick(index)}
                  >
                    {item.icon && (
                      <img
                        className="icon-xs"
                        src={leftIndex === index ? item.activeIcon : item.icon}
                        alt={item.content}
                      />
                    )}
                    <div className="ml-10">{item.content}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" col-12 col-md-10">
              {isVestingAddress() ? (
                <div className="row">
                  <div className="col-12 col-md-8 order-md-1 order-1">
                    <div className="order-1">
                      <div className={cx("Earn-detail-left-header", rowDefaultClass)}>
                        <div className="flex  aligns-center">
                          <div className="ml-10">{leftMenuCell[leftIndex]?.content}</div>
                        </div>
                        <div className="default-btn" onClick={claim}>
                          Claim
                        </div>
                      </div>
                      <div className="Earn-detail-left-content">
                        <div className={cx(rowDefaultClass, "")}>
                          <div className="Earn-detail-left-subtitle">Start Time</div>
                          <div className="Earn-detail-left-value">
                            {start && formatUTCTime(new Date(start?.toString() * 1000))} UTC
                          </div>
                        </div>
                        <div className={cx(rowDefaultClass, "mt-20")}>
                          <div className="Earn-detail-left-subtitle">End Time</div>
                          <div className="Earn-detail-left-value">
                            {end && formatUTCTime(new Date(end?.toString() * 1000))} UTC
                          </div>
                        </div>

                        <div className={cx(rowDefaultClass, "mt-20")}>
                          <div className="Earn-detail-left-subtitle">Total Allocation</div>
                          <div className="Earn-detail-left-value flex aligns-center">
                            <div> {formatAmount(allocation?.toString(), defaultTokenDecimals, 4, true)}</div>
                            <div className="Earn-detail-left-subtitle ml-5">DIP</div>
                          </div>
                        </div>
                        <div className={cx(rowDefaultClass, "mt-20")}>
                          <div className="Earn-detail-left-subtitle">Claimable</div>
                          <div className="Earn-detail-left-value flex aligns-center">
                            <div> {formatAmount(claimable?.toString(), defaultTokenDecimals, 4, true)}</div>
                            <div className="Earn-detail-left-subtitle ml-5">DIP</div>
                          </div>
                        </div>
                        <div className={cx(rowDefaultClass, "mt-20")}>
                          <div className="Earn-detail-left-subtitle">Claimed Amount</div>
                          <div className="Earn-detail-left-value flex aligns-center">
                            <div> {formatAmount(claimedAmount?.toString(), defaultTokenDecimals, 4, true)}</div>
                            <div className="Earn-detail-left-subtitle ml-5">DIP</div>
                          </div>
                        </div>
                        <div className={cx(rowDefaultClass, "mt-20")}>
                          <div className="Earn-detail-left-subtitle">Vesting Address</div>
                          <div className="Earn-detail-left-value">{vestingAddress?.toString()}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-20">You do not have permission to view this page</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vesting;
