import { formatMoney } from "lib/numbers";

const AssetsOverview = ({ walletTotalValue, earnTotalValue }) => {
  const data = [
    {
      title: "Wallets",
      value: walletTotalValue ? `$${formatMoney(walletTotalValue, 6)}` : "---",
    },
    {
      title: "Earn",
      value: earnTotalValue ? `$${formatMoney(earnTotalValue, 6)}` : "---",
    },

    {
      title: "In Orders",
      value: "---",
    },
    {
      title: "In Positions",
      value: "---",
    },
  ];
  return (
    <div>
      <div className="ml-10 mt-20">
        <div className="fs-2">Overview</div>
        <div className="Assets-total-card mt-10">
          <div className="Earn-detail-left-subtitle">Total</div>
          <div className="fs-3 fw-bold">{formatMoney(walletTotalValue + earnTotalValue, 2)} USD</div>
        </div>
      </div>

      <table className="table table-dark table-hover table-striped align-middle mt-20" style={{ marginBottom: 0 }}>
        <thead className="Order-header">
          <tr>
            <th scope="col">Token</th>
            <th scope="col">USD Value</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="fw-bold">{item.title}</div>
                </td>
                <td>{item.value}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssetsOverview;
