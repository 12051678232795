import React, { useEffect, useState } from "react";
import "./Earn.css";
import TrancheCard from "components/LiquidityCard/TrancheCard";
import StakeCard from "components/LiquidityCard/StakeCard";
import DipIcon from "img/DIP-token.png";
import FTMIcon from "img/FTM.png";
import ETHIcon from "img/ETH.png";
import SnrIcon from "img/ic_snr.png";
import JnrIcon from "img/ic_jnr.png";
import MezzIcon from "img/ic_mezz.png";
import MultiIcon from "img/ic_multi.png";
import STGIcon from "img/ic_stg.png";
import { Link } from "react-router-dom";
import useWallet from "lib/wallets/useWallet";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import LiquidityBootstrapping from "abis/LiquidityBootstrapping.json";
import LPLiquidityBootstrapping from "abis/LPLiquidityBootstrapping.json";
import useSWR from "swr";
import LBPHeaderImg from "img/lbp-new.jpg";
import { contractFetcher } from "lib/contracts";
import { getPoolData } from "./services";
import TokenDistribution from "components/TokenDistribution";
import { useDipPairPrice } from "domain/prices";
import { formatMoney } from "lib/numbers";
import { useDLPPrices } from "domain/pool";
import Footer from "components/Footer/Footer";

export default function Earn () {
  const { active, signer } = useWallet();
  const { chainId } = useChainId();

  const dipPairPrice = useDipPairPrice(chainId, signer);
  const { data: snrLBPPoolData } = useSWR([`SnrLBP:info${active}`, chainId, getContract(chainId, "SnrLBP"), "info"], {
    fetcher: contractFetcher(signer, LiquidityBootstrapping),
  });

  const { data: mezzLBPPoolData } = useSWR(
    [`MezzLBP:info${active}`, chainId, getContract(chainId, "MezzLBP"), "info"],
    {
      fetcher: contractFetcher(signer, LiquidityBootstrapping),
    }
  );
  const { data: jnrLBPPoolData } = useSWR([`JnrLBP:info${active}`, chainId, getContract(chainId, "JnrLBP"), "info"], {
    fetcher: contractFetcher(signer, LiquidityBootstrapping),
  });
  const { data: dipFtmLBPPoolData } = useSWR(
    [`DIP_ETHLBP:info${active}`, chainId, getContract(chainId, "DIP_ETHLBP"), "info"],
    {
      fetcher: contractFetcher(signer, LPLiquidityBootstrapping),
    }
  );
  const dlpPrices = useDLPPrices(chainId, signer);
  const snrLBPPool = getPoolData(snrLBPPoolData, false, 0, dipPairPrice, dlpPrices?.jnrDLPPrice);
  const mezzLBPPool = getPoolData(mezzLBPPoolData, false, 1, dipPairPrice, dlpPrices?.mezzDLPPrice);
  const jnrLBPPool = getPoolData(jnrLBPPoolData, false, 2, dipPairPrice, dlpPrices?.mezzDLPPrice);
  const dipFtmLBPPool = getPoolData(dipFtmLBPPoolData, true, 3, dipPairPrice);
  // const multiLBPPool = getPoolData(undefined, false, 4);

  useEffect(() => {
    //  const provider = getProvider(signer, chainId);
    // const snrLBPContract = new ethers.Contract(snrLBPAddress, LiquidityBootstrapping.abi, provider);
    // snrLBPContract.info().then((res) => {
    // });
  }, []);
  const tranches = [
    {
      title: "Senior Tranche",
      risk: 1,
      perDay: snrLBPPool?.perDay ? formatMoney(snrLBPPool?.perDay, 4) : "---",
      price: `$${snrLBPPool?.price ? snrLBPPool?.price : "1.00"}`,
      management: `${snrLBPPool?.totalAmount ? formatMoney(snrLBPPool?.totalAmount, 2) : "---"}`,
      apr: snrLBPPool?.apr ? formatMoney(snrLBPPool?.apr, 2) : "---",
      icons: [SnrIcon],
    },
    {
      title: "Mezzanine Tranche",
      perDay: mezzLBPPool?.perDay ? formatMoney(mezzLBPPool?.perDay, 4) : "---",
      price: `$${mezzLBPPool?.price ? mezzLBPPool?.price : "1.00"}`,
      management: `${mezzLBPPool?.totalAmount ? formatMoney(mezzLBPPool?.totalAmount, 2) : "---"}`,
      apr: mezzLBPPool?.apr ? formatMoney(mezzLBPPool?.apr, 2) : "---",
      icons: [MezzIcon],
    },
    {
      title: "Junior    Tranche",
      risk: 3,
      perDay: jnrLBPPool?.perDay ? formatMoney(jnrLBPPool?.perDay, 4) : "---",
      price: `$${jnrLBPPool?.price ? jnrLBPPool?.price : "1.00"}`,
      management: `${jnrLBPPool?.totalAmount ? formatMoney(jnrLBPPool?.totalAmount, 2) : "---"}`,
      apr: jnrLBPPool?.apr ? formatMoney(jnrLBPPool?.apr, 2) : "---",
      icons: [JnrIcon],
    },
  ];
  const liquiditys = [
    {
      title: "DIP/ETH",
      perDay: dipFtmLBPPool?.perDay ? formatMoney(dipFtmLBPPool?.perDay, 4) : "---",
      management: dipFtmLBPPool?.totalAmount ? `${formatMoney(dipFtmLBPPool?.totalAmount, 2)}` : "---",
      apr: dipFtmLBPPool?.apr ? formatMoney(dipFtmLBPPool?.apr, 2) : "---",
      icons: [DipIcon, ETHIcon],
    },
  ];
  // const multis = {
  //   title: "Stargate Pool",
  //   risk: 3,
  //   perDay: multiLBPPool?.perDay,
  //   price: `$${multiLBPPool?.price ? multiLBPPool?.price : "1.00"}`,
  //   management: `$${multiLBPPool?.totalAmount ? multiLBPPool?.totalAmount : "---"}`,
  //   apr: multiLBPPool?.apr ? multiLBPPool?.apr : "---",
  //   icons: [STGIcon],
  // };
  return (
    <div className="default-bg  default-container page-layout">
      <div className="">
        <img src={LBPHeaderImg} alt="lbp" width={"100%"}></img>
        <div className="Earn-header-title mt-20">LBP DIP/ETH Pool</div>
        <div className="Liquidity-card-subtitle">
          Provide liquidity for DIP/ETH pair on LBP Pool. Deposit LP token here and earn DIP tokens.
        </div>
        {liquiditys.map((item, index) => (
          <div className="mt-20" key={index}>
            <Link to={`/lbpLpDetail`} className="disabled">
              <StakeCard data={item}></StakeCard>
            </Link>
          </div>
        ))}
      </div>
      <div className=" mt-20">
        <div className="Earn-header-title ">LBP Trading Pools </div>
        <div className="Liquidity-card-subtitle">
          Deposit any asset to one or more tranches to start earning protocol revenues (from swaps and leveraged
          trading) as well as DIP token incentives on top.
        </div>
        {tranches.map((item, index) => (
          <div className="mt-20" key={index}>
            <Link to={`/lbpDetail/${index + 1}`} className="disabled">
              <TrancheCard data={item}></TrancheCard>
            </Link>
          </div>
        ))}
      </div>
      {/* <div className="default-container mt-20">
        <div className="Earn-header-title ">LBP Innovation Pools </div>
        <div className="">Deposit STG or USDC to get STG DLP and start earning DIP. </div>
        <div className="mt-20">
          <Link to={`/lbpInnovationDetail`} className="disabled">
            <TrancheCard data={multis}></TrancheCard>
          </Link>
        </div>
      </div> */}
      <div className=" mt-20">
        <div className="Earn-header-title"> Token Distribution</div>
        <div>
          <TokenDistribution></TokenDistribution>
        </div>
      </div>
      <Footer />
    </div>
  );
}
