import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gql } from "@apollo/client";
import { bigNumberify, expandDecimals, formatAmount, parseValue } from "lib/numbers";
import { getDipTradingClient } from "lib/subgraph/clients";
import { getTokenBySymbol, getToken, TOKENS, placeHolderAddress } from "config/tokens";
import { defaultPriceDecimals, defaultTokenDecimals, defaultValueDecimals } from "lib/pool";
import { formatDateTime } from "lib/dates";
import { Token } from "./tokens";

export function useTradeHistory(chainId, account, page) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{ 
         histories(
           where: {owner: "${account.toLowerCase()}" updateType_not: SWAP}
           first: 10
           skip: ${page * 10}
           orderBy: createdAtTimestamp
           orderDirection: desc
         ) {
          id
          liquidatedFeeValue
          liquidatedPrice
          minAmountOut
          owner
          pnl
          side
          size
          status
          tokenIn
          tokenOut
          triggerAboveThreshold
          triggerPrice
          tx
          type
          updateType
          createdAtTimestamp
          amountIn
          amountOut
          borrowFeeValue
          closeFeeValue
          collateralToken
          collateralValue
          executionPrice
          market {
                  id
                  indexToken {
                    symbol
                    price
                    id
                    decimals
                  }
           }
         }
      }`);
      const graphClient = getDipTradingClient(chainId);
      if (!graphClient) {
        return;
      }
      if (page === 0) {
        graphClient.clearStore().then(() => {
          graphClient
            .query({ query })
            .then((res) => {
              const _data = processData(chainId, res);
              setData(_data);
            })
            // eslint-disable-next-line no-console
            .catch(console.warn);
        });
      } else {
        graphClient
          .query({ query })
          .then((res) => {
            const _data = processData(chainId, res);
            setData(_data);
          })
          // eslint-disable-next-line no-console
          .catch(console.warn);
      }
    }
  }, [setData, chainId, account, page]);

  return data;
}

function getNormalTokenSymbol(symbol) {
  if (symbol.indexOf("BTC") !== -1) return "BTC";
  else return symbol;
}

function processData(chainId, res) {
  return res.data.histories.map((item) => {
    const indexToken = getTokenBySymbol(chainId, getNormalTokenSymbol(item.market.indexToken.symbol));
    const { decimals, displayDecimals } = indexToken;
    const priceDecimals = defaultPriceDecimals + (defaultTokenDecimals - decimals);
    const collateralToken = TOKENS[chainId].find(
      (token) => token.address.toUpperCase() === item.collateralToken.toString().toUpperCase()
    );
    return {
      borrowFeeValue: formatAmount(item.borrowFeeValue || 0, defaultValueDecimals, 2, true),
      liquidatedFeeValue: formatAmount(item.liquidatedFeeValue || 0, defaultValueDecimals, 2, true),
      liquidatedPrice: formatAmount(item.liquidatedPrice || 0, priceDecimals, displayDecimals, true),
      closeFeeValue: formatAmount(item.closeFeeValue || 0, defaultValueDecimals, 2, true),
      collateralToken: collateralToken,
      collateralValue: formatAmount(item.collateralValue || 0, defaultValueDecimals, 2, true),
      createTime: formatDateTime(item.createdAtTimestamp),
      executionPrice: formatAmount(item.executionPrice || 0, priceDecimals, displayDecimals, true),
      pnl: formatAmount(item.pnl || 0, defaultValueDecimals, 2, true),
      isLong: item.side == 0,
      size: formatAmount(item.size || 0, defaultValueDecimals, 2, true),
      status: item.status,
      indexToken: indexToken,
      triggerPrice: formatAmount(item.triggerPrice || 0, priceDecimals, displayDecimals, true),
      tx: item.tx,
      type: item.type,
      updateType: item.updateType,
      triggerAboveThreshold: item.triggerAboveThreshold,
    };
  });
}

export function useSwapHistory(chainId, account, page) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{ 
         histories(
           where: {owner: "${account.toLowerCase()}", updateType: SWAP}
           first: 10
           skip: ${page * 10}
           orderBy: createdAtTimestamp
           orderDirection: desc
         ) {
          tokenIn
          tokenOut
          amountIn
          amountOut
          type
          updateType
          status
          tx
          createdAtTimestamp
         }
      }`);
      const graphClient = getDipTradingClient(chainId);
      if (!graphClient) {
        return;
      }
      if (page === 0) {
        graphClient.clearStore().then(() => {
          graphClient
            .query({ query })
            .then((res) => {
              const _data = processSwapData(chainId, res);
              setData(_data);
            })
            // eslint-disable-next-line no-console
            .catch(console.warn);
        });
      } else {
        graphClient
          .query({ query })
          .then((res) => {
            const _data = processSwapData(chainId, res);
            setData(_data);
          })
          // eslint-disable-next-line no-console
          .catch(console.warn);
      }
    }
  }, [setData, chainId, account, page]);

  return data;
}

function processSwapData(chainId, res) {
  return res.data.histories.map((item) => {
    const tokenInAddr = item.tokenIn.toString().toUpperCase();
    const tokenOutAddr = item.tokenOut.toString().toUpperCase();
    const ethAddr = placeHolderAddress.toUpperCase();

    const tokenIn: Token | undefined = TOKENS[chainId].find(
      (token) => token.address.toUpperCase() === tokenInAddr || (tokenInAddr === ethAddr && token.isNative)
    );
    //@ts-ignore
    const { decimals } = tokenIn;
    const tokenOut = TOKENS[chainId].find(
      (token) => token.address.toUpperCase() === tokenOutAddr || (tokenOutAddr === ethAddr && token.isNative)
    );

    return {
      tokenIn: tokenIn,
      tokenOut: tokenOut,
      amountIn: formatAmount(item.amountIn || 0, decimals, 4, true),
      //@ts-ignore
      amountOut: formatAmount(item.amountOut || 0, tokenOut.decimals, 4, true),
      createTime: formatDateTime(item.createdAtTimestamp),
      status: item.status,
      tx: item.tx,
      type: item.type,
      updateType: item.updateType,
    };
  });
}

export function useExpBatchData(chainId, account, page) {
  const [data, setData] = useState();
  useEffect(() => {
    if (account) {
      const query = gql(`{
          loyaltyUserBatches(
            orderBy: id
            orderDirection: desc
            first: 10
            skip: ${page * 10}
            where: {user: "${account}", batch_: {}}
          ) {
            amount
            batchId
            id
            lastRewardTime
            user
            batch {
              allocatedTime
              batchVestingDuration
              id
              rewardAmount
              rewardPerShare
              startTime
              totalBalance
            }
          }
        }`);
      const graphClient = getDipTradingClient(chainId);
      if (!graphClient) {
        return;
      }
      graphClient
        .query({ query })
        .then((res) => {
          const data = processExpBatchData(res);
          //@ts-ignore
          setData(data);
        })
        // eslint-disable-next-line no-console
        .catch(console.warn);
    }
  }, [setData, chainId, account, page]);

  return data;
}

function processExpBatchData(res) {
  let ids: number[] = [];
  const list = res.data.loyaltyUserBatches.map((item) => {
    ids.push(item.batchId);
    return {
      amount: formatAmount(item.amount, defaultTokenDecimals, 6),
      batchId: item.batchId,
      lastRewardTime: item.lastRewardTime.toString(),
      batchStartTime: item.batch.startTime.toString(),
      totalExp: formatAmount(item.batch.totalBalance, defaultTokenDecimals, 6),
      batchAllocatedTime: item.batch.allocatedTime,
      perExpOfDip: formatAmount(item.batch.rewardPerShare, 6, 7),
      totalRewardAmount: formatAmount(item.batch.rewardAmount, defaultTokenDecimals, 6),
    };
  });
  return {
    list,
    ids,
  };
}

// export function useExpRedeemData(chainId, account, ids) {
//   const [data, setData] = useState(null);
//   useEffect(() => {
//     if (account) {
//       const query = gql(`{
//           loyaltyRedeemHistories(
//             orderBy: id
//             orderDirection: desc
//             where: {owner: "${account}"}
//           ) {
//             tx
//             timestamp
//             owner
//             id
//             amount
//           }
//         }`);
//       const graphClient = getDipTradingClient(chainId);
//       if (!graphClient) {
//         return;
//       }

//       graphClient
//         .query({ query })
//         .then((res) => {
//           setData(processExpRedeemData(res));
//         })
//         // eslint-disable-next-line no-console
//         .catch(console.warn);
//     }
//   }, [setData, chainId, account, ids]);

//   return data;
// }

// function processExpRedeemData(res) {
//   return res.data.loyaltyRedeemHistories.map((item) => {
//     return {
//       tx: item.tx,
//       timestamp: item.timestamp,
//       id: item.id,
//       amount: formatAmount(item.amount, defaultTokenDecimals, 6),
//     };
//   });
// }
