import { REFERRAL_CODE_KEY } from "config/localStorage";
import { ethers } from "ethers";

export function getReferrerBytes() {
  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const result = ethers.utils.defaultAbiCoder.encode(
    ["address"],
    [localStorageCode && ethers.utils.isAddress(localStorageCode) ? localStorageCode : ethers.constants.AddressZero]
  );

  return result;
}
