import React from "react";
import "./Buy.css";
import BuyCard from "components/Buy/BuyCard";
import KucoinIcon from "img/ic_kucoin.png";
import SpookyIcon from "img/ic-9608.png";
import BeeXIcon from "img/ic-beethoven-x.webp";
import EqIcon from "img/ic-equalizer.webp";
import CurveIcon from "img/ic_curve.webp";
import BaseSwapIcon from "img/ic_baseswap.png";
import UpbitIcon from "img/ic_upbit.png";
import BinanceIcon from "img/ic_binance.png";
import CoinBaseIcon from "img/ic_coinbase.png";
import Footer from "components/Footer/Footer";
export default function Buy () {
  const dexs = [
    // { content: "SpookySwap", icon: SpookyIcon, link: "https://www.spooky.fi/" },
    {
      content: "BaseSwap",
      icon: BaseSwapIcon,
      link: "https://baseswap.fi/swap?outputCurrency=0xadba4694f5deaf3251d06dec38c86946f8952efb",
    },
    // {
    //   content: "Velocimeter",
    //   icon: "https://base.velocimeter.xyz/_next/image?url=%2Fimages%2Fonly_bvm_blue.png&w=3840&q=75",
    //   link: "https://base.velocimeter.xyz/swap?outputCurrency=0xadba4694f5deaf3251d06dec38c86946f8952efb",
    // },
    {
      content: "Equalizer",
      icon: "https://base.equalizer.exchange/favicon2.png",
      link: "https://base.equalizer.exchange/swap",
    },
    // { content: "Curve", icon: CurveIcon },
    // { content: "Beethoven X", icon: BeeXIcon },
    // { content: " Equalizer", icon: EqIcon },
  ];
  const dgtDexs = [
    // { content: "SpookySwap", icon: SpookyIcon, link: "https://www.spooky.fi/" }, 
    // {
    //   content: "Velocimeter",
    //   icon: "https://base.velocimeter.xyz/_next/image?url=%2Fimages%2Fonly_bvm_blue.png&w=3840&q=75",
    //   link: "https://base.velocimeter.xyz/swap?outputCurrency=0xadba4694f5deaf3251d06dec38c86946f8952efb",
    // },
    {
      content: "Equalizer",
      icon: "https://base.equalizer.exchange/favicon2.png",
      link: "https://base.equalizer.exchange/swap",
    },
    // { content: "Curve", icon: CurveIcon },
    // { content: "Beethoven X", icon: BeeXIcon },
    // { content: " Equalizer", icon: EqIcon },
  ];
  const cexs = [
    { content: "Binance", icon: BinanceIcon },
    { content: "Coinbase", icon: CoinBaseIcon },
    { content: "Upbit", icon: UpbitIcon },
    { content: " Kucoin", icon: KucoinIcon },
  ];
  const cells = [
    {
      title: "Buy DIP from Dexes",
      cell: dexs,
    },
    {
      title: "Buy DGT from Dexes",
      cell: dgtDexs,
    },
    // {
    //   title: "Buy from Buy From Cexes",
    //   cell: cexs,
    // },
  ];

  return (
    <div className="default-bg ">
      <div className="default-container page-layout">
        <div className="BuyTitle">Buy DIP or DGT</div>
        <div className="BuyDesc mt-10">
          Where to buy DIP or DGT in the future (note that the choices listed here are planning, not the final result)
        </div>
        {cells.map((content, key) => (
          <div className="mt-20" key={key}>
            <div className="mt-20 fw-bold fs-3">{content.title}</div>
            <div className="row mt-20">
              {content.cell.map((item, index) => (
                <div
                  className="col-12 col-md-6 mt-10"
                  key={index}
                  onClick={() => {
                    window.open(item.link);
                  }}
                >
                  <BuyCard data={item}></BuyCard>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="Buy-footer"></div>
      <div className="default-bg-footer"></div>
      <Footer />
    </div>
  );
}
