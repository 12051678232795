import "./ExchangeBottom.css";
import cx from "classnames";
import React, { useState } from "react";
import useWallet from "lib/wallets/useWallet";
import { useChainId } from "lib/chains";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import OrderManager from "abis/OrderManager.json";
import { getContract } from "config/contracts";
import { getOrdersInfo } from "lib/positions";
import { SpotHistory } from "components/Exchange/SpotHistory";

export const SpotBottom = ({ tokenAddress, tokenPrice, setPendingTxns }) => {
  const { chainId } = useChainId();
  const { active, signer, account } = useWallet();
  // const orderManagerAddr = getContract(chainId, "OrderManager");
  // const poolViewAddr = getContract(chainId, "PoolView");
  const [tabIndex, setTabIndex] = useState(0);
  // const [orderPage, setOrderPage] = useState({ start: 0, num: 1000 });
  // const { data: orders } = useSWR(
  //   active &&
  //     signer && [
  //       `ExchangeBottom:Orders${active}`,
  //       chainId,
  //       orderManagerAddr,
  //       "getOrders",
  //       account,
  //       orderPage.start,
  //       orderPage.num,
  //     ],
  //   {
  //     fetcher: contractFetcher(signer, OrderManager),
  //   }
  // );
  // const ordersInfo = getOrdersInfo(chainId, orders);

  const tabs = [
    // {
    //   title: `Orders${ordersInfo?.total > 0 ? "(" + ordersInfo?.total + ")" : ""}`,
    // },
    {
      title: "History",
    },
  ];
  const tabClick = (index) => {
    setTabIndex(index);
  };
  return (
    <div className="mt-10 mb-20">
      <div className="Exchange-bottom-tab-split"></div>
      <div className="flex aligns-center">
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => {
              tabClick(index);
            }}
            className=" Exchange-bottom-tab"
          >
            <div className={cx({ "ml-10": index > 0, "Exchange-bottom-tab-active": tabIndex === index })}>
              {tab.title}
            </div>
          </div>
        ))}
      </div>
      <div className="Exchange-bottom-tab-split"></div>
      {tabIndex === 0 && (
        <SpotHistory
          tokenPrice={tokenPrice}
          chainId={chainId}
          signer={signer}
          setPendingTxns={setPendingTxns}
          account={account}
        />
      )}
    </div>
  );
};
