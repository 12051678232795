import { useEffect, useState } from "react";
import { defaultApiUrl, getApiPathName } from "lib/api";
import cx from "classnames";
import Button from "components/Button/Button";
import { helperToast } from "lib/helperToast";

export default function USDCAirdrop ({ account, chainId }) {
  const [rewardInfo, setRewardInfo] = useState();
  const isNotCanApply = () => {
    return !rewardInfo || rewardInfo.reward === "" || rewardInfo.reward < 0;
  };
  const [claim, setClaim] = useState();
  useEffect(() => {
    if (!account) return;
    try {
      fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/contest/testnet/reward/${account}`).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            if (data.errno === 0) {
              setRewardInfo(data.data);
            } else {
              setRewardInfo(undefined);
            }
          });
        }
      });
    } catch (e) { }
  }, [chainId, account, claim]);
  const applyClaim = () => {
    if (!account || rewardInfo?.status !== 0) return;
    try {
      fetch(`${defaultApiUrl}/${getApiPathName(chainId)}/contest/testnet/applyReward/${account}`)
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              if (data.errno === 0) {
                helperToast.success("Apply success! The airdrop will arrive within 24 hours.");
                setClaim(true);
              } else {
                helperToast.error("Apply failed! Please try again later.");
              }
            });
          }
        })
        .catch((error) => {
          helperToast.error("Apply failed! Please try again later.");
        });
    } catch (e) {
      helperToast.error("Apply failed! Please try again later.");
    }
  };
  return (
    <div className="ml-15">
      <div className="fs-3">USDC AirDrop</div>
      <div className="mt-20">
        <div>
          You can find the airdrop list&nbsp;
          <a
            href="https://docs.google.com/spreadsheets/d/1JyKsA90MzmScdJ2uWuVzS3BC5DLuThpdHUKL09qjAgU/edit#gid=0"
            style={{ color: "#5682FF" }}
          >
            here.
          </a>
        </div>
        <div className={cx("Testnet-rewards-card")}>The claim application has concluded!</div>

        <div className="mt-20 ml-20 Liquidity-card-subtitle">
          <div className="flex  aligns-center">
            <img
              src={require(rewardInfo ? "img/ic_pass.png" : "img/ic_not_pass.png")}
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">Join the testnet trading contest and get more than $10 profit</div>
          </div>
          <div className="flex  aligns-center mt-10">
            <img
              src={
                require(rewardInfo && rewardInfo.reward !== "" && rewardInfo.reward > 0
                  ? "img/ic_pass.png"
                  : "img/ic_not_pass.png")
              }
              alt=""
              className="icon-xs"
            ></img>
            <div className="ml-10">
              During the trading contest, no more than 10 FTMs received from the official faucet were used
            </div>
          </div>
        </div>
        <div className="mt-20">
          {/* {isNotCanApply() && (
            <div className="Testnet-rewards-card Testnet-rewards-error">
              You are not eligible for the trading contest USDC airdrop!
            </div>
          )}
          {!isNotCanApply() && (
            <div>
              <div
                className={cx("Testnet-rewards-card", {
                  "Testnet-rewards-card-active": rewardInfo.status === 0,
                })}
              >
                {rewardInfo.status === 0
                  ? "Congratulations! You are on the list of winners."
                  : rewardInfo.status === 1
                  ? "You have applied for a claim, USDC will reach your wallet within 24H!"
                  : "Your reward has arrived in your wallet complete"}
              </div>
            </div>
          )} */}
        </div>
        <div className="row">
          <div className="col-md-6 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Your Reward</div>
              <div className="mt-10 flex algins-center">
                <img src={require("img/ic_usdc_40.svg").default} alt="USDC" className="icon-xs"></img>
                <div className=" ml-10">{rewardInfo?.reward}</div>
                <span className="Liquidity-card-subtitle ml-5 "> USDC</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Airdrop status</div>
              <div className="mt-10 flex algins-center">
                <div
                  className={cx(" ml-10", {
                    "Testnet-rewards-card-active": !isNotCanApply() && rewardInfo?.status === 0,
                    "Liquidity-card-subtitle ": isNotCanApply() || rewardInfo?.status !== 0,
                  })}
                >
                  {isNotCanApply()
                    ? "You are not in airdrop list"
                    : rewardInfo?.status === 0
                      ? "Not applied"
                      : rewardInfo?.status === 1
                        ? "The airdrop is being distributed"
                        : "The airdrop has arrived"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Your Pnl</div>
              <div className="mt-10">${rewardInfo?.pnl}</div>
            </div>
          </div>
          <div className="col-md-4 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Your Win/Loss</div>
              <div className="mt-10">
                {rewardInfo?.win}/{rewardInfo?.loss}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 ">
            <div className="Testnet-rewards-card flex-col justify-center  aligns-center">
              <div className="Liquidity-card-subtitle ">Your Volume</div>
              <div className="mt-10">${rewardInfo?.volume}</div>
            </div>
          </div>
        </div>
        <div className="Exchange-swap-button-container mt-20">
          <Button
            variant="primary-action"
            className="w-100"
            onClick={applyClaim}
            // disabled={isNotCanApply() || rewardInfo?.status !== 0}
            disabled={true}
          >
            The claim application has concluded!
            {/* {isNotCanApply()
              ? "Cannot apply for a claim"
              : rewardInfo?.status === 0
              ? "Apply Claim"
              : "Claim Already Applied"} */}
          </Button>
        </div>
      </div>
    </div>
  );
}
