import React from "react";
import "./LiquidityCard.css";
import LiquidityCard from "./LiquidityCard";
import cx from "classnames";

export default function StakeCard ({ data }) {
  const { icons, title, perDay, management, price, apr } = data;
  const cellClass = "flex-col justify-center";
  const subTitles = ["Total Deposited", "APR"];
  return (
    <div>
      <div className="tranche-card-m">
        <LiquidityCard item={{ ...data, type: 2 }}></LiquidityCard>
      </div>
      <div className="tranche-card">
        <div className="Liquidity-card">
          <div className="flex justify-between aligns-center mt-10">
            <div className={cellClass}>
              <div className="flex">
                {icons
                  ? icons.map((icon, index) => (
                    <img
                      key={index}
                      alt=""
                      src={icon}
                      className={cx("icon-sm", {
                        " mr-base": index === icons.length - 1,
                        "DefaultCard--ml10": index > 0,
                      })}
                    ></img>
                  ))
                  : ""}
                <div className="Liquidity-card-value">{title}</div>
              </div>
              <div className="Liquidity-card-subtitle mt-10">Earn {perDay} DIP per Day</div>
            </div>
            {subTitles.map((item, index) => (
              <div className={cellClass} key={index}>
                <div className="Liquidity-card-subtitle">{item}</div>
                <div className="Liquidity-card-value mt-10">{index === 0 ? `$${management}` : `${apr}%`}</div>
              </div>
            ))}
            <div>
              <img src={require("img/ic_right.png")} alt="detail"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
